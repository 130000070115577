import { createContext, useContext, useState,useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageContext = createContext({ currentPage: '', updateCurrentPage: (page: string) => { } });
export default PageContext;

export const useRouteUpdater = () => {
    const { updateCurrentPage } = useContext(PageContext);
    const checkRouteOnReload = () => {
        updateCurrentPage(window.location.pathname);
    };
    return { checkRouteOnReload };
};

export const PageProvider: React.FC<{children: any}> = ({ children }) => {
    const location = useLocation()
    const [currentPage, setCurrentPage] = useState(location.pathname);

    const updateCurrentPage = (page: string) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location.pathname]);


    const pageProviders = {
        currentPage: currentPage,
        updateCurrentPage: updateCurrentPage,
    }
    
    return (
        <PageContext.Provider value={pageProviders}>
            {children}
        </PageContext.Provider>
    )
}
