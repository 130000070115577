import { useEffect, useState } from "react";

export const OfferEndsInTimer: React.FC<{timer: number}> = ({timer}) => {

    const [days, hours, minutes, seconds] = useCountdown(new Date(timer));

    return (
        <>
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        </>
    )
}

const ShowCounter: React.FC<{
    days: number,
    hours: number,
    minutes: number,
    seconds: number
}> = ({ days, hours, minutes, seconds }) => {
    return (
        <>
            {
                days > 0 || hours > 0 || minutes > 0 || seconds > 0 ? 

                <div className="flex gap-1 lg:font-bold font-semibold align-center items-center lg:px-[10px] lg:py-[5px] px-[10px] py-[5px]  lg:text-base text-[15px] w-[fit-content] bg-[#03977e] border-[1px]  border-[solid]  text-white rounded-[16px]">
                    <div className="">
                        <span className="animate-pulse">⏳</span>
                        Offer Ends In</div>
                    {days ? <DateTimeDisplay value={days} type={'d'} /> : <></>}
                    {hours ? <DateTimeDisplay value={hours} type={'hr'} /> : <></>}
                    {minutes ? <DateTimeDisplay value={minutes} type={'m'} /> : <></>}
                    {seconds ? <DateTimeDisplay value={seconds} type={'s'} /> : <></>}
                </div>

                : <></>
            }
        </>
    );
};

const DateTimeDisplay: React.FC<{
    value: number,
    type: string
}> = ({ value, type }) => {
    return (
        <div className="flex">
            <p>{value}</p>
            <span>{type}</span>
        </div>
    );
};


const useCountdown = (targetDate: any) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown: any) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 *60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60 )) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};