import { IoIosArrowRoundForward } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export const Contact: React.FC = () => {

  const location =useLocation()

  useEffect(()=>{
  window.scrollTo(0,0)
  },[location.pathname === "/contact"])




  return (
    <>
      <div className=" lg:w-full  lg:flex lg:flex-row    lg:py-10   w-full flex flex-col gap-10  items-center text-left  md:w-full md:flex md:flex-col md:gap-5 md:items-start ">
        <div className="lg:w-1/2 lg:flex lg:justify-center  md:w-full md:flex   w-full flex  ">
          <div className="lg:w-1/2  lg:flex lg:flex-col lg:justify-center   lg:gap-5    w-full  flex flex-col justify-center   px-10   gap-5   py-5  md:w-2/3 md:flex md:flex-col md:items-start md:px-10 ">
            <span className="text-lg font-semibold">CONTACT</span>
            <span className="max-w-max    ">
              4th Floor, Business Park
              <br />
              HiLITE City, National Highway 66,
              <br /> Bypass, Thondayad,
              <br />
              Kozhikode, Kerala, 673014
              <br />
              <br />
              +918590322571
              <br />
               hellowowkid@gmail.com
            </span>
          </div>
        </div>
        <form
          className="lg:w-1/2 lg:flex lg:flex-col lg:gap-5 lg:justify-center  w-full flex flex-col gap-5 justify-center items-center "
          action=""
        >
          <div className="w-full     ">
            <span className="lg:w-full lg:flex lg:justify-start lg:text-[35px] lg:font-[childhood] lg:px-[110px] lg:py-3  md:w-full md:flex md:justify-start md:text-[30px] md:text-start md:font-[childhood] md:px-[35px] md:py-3 w-full flex justify-start text-[33px] font-[childhood] px-[35px] py-3">
              Fill the details below
            </span>
          </div>
          <div className="lg:w-[80%] lg:flex lg:flex-col  w-full flex flex-col  px-10 md:w-full md:flex md:flex-col md:px-10 ">
            <label className="" htmlFor="email">
              email ID
            </label>
            <input
              className="lg:border  lg:rounded-md lg:py-[5px] lg:outline-none  lg:focus:outline-none  lg:focus:ring-1 lg:focus:ring-[#30d0b5]   lg:border-[#30d0b5]  md:border  md:rounded-md md:py-[5px] md:outline-none  md:focus:outline-none  md:focus:ring-1 md:focus:ring-[#30d0b5]   md:border-[#30d0b5]   border  rounded-md py-[5px] outline-none  focus:outline-none  focus:ring-1 focus:ring-[#30d0b5]   border-[#30d0b5]"
              type="email"
              name="email"
              id="email"
            />
          </div>
          <div className="lg:w-[80%] lg:flex lg:flex-col  w-full flex flex-col  px-10 md:w-full md:flex md:flex-col md:px-10 ">
            <label htmlFor="phonenumber">Contact Number</label>
            <input
              className="lg:border  lg:rounded-md lg:py-[5px] lg:outline-none  lg:focus:outline-none  lg:focus:ring-1 lg:focus:ring-[#30d0b5]   lg:border-[#30d0b5]  md:border  md:rounded-md md:py-[5px] md:outline-none  md:focus:outline-none  md:focus:ring-1 md:focus:ring-[#30d0b5]   md:border-[#30d0b5]   border  rounded-md py-[5px] outline-none  focus:outline-none  focus:ring-1 focus:ring-[#30d0b5]   border-[#30d0b5]"
              type="tel"
              name="phonenumber"
              id="phonenumber"
            />
          </div>
          <div className="lg:w-[80%] lg:flex lg:flex-col  w-full flex flex-col  px-10 md:w-full md:flex md:flex-col md:px-10 ">
            <label htmlFor="message">Message</label>
            <textarea
              className="lg:border  lg:rounded-md lg:py-[5px] lg:outline-none  lg:focus:outline-none  lg:focus:ring-1 lg:focus:ring-[#30d0b5]   lg:border-[#30d0b5]  md:border  md:rounded-md md:py-[5px] md:outline-none  md:focus:outline-none  md:focus:ring-1 md:focus:ring-[#30d0b5]   md:border-[#30d0b5]   border  rounded-md py-[5px] outline-none  focus:outline-none  focus:ring-1 focus:ring-[#30d0b5]   border-[#30d0b5]"
              name="message"
              id="messaeg"
            />
          </div>

          <div className="w-full flex justify-center ">
            <button className=" w-[80%] lg:w-[70%]   text-center lg:py-3 lg:px-[220px] lg:bg-[#03977e] lg:rounded-3xl lg:flex lg:items-center lg:text-white lg:gap-5  lg:font-semibold  py-3 px-[110px] bg-[#03977e] rounded-3xl flex items-center text-white gap-2  font-semibold  md:py-3 md:px-[310px] ">
              SEND
             <p className="text-center">
             <IoIosArrowRoundForward size={20} />
             </p>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
