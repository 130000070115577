
import { useEffect } from "react";

import { useLocation } from "react-router-dom";
export const PrivacyPolicySection: React.FC = () => {

  const location =useLocation()


  useEffect(()=>{
    window.scrollTo(0,0)
    },[location.pathname === "/privacy-policy"])
    return (
      <>
        <section className="lg:block lg:py-10 lg:px-52    lg:relative lg:top-[60%] lg:left-[50%] lg:-translate-x-[50%]  md:block md:py-10 md:px-100    md:relative md:top-[50%] md:left-[50%] md:-translate-x-[50%]  block   relative top-[60%] left-[50%] -translate-x-[50%]">
          <div className="lg:w-fit lg:h-fit  lg:py-10 lg:px-10 lg:text-left  md:w-fit md:h-fit  md:py-10 md:px-10  w-fit h-fit  py-10 px-5 md:text-left  text-left">
          <h1 className="font-bold text-2xl font-sans">Privacy Policy</h1>
          <br />
          <p className=" text-left ">
            The wowkid is dedicated to safeguarding the privacy of our customers,
            particularly children under the age of 18, and their personal
            information. This Privacy Policy elucidates how we gather, utilize,
            and safeguard your data in accordance with Indian law.
          </p>
          <br />
  
          <h2 className=" text-base font-semibold">
            Information We Collect:
          </h2>
          <p className="text-left">
            Personal Information: We may collect personal data from you, such as
            names, email addresses, and phone numbers, when you engage with our
            services. Children's Information: We may gather and process images of
            children below 18 years of age. We strive to ensure that we have
            obtained consent from parents or guardians before processing such
            data. If you are a parent or guardian and suspect that your child's
            information has been collected without your consent, please promptly
            contact us. Third-Party Applications: We may handle customer data in
            third-party applications for operational purposes. These third-party
            applications are governed by their respective privacy policies.
          </p>
          <br />
  
          <h2 className=" text-base font-semibold">
            Use of Information:
          </h2>
          <p className=" text-left">
            We utilise your personal information to deliver personalised products
            and services. Children's data is processed only with the consent of
            their parents or guardians. We may also utilise anonymized and
            aggregated data for internal research and analysis to enhance our
            products and services.
          </p>
          <br />
  
          <h2 className=" text-base font-semibold">Data Security:</h2>
          <p className="text-left">
            We uphold reasonable security measures to safeguard your personal
            information from unauthorised access, disclosure, alteration, and
            destruction.
          </p>
          <br />
          <h2 className=" text-base font-semibold">Data Sharing:</h2>
          <p className="text-left">
            We do not sell, rent, or trade your personal information with third
            parties. However, we may share your information with third parties
            that provide services on our behalf.
          </p>
          <br />
          <h2 className=" text-base font-semibold">Your Rights:</h2>
          <p className=" text-left">
            You have the right to access, rectify, or delete your personal
            information. Parents or guardians have the right to review and request
            the deletion of their children's information.
          </p>
          <br />
          <h2 className="  text-base font-semibold">Cookies:</h2>
          <p className="text-left">
            We utilise cookies on our website to enhance your browsing experience.
            You can manage or disable cookies in your browser settings.
          </p>
          <br />
          <h2 className=" text-base font-semibold">
            Changes to Privacy Policy:
          </h2>
  
          <p className=" text-left">
            We may revise this Privacy Policy periodically. You will be notified
            of significant changes.
          </p>
          <br />
          <h3 className=" text-base font-semibold">
            Contact Information:
          </h3>
  
          <p className=" text-left">
            If you have any inquiries or concerns regarding this Privacy Policy or
            your personal information, please reach out to us at
            <strong > hello@thewowkid.com</strong>. By
            utilising Thewowkid’s services, you agree to the terms outlined in
            this Privacy Policy.
          </p>
          <br />
  
          </div>
          </section>
      </>
    );
  };