import React from 'react';
import './modal.scss';
import closeModalImg from '../../assets/images/svg/close-modal.svg';

interface ModalProps {
  heading?: string;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ heading = '', isOpen, onClose, children }) => {
  if (!isOpen) return null;
if(isOpen)
{
  const popup =  document.getElementById("popup")
popup?.scrollTo(0,0)
}
  return (
    <div id='popup' className="modal-overlay " onClick={onClose}>
      <div className="modal-content w-[100%] lg:w-auto md:w-[100%] min-w-[300px] h-[90%] lg:h-[90%] md:h-auto overflow-y-auto  scrollbar-hide " onClick={(e) => e.stopPropagation()}>
        <div className='p-5 flex items-center justify-between'>
          <h1 className='lg:text-[28px] md:text-[25px] text-[20px]  font-bold leading-[34px] text-[#242631] mr-[20px]'>{heading}</h1>
          <button className="modal-close" onClick={onClose}>
            <img src={closeModalImg} alt="" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};
