import { useContext } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { Link, useSearchParams } from "react-router-dom";
import PageContext from "../../implementaion/page-context/page-context";


export const Paymentsuccessmsg: React.FC = () => {
    const [searchParams] = useSearchParams()
    const payment_id = searchParams.get('payment_id')
    const { currentPage, updateCurrentPage } = useContext(PageContext);

    return (
        <>
            <section className="h-full overflow-hidden lg:pt-0 md:pt-0 pt-[100px]">
                <div className="   lg:min-w-[30%] md:min-w-[30%]  h-auto top-[50%] left-[50%] -translate-x-1/2 lg:-translate-y-[70%] md:-translate-y-[60%] -translate-y-[75%]  absolute  flex flex-col justify-center lg:gap-3 md:gap-3 gap-2 items-center overflow-hidden ">
                   <div className="lg:block md:block hidden">
                   <FaCircleCheck
                        size={80}
                        className=" text-[#22965a] border-4 border-green-100 rounded-full"
                    />
                   </div>
                   <div className="lg:hidden md:hidden block">
                   <FaCircleCheck
                        size={55}
                        className=" text-[#22965a] border-4 border-green-100 rounded-full"
                    />
                   </div>


                    <h1 className="font-extrabold lg:text-3xl md:text-3xl text-[#2a2a2a]  text-2xl  text-center ">
                        Payment successful!
                    </h1>
                    <p className="font-sans lg:text-base md:text-base text-xs font-medium  text-center">
                        Payment ID: {payment_id}
                    </p>
                    {/* <p className="font-sans  w-[300px] text-wrap text-center  ">
            Payment of 299 for Face stickers is successfully Paid
          </p> */}  
                    <p className="w-[350px] text-center text-sm lg:text-base md:text-base">Payment success !, please track your id by providing email, please reach out to use for any query <strong className="font-sans"> hello@thewowkid.com</strong></p>
                    <button className="font-sans font-semibold  lg:px-[120px] lg:py-[15px] px-32 py-5
                      bg-[#03977e] rounded-full text-white text-center text-nowrap">
                        <Link to="/track-order" onClick={() => updateCurrentPage('/track-order')}>
                            Track Order
                        </Link>
                    </button>
                </div>
            </section>
        </>
    );
};
