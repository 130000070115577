import React, { useContext, useEffect, useRef, useState } from "react";
import "./landing.scss";
import layoutRocket from "../../assets/images/svg/layout-rocket.svg";
import layoutColorEllipse from "../../assets/images/svg/layout-color-elipse.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, FreeMode } from "swiper/modules";
import verifiedCustomer from "../../assets/images/svg/verified-customer.svg";
import freeDelivery from "../../assets/images/svg/free-delivery.svg";
import dispatchTime from "../../assets/images/svg/dispatch-time.svg";
import waterProof from "../../assets/images/svg/water-proof.svg";
import { ProductWrapper } from "../../implementaion/product-wrapper/product-wrapper";
//-- todo remove above
import { useFetch } from "../../common/https";
import { Link } from "react-router-dom";
import PageContext from "../../implementaion/page-context/page-context";
import { WhatappBanner } from "../../implementaion/whatapp-banners/whatapp-banners";
// import bluredImageDesktop from "../../assets/images/png/blured-banner-img.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bluredImageMobile from "../../assets/images/png/blured-banner-img-mobile.png";
import { VerifiedPatners } from "../../implementaion/verified_partners/VerifiedPatners";
import { Instafeed } from "../../implementaion/Instafeed/Instafeed"

export const Landing: React.FC = () => {
  return (
    <>
      <div className="px-[15px] sm:px-[30px] py-[0] z-10">
        <div className="max-w-screen-xl mx-[auto] my-[0] ">
          {/* <img
            className="fixed z-1 top-[82px] left-[18px] sm:top-[138px] sm:left-[158px] w-[60px] h-[40px] sm:w-[150px] sm:h-[90px]"
            src={layoutRocket}
            alt="layout-rocket"
          /> */}
          <img
            className="z-1 w-[392px] h-[426px] fixed top-2/4 -left-[50px] translate-x-[0] -translate-y-1/2"
            src={layoutColorEllipse}
            alt="layout-color-elipses"
          />
          <div className="md:pt-[5px] lg:pt-[5px] pt-0 -translate-y-6  lg:-translate-y-2">
            <BannerCarousel />
          </div>
          <div className="my-[30px] sm:my-[50px] md:my-[80px]">
            <ProductPoints />
          </div>
        </div>
      </div>
      <div>
        <ProductWrapper />
      </div>
      {/* <div>
        <ProductWrapper
          heading="Top selling products"
          productKey="top-selling"
          subHeading="Explore our customer favorite essentials, crafted for self expression and everyday efficiency. Elevate your creativity with customized premium designs"
        />
      </div> */}
        <div className="mt-[30px] sm:mt-[50px] lg::mt-[50px]">
        <WhatappBanner />
      </div>
      <div className="mt-[50px]  md:mt-[30px] lg:mt-[50px]"  >
      <VerifiedPatners/>
      </div>
      <div className="mt-[30px] sm:mt-[50px] lg:mt-[50px]">
        <CustomerReview />
      </div>
      {/* <div className="mt-[30px] sm:mt-[50px] md:mt-[80px]">
        <Instafeed/>
      </div> */}
    
    </>
  );
};

const BannerCarousel: React.FC = () => {
  const { fetchRest } = useFetch({});
  const [bannerConfig, setBannerConfig] = useState<any>([]);
  const { currentPage, updateCurrentPage } = useContext(PageContext);
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      fetchRest({
        url: "banner-configs",
        method: "GET",
        isFullPageLoader: true,
      })
        .then((res) => {
      
          setBannerConfig(res?.data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <>
      {bannerConfig?.length ? (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          allowSlideNext={true}
          allowSlidePrev={true}
          allowTouchMove={true}
          speed={2000}

          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          pagination={{
            clickable: true,
          }}
          // freeMode={true}
          modules={[Autoplay, Pagination, FreeMode]}
          className="banner-slider flex justify-center"
        >
          {bannerConfig.map((banner: any,index:any) => {
            return (
              <SwiperSlide  key={index}>
                <div>
                 <div className=" flex items-center justify-center ">
                 <h1 className=" lg:p-10 pt-2 pb-4 font-[childhood] text-nowrap text-[35px] md:text-[60px] lg:text-[80px] font-normal leading-[30px] md:leading-[60px] tracking-wide text-center bg-gradient-to-r from-red-500 to-yellow-400 text-transparent bg-clip-text">
                    {banner?.header}
                  </h1>
                 </div>
                  <p className="md:hidden hidden text-[16px] font-normal leading-[20.8px] text-center text-[#242631]">
                    {banner?.description}
                  </p>
                  <div className="banner-slides-wrapper rounded-[10px] relative">
                  <Link
                        to={banner?.prdLink}
                        onClick={() => updateCurrentPage(banner?.prdLink)}
                      >
                    <LazyLoadImage
                      className="hidden md:block"
                      src={process.env.REACT_APP_IMG_CDN_URL + banner?.img}
                      // placeholderSrc={bluredImageDesktop}
                      alt="Image Alt"
                    />
                      </Link>
                      <Link
                          to={banner?.prdLink}
                          onClick={() => updateCurrentPage(banner?.prdLink)}
                        >
                    <LazyLoadImage
                      className="block lg:hidden md:hidden"
                      
                      src={
                        process.env.REACT_APP_IMG_CDN_URL +banner?.mobileImg
                        
                      }
                      // placeholderSrc={bluredImageMobile}
                      alt="Image Alt"
                    />
                        </Link>


                    {/* <div className="absolute w-[360px] h-[450px] -translate-x-1/2 -translate-y-1/2 top-[45%] left-[50%]  lg:hidden  md:hidden   flex justify-center items-center">
                      <button className="banner-button   lg:bottom-[70px] lg:left-[110px] left-[145px] bottom-[240px]   md:left-[60px] md:bottom-[45px] bg-[#ffffff] text-[#30BDDB] lg:px-[20px] lg:py-[10px]  px-[11px] py-[1px] text-[10px] rounded-[32px] box-border lg:text-[18px] font-bold leading-[20.9px] text-left">
                        <Link
                          to={banner?.prdLink}
                          onClick={() => updateCurrentPage(banner?.prdLink)}
                        >
                          Buy Now
                        </Link>
                      </button>
                    </div> */}
                    {/* <button className=" lg:block md:block hidden banner-button  absolute  lg:bottom-[70px] lg:left-[110px] left-[145px] bottom-[240px]   md:left-[60px] md:bottom-[45px] bg-[#ffffff] text-[#30BDDB] lg:px-[20px] lg:py-[10px]  px-[11px] py-[1px] text-[10px] rounded-[32px] box-border lg:text-[18px] font-bold leading-[20.9px] text-left">
                      <Link
                        to={banner?.prdLink}
                        onClick={() => updateCurrentPage(banner?.prdLink)}
                      >
                        Buy Now
                      </Link>
                    </button> */}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <></>
      )}
    </>
  );
};

const ProductPoints: React.FC = () => {
  return (
    <section className="flex items-baseline justify-between gap-4 flex-wrap">
      <div className="flex flex-col align-center items-center gap-3 w-[45%] sm:w-1/5">
        <img
          className="w-[48px] h-[48px] sm:w-[114px] sm:h-[114px]"
          src={verifiedCustomer}
          alt=""
        />
        <div className="flex flex-col gap-1 items-center align-center">
          <p className="font-bold text-center text-[12px] md:text-[13px] lg:text-[16px] leading-[20px] lg:leading-[28px]">
            20k+ verified customers
          </p>
          {/* <p className="text-[12px] font-normal leading-[14px] text-center sm:text-[14px] sm:leading-[22px] text-[#54575A]">
            20k+ customers all over india
          </p> */}
        </div>
      </div>

      <div className="flex flex-col align-center items-center gap-3 w-[45%] sm:w-1/5">
        <img
          className="w-[48px] h-[48px] sm:w-[114px] sm:h-[114px]"
          src={freeDelivery}
          alt=""
        />
        <div className="flex flex-col gap-1 items-center align-center">
          <p className="font-bold text-center text-[12px] md:text-[13px]  lg:text-[16px] leading-[20px] lg:leading-[28px]">
            All India free delivery
          </p>
          {/* <p className="text-[12px] font-normal leading-[14px] text-center  lg:text-[15px] sm:leading-[22px] text-[#54575A]">
            Get fast & efficient deliver
          </p> */}
        </div>
      </div>

      <div className="flex flex-col align-center items-center gap-3 w-[45%] sm:w-1/5">
        <img
          className="w-[48px] h-[48px] sm:w-[114px] sm:h-[114px]"
          src={dispatchTime}
          alt=""
        />
        <div className="flex flex-col gap-1 items-center align-center">
          <p className="font-bold text-center text-[12px] md:text-[13px]  lg:text-[16px] leading-[20px] lg:leading-[28px]">
            Dispatch within 48 Hours
          </p>
          {/* <p className="text-[12px] font-normal leading-[14px] text-center sm:text-[14px] sm:leading-[22px] text-[#54575A]">
            Dispatched with high quality material
          </p> */}
        </div>
      </div>

      <div className="flex flex-col align-center items-center gap-3 w-[45%] sm:w-1/5">
        <img
          className="w-[48px] h-[48px] sm:w-[114px] sm:h-[114px]"
          src={waterProof}
          alt=""
        />
        <div className="flex flex-col gap-1 items-center align-center">
          <p className="font-bold text-center text-[12px] md:text-[13px]  lg:text-[16px] leading-[20px] lg:leading-[28px]">
            Water proof
          </p>
          {/* <p className="text-[12px] font-normal leading-[14px] text-center sm:text-[14px] sm:leading-[22px] text-[#54575A]">
            Let it be rain or shower, doesn’t matter
          </p> */}
        </div>
      </div>
    </section>
  );
};

const CustomerReview: React.FC = () => {


  
  return (
    <section className=" lg:py-[80px] py-[50px]  md:py-[50px]  flex flex-col gap-10 items-center overflow-hidden">
      <div>
        <h1 className="px-[15px] sm:px-[30px] font-[childhood] text-[40px] md:text-[60px] font-normal leading-[70px]  lg:leading-[50px] tracking-[0.02em] text-center">
          Little Voices Speak!
        </h1>
        <h2 className="px-[15px] sm:px-[30px] text-[16px] md:text-[20px] font-normal leading-[16px] text-center mt-0 sm:mt-4 md:mt-6 ">
          Every detail tells a story—our bespoke creations blend craftsmanship
          and creativity to let the little voices speak!
        </h2>
      </div>

      <Swiper
        slidesPerView={1.2} // On mobile, show 1.2 slides (1 full + half next)
        spaceBetween={0} // Add some space between slides
        loop={true} // Enable looping
        speed={1000}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2, // For smaller screens, show full + half slide
            spaceBetween: 0, // Space between slides
          },
          768: {
            slidesPerView: 2, // Show 2 slides for tablets
            spaceBetween: 0, // Space between slides
          },
          1024: {
            slidesPerView: 7, // For larger screens, show 4 slides
            spaceBetween: 0, // Space between slides
          },
        }}
        modules={[Autoplay]}
        className="review-carousel w-full overflow-hidden"
      >
        {[
          "Review01.mp4",
          "Review02.mp4",
          "Review03.mp4",
          "Review05.mp4",
          "Review06.mp4",
          "Review07.mp4",
          "Review08.mp4",
          "Review09.mp4",
        ].map((rev, index) => {
          return (
            <SwiperSlide key={index} className="w-full mr-[14px] overflow-hidden">
              <video
                autoPlay
                loop
                muted
                controls
                controlsList="nofullscreen nodownload noplaybackspeed "
                playsInline
  

                // onLoad={(this) => this.player && this.player.presentFullscreenPlayer()}
                className="w-full aspect-square"
              >
                <source
                  src={process.env.REACT_APP_IMG_CDN_URL + rev}
                  type="video/mp4"
                />
              </video>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
