import { useEffect, useRef, useState } from "react";
import { useFetch } from "../../common/https";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import "./track-order-login.scss";
import { error } from "console";

export const TrackOrderLogin: React.FC = () => {
  const [otpsection, setotpsection] = useState(false);
  const { fetchRest } = useFetch({});
  const [orderValue, setOrderValue] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [orderErrorMessage, setOrderErrorMessage] = useState("");
  const [phnNoErrorMessage, setPhnNoErrorMessage] = useState("");
  const navigate = useNavigate();
  const [otpVerificationSessionKey, setOtpVerificationSessionKey] =
    useState(undefined);
  const initialized = useRef(false);
  const [retries, setRetries] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [timerInterVal, setTImerInterVal] = useState<any>(undefined);
  const [otpArr, setOtpArr] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const resendOTP = () => {
    if (seconds === 0 && retries <= 2) {
      sendOtpForLogin(() => {
        setRetries(retries + 1);
        if (timerInterVal) {
          clearInterval(timerInterVal);
        }
        setSeconds(29);
        setTImerInterVal(
          setInterval(() => {
            if (seconds > 0) {
              setSeconds(seconds - 1);
            }
          }, 1000)
        );
      });
    }
  };

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      if (localStorage.getItem("accessToken")) {
        navigate("/order-details");
      }
    }
  }, []);

  const handleLogin = async () => {
    sendOtpForLogin(() => {
      setotpsection(true);
      setSeconds(29);
    });
  };

  const sendOtpForLogin = async (callback: any) => {
    fetchRest({
      url: "init-auth",
      method: "POST",
      data: {
        email: phoneNumberValue,
      },
      isFullPageLoader: true,
    })
      .then((res) => {
        if (res.data?.session) {
          setOtpVerificationSessionKey(res.data?.session);
          callback();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const verifyOtp = () => {
    let otp = "";
    let isOtpValid = true;
    otpArr.forEach((val) => {
      if (val) {
        otp += val;
      } else {
        isOtpValid = false;
      }
    });
    if (isOtpValid && otpArr?.length === 6) {
      handleLoginWithOtp(otp);
    } else {
      console.log("otp invalid !");
    }
  };

  const handleLoginWithOtp = (otp: string) => {
    fetchRest({
      url: "verify-otp",
      method: "POST",
      data: {
        email: phoneNumberValue,
        sessionKey: otpVerificationSessionKey,
        otp,
      },
      isFullPageLoader: true,
    })
      .then((res) => {
        if (res.data?.accessToken) {
          localStorage.setItem("accessToken", res.data?.accessToken);
          let navVal = "/order-details";
          if (orderValue) {
            navVal += `?orderId=${orderValue}`;
          }
          navigate(navVal);
        }
      })
      .catch((err) => {
        
        setErrorMessage("Invalid OTP! Please check and enter again")
 
      });
  };

  const handleOTPValues = (event: any, index: number) => {
    let arr: any = otpArr;
    arr[index] = event;
    setOtpArr(arr);

    // Move to the next input if a digit is entered and it's not the last input

    const inputs = document.querySelectorAll(
      ".otp-input"
    ) as NodeListOf<HTMLInputElement>;
    if (event && index < 5) {
      inputs[index + 1]?.focus();
    }

    // Move to previous input on backspace (if current input is empty)
    if (event === "" && index > 0) {
      inputs[index - 1]?.focus();
    }
  };

  return (
    <>
      <section className=" px-[30px] py-[0]">
        <div className="max-w-screen-xl mx-[auto] my-[0] relative z-10">
          <div className="relative">
            <div className="mx-[auto] my-[0] md:pt-[60px] w-full sm:w-[380px] ">
              {!otpsection ? (
                <div className="flex items-start flex-col justify-start gap-6 b">
                  <h1 className="text-[24px] font-bold leading-[26.4px] tracking-[-0.01em] text-left text-[#17232A]">
                    Track your order
                  </h1>

                  <div className="hidden flex flex-col gap-4 items-start w-full">
                    <label
                      className="text-[#7E818C] text-[14px] font-semibold leading-[15px] text-left"
                      htmlFor="order-id"
                    >
                      Enter order ID
                    </label>
                    <input
                      disabled
                      type="text"
                      name="order-id"
                      value={orderValue}
                      onChange={(e) => setOrderValue(e.target.value)}
                      className="text-[#7E818C] text-[16px] font-semibold leading-[15px] text-left w-full h-[50px] p-[16px] rounded-[8px] border-[1px] border-[solid] border-[#D1D6DE]"
                    />
                    {orderErrorMessage && (
                      <p style={{ color: "red" }}>{orderErrorMessage}</p>
                    )}
                  </div>

                  <div className="flex items-center w-full hidden">
                    <div className="w-1/2 h-[1px] bg-[#EAECF0]"></div>
                    <div className="px-[10px] py-[0] text-[16px] font-semibold leading-[20px] text-center text-[#475467]">
                      Or
                    </div>
                    <div className="w-1/2 h-[1px] bg-[#EAECF0]"></div>
                  </div>

                  <div className="flex flex-col gap-4 items-start w-full">
                    {/* <label
                      className="text-[#7E818C] text-[14px] font-semibold leading-[15px] text-center"
                      htmlFor="phn-no"
                    >
                      Email ID.
                    </label> */}
                    <input
                      type="email"
                      name="phn-no"
                      placeholder="Enter Your Email Id"
                      value={phoneNumberValue}
                      onChange={(e) => setPhoneNumberValue(e.target.value)}
                      className="text-[#7E818C] text-[16px] font-semibold leading-[15px] text-left w-full h-[50px] p-[16px] rounded-[8px] border-[1px] border-[solid] border-[#D1D6DE]"
                    />
                    {phnNoErrorMessage && (
                      <p style={{ color: "red" }}>{phnNoErrorMessage}</p>
                    )}
                  </div>

                  <button
                    onClick={handleLogin}
                    className="w-full p-[20px] rounded-[58px] bg-[#03977E] text-[18px] font-semibold leading-[22px]  text-[#FFFFFF] text-center"
                  >
                    Track
                  </button>
                </div>
              ) : (
                <></>
              )}
              {otpsection ? (
                <div className="otp-form">
                  <div className="form">
                    <span
                      className="close"
                      onClick={() => {
                        setRetries(0);
                        setotpsection(false);
                      }}
                    >
                      <FiArrowLeft />
                    </span>

                    <div className="info">
                      <span className="title">Enter OTP</span>
                      <p className="description">
                        Verify your email id by entering the code sent to{" "}
                        {phoneNumberValue}
                      </p>
                      {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
                    </div>
                    <div className="inputs">
                      {Array.from({ length: 6 }).map((_, index) => (
                        <input
                         
                          key={index}
                          onChange={(e) =>
                            handleOTPValues(e.target.value, index)
                          }
                          placeholder=""
                          type="tel"
                          maxLength={1}
                          className="otp-input "
                          id={`otp-${index}`}
                        />
                      ))}
                    </div>
                    {retries <= 2 ? (
                      <p className="resend">
                        Haven't received the OTP
                        <a className="resend-action">
                          <span onClick={resendOTP}>Resend</span> in {seconds}
                        </a>
                      </p>
                    ) : (
                      <></>
                    )}

                    <button
                      onClick={verifyOtp}
                      className="w-full p-[20px] mt-2 rounded-[58px] bg-[#03977E] text-[18px] font-semibold leading-[22px]  text-[#FFFFFF] text-center"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
