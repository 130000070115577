import { FaCircleXmark } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";

export const PaymentFailuremsg: React.FC = () => {

    const [searchParams] = useSearchParams();
    const payment_id = searchParams.get('payment_id')

    return (
        <>
            <section className="h-full overflow-hidden ">
                <div className="font-sans   lg:min-w-[30%] h-full top-[50%] left-[50%] -translate-x-1/2 -translate-y-[60%]  absolute  flex flex-col justify-center gap-3 items-center overflow-hidden ">
                    <FaCircleXmark
                        size={80}
                        className=" text-[#bc2b2b] border-4 border-red-100 rounded-full"
                    />

                    <h1 className="font-bold lg:text-3xl text-[#2a2a2a]  text-2xl text-center ">
                        Payment Failed!
                    </h1>
                    <p className="font-sans text-base font-medium  text-center">
                        Payment ID: {payment_id}
                    </p>
                    <p>Payment failed !, if money deducted please reach use  <strong className="font-sans"> hello@thewowkid.com</strong>. </p>
                    <p>OR</p>
                    <p>Please reach us through whatsapp</p>
                </div>
            </section>
        </>
    );
};