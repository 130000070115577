import { useEffect, useRef, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { ProductWrapper } from "../../implementaion/product-wrapper/product-wrapper";
import "./cart.scss";
import starRating from "../../assets/images/svg/star.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import mainLogo from "../../assets/images/svg/wow_kid_logo.svg";
import cart_empty from "../../assets/images/svg/shopping.svg";
import { useLocalStorage } from "../../implementaion/localStorage/localStorageHandler";
import { useFetch } from "../../common/https";
import { email_regex, phone_regex } from "../../implementaion/validation/Regex_validation";
import uploadImg from "../../assets/images/svg/upload-icon.svg";
import { Modal } from "../../common/modal-popup/modal-popup";
import plusButton from '../../assets/images/png/plus-button.png';
import minusButton from '../../assets/images/png/minus-button.png';
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import State_and_District  from "../cart/State-and-District.json"

export const Cart: React.FC = () => {
  const navigate = useNavigate();
  const { fetchRest } = useFetch({});
  const { localStorageData, updateLocalStorage, deleteLocalStorage } =
    useLocalStorage();
  const initialized = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [ordersInCart, setOrdersInCart] = useState<any>();
  const [addressState, setAddressState] = useState("init");
  const [selectAll, setSelectAll] = useState(true);
  const location = useLocation()

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    state: "",
    city: "",
    pinCode: "",
  });

  const handleCheckboxChange = (cartId: number) => {
    const updatedCheckboxes = ordersInCart.map((cart: any, index: number) => {
      if (index === cartId) {
        cart.isSelected = !cart.isSelected;
      }
      return cart;
    });
    setOrdersInCart(updatedCheckboxes);
    const allChecked = Object.values(updatedCheckboxes).every(
      (value: any) => value.isSelected
    );
    setSelectAll(allChecked);
  };

  const handleSelectAllChange = () => {
    const newValue = !selectAll;
    const updatedCheckboxes = ordersInCart.map((cart: any) => {
      return {
        ...cart,
        isSelected: newValue,
      };
    });
    setOrdersInCart(updatedCheckboxes);
    setSelectAll(newValue);
  };

  const handlePaymentState = (paymentResponse: any) => {
    const payloadObj = {
      address: formData,
      paymentDetails: paymentResponse,
      orderDetails: ordersInCart?.map((order: any) => {
        const ord = {
          ...order?.orderDetail,
          templateId: order?.orderDetail?.templateId,
        };
        delete ord.orderId;
        return ord;
      }),
    };
    fetchRest({
      url: "set-order",
      method: "POST",
      data: payloadObj,
      isFullPageLoader: true,
    })
      .then((res) => {
        const orders = localStorageData?.['wow_kids_orders'];
        const orderIds = JSON.parse(orders);
        orderIds.forEach((orderId: string) => {
          deleteLocalStorage(
            orderId
          );
        });
        updateLocalStorage('wow_kids_orders', JSON.stringify([]));
        navigate(
          `payment/success?payment_id=${paymentResponse?.razorpay_payment_id}&razorpay_order_id=${paymentResponse?.razorpay_order_id}`
        );
      })
      .catch((err) => {
        navigate(
          `payment/failed?payment_id=${paymentResponse?.razorpay_payment_id}&razorpay_order_id=${paymentResponse?.razorpay_order_id}`
        );
      });
  };

  useEffect(() => {
    const orders = localStorageData["wow_kids_orders"];
    if (orders) {
      const orderIds = JSON.parse(orders);
      if (orderIds?.length) {
        const orderDetails: any = [];
        orderIds.forEach((id: string) => {
          const data =
            localStorageData[id] && JSON.parse(localStorageData[id]);
          if (data) {
            orderDetails.push({ ...data, orderId: id });
          }
        });

        if (orderDetails?.length) {
          fetchRest({
            url: "get-products-info",
            method: "POST",
            data: { prds: orderDetails.map((res: any) => res.prdId) },
            isFullPageLoader: true,
          })
            .then((res) => {
              const prdData = res?.data?.data;
              const prdOrders: any = [];
              orderDetails?.forEach((ord: any) => {
                const result = prdData.filter(
                  (prd: any) => prd.id === ord.prdId
                )?.[0];
                prdOrders.push({
                  orderDetail: ord,
                  prdInfo: result,
                  isSelected: true,
                });
              });
              setOrdersInCart(prdOrders);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        setOrdersInCart([]);
      }
    } else {
      setOrdersInCart([]);
    }
  }, [localStorageData])


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname === "/cart?state=info"])

  return (
    <>
      <section className="px-[30px] py-[0] mb-[30px] sm:w-full md:w-full lg:w-full">
        <div className="max-w-screen-xl mx-[auto] my-[0] sm:w-full md:w-full lg:w-full">
          <div className="md:pt-[60px]">
            <div className="flex  gap-6  flex-col lg:flex md:flex   lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-[65%] lg:flex lg:flex-col flex flex-col w-full items-center">
                {!searchParams.get("state") ||
                  searchParams.get("state") === "info" ? (
                  <CartInfoState
                    selectAll={selectAll}
                    handleSelectAllChange={handleSelectAllChange}
                    ordersInCart={ordersInCart}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                ) : searchParams.get("state") === "address" ? (
                  <AddressForm
                    addressState={addressState}
                    setAddressState={setAddressState}
                    formData={formData}
                    setFormData={setFormData}
                  />
                ) : (
                  <></>
                )}
              </div>

              {searchParams.get("state") !== "payment" ? (
                <div className="w-full lg:w-[30%]">
                  <CartBillInfo
                    ordersInCart={ordersInCart}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    addressState={addressState}
                    setAddressState={setAddressState}
                    addressForm={formData}
                    handlePaymentState={handlePaymentState}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* {!searchParams.get("state") || searchParams.get("state") === "info" ? (
        <ProductWrapper />
      ) : (
        <></>
      )} */}
    </>
  );
};



const AddressForm: React.FC<{
  addressState: any;
  setAddressState: any;
  formData: any;
  setFormData: any;
}> = ({ addressState, setAddressState, formData, setFormData }) => {
  const [errors, setErrors] = useState<any>({});
  const location = useLocation()
  const rules: Record<string, { regex: RegExp; message: string }> = {
    phoneNumber: { regex: phone_regex, message: "Phone number is not valid" },
    email: { regex: email_regex, message: "Email is not valid" },
  };
  const validate = () => {
    const newErrors: any = {};
    if (!formData.fullName) newErrors.fullName = "Full name is required.";
    if (!formData.phoneNumber) newErrors.phoneNumber = "Phone number is required.";
    if (!formData.email) newErrors.email = "Email is required"
    if (!formData.addressLine1) newErrors.addressLine1 = "Address Line 1 is required.";
    if (!formData.addressLine2) newErrors.addressLine2 = "Address Line 2 is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.pinCode) newErrors.pinCode = "Pin code is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors((prev: any) => ({ ...prev, [name]: null }));
    }
    // Perform validation if a rule exists for the field
    if (rules[name]) {
      const test = rules[name].regex.test(value);
      console.log(test);
      const err = {
        ...errors
      };
      if (!test) {
        err[name] = test ? null : rules[name].message;
      } else {
        err[name] = null;
      }
      setErrors(err);
    }

    if (addressState === "valid") {
      setAddressState("check");
    } else {
      let isValid = true;
      for (var key of Object.keys(errors)) {
        if (errors[key]) {
          isValid = false;
          break;
        }
      }
      if (isValid) {
        setAddressState("valid");
      }
    }
  };
  
  const handleSubmit = () => {
    if (validate()) {
      setAddressState("valid");
      console.log(formData);
    } else {
      setAddressState("invalid");
    }
  };

  useEffect(() => {
    if (addressState === "check" || addressState === "invalid") {
      handleSubmit();
    }
  }, [addressState]);



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname === "/cart?state=address"])

  return (
    <form className="address-form  w-100 overflow-x-hidden" onSubmit={handleSubmit}  >
      <h2 className="text-[24px] font-bold leading-[32px] text-left text-[#242631]">
        Address
      </h2>

      <h3 className="mt-[28px] text-[18px] font-bold leading-[24px] text-left text-[#242631]">
        Contact info
      </h3>
      <div className="flex items-center justify-between gap-[10px] sm:gap-[20px] md:gap-[20px] flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2 ">
          <label>Enter your full name*</label>
          <input
         
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="!text-[0.9rem] lg:!text-[1rem] md:!text-[1rem]"
          />
          {errors.fullName && <p style={{ color: "red" }}>{errors.fullName}</p>}
        </div>

        <div className="w-full sm:w-1/2">
          <label>Phone number*</label>
          <input
            type="number"
            name="phoneNumber"
            minLength={10}
            maxLength={10}
            value={formData.phoneNumber}
            onChange={handleChange}
             className="!text-[0.9rem] lg:!text-[1rem] md:!text-[1rem]"
          />
          {errors.phoneNumber ?
            <p style={{ color: "red" }}>{errors.phoneNumber}</p> : <></>
          }
        </div>
      </div>
      <div className="flex items-center justify-between gap-[10px] sm:gap-[20px] md:gap-[20px] flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2">
          <label>Email*</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
             className="!text-[0.9rem] lg:!text-[1rem] md:!text-[1rem]"
          />
          {errors.email && (
            <p style={{ color: "red" }}>{errors.email}</p>
          )}
        </div>
      </div>

      <h3 className="mt-[20px] text-[18px] font-bold leading-[24px] text-left text-[#242631]">
        Shipping address
      </h3>

      <div className="flex items-center justify-between gap-[10px] sm:gap-[20px] md:gap-[20px] flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2">
          <label>Address line 1*</label>
          <input
            type="text"
            name="addressLine1"
            value={formData.addressLine1}
            onChange={handleChange}
             className="!text-[0.9rem] lg:!text-[1rem] md:!text-[1rem]"
          />
          {errors.addressLine1 && (
            <p style={{ color: "red" }}>{errors.addressLine1}</p>
          )}
        </div>

        <div className="w-full  sm:w-1/2">
          <label>Address line 2*</label>
          <input
            type="text"
            name="addressLine2"
            value={formData.addressLine2}
            onChange={handleChange}
             className="!text-[0.9rem] lg:!text-[1rem] md:!text-[1rem]"
          />
          {errors.addressLine2 && (
            <p style={{ color: "red" }}>{errors.addressLine2}</p>
          )}
        </div>
      </div>

      <div className="flex items-center justify-between gap-[10px] sm:gap-[20px] md:gap-[20px] flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2">
          <label>Landmark</label>
          <input
            type="text"
            name="landmark"
            value={formData.landmark}
            onChange={handleChange}
             className="!text-[0.9rem] lg:!text-[1rem] md:!text-[1rem]"
          />
        </div>

        <div className="w-full sm:w-1/2">
          <label>State*</label>
          <select
            name="state"
            value={formData.state}
            onChange={handleChange}
          >
            <option value="">Select state</option>
            {
              State_and_District?.states?.map((state: any) => {
                return <option key={state?.state} value={state?.state}>{state?.state}</option>
              } 
              )
            }
          </select>
          {errors.state && <p style={{ color: "red" }}>{errors.state}</p>}
        </div>
      </div>

      <div className="flex items-center justify-between gap-[10px] sm:gap-[20px] md:gap-[20px] flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2">
          <label>City*</label>
          <select name="city" value={formData.city} onChange={handleChange}>
            <option className="" value="">Select city</option>
            {State_and_District.states.find(s => s.state === formData.state)?.districts.map((d, i) => (
      <option key={i} value={d}>{d}</option>
    ))}
          </select>
          {errors.city && <p style={{ color: "red" }}>{errors.city}</p>}
        </div>

        <div className="w-full sm:w-1/2">
          <label>Pin code*</label>
          <input
            type="text"
            name="pinCode"
            value={formData.pinCode}
            onChange={handleChange}
          />
          {errors.pinCode && <p style={{ color: "red" }}>{errors.pinCode}</p>}
        </div>
      </div>
    </form>
  );
};

const CartBillInfo: React.FC<{
  ordersInCart: any;
  searchParams: any;
  setSearchParams: any;
  addressState: any;
  setAddressState: any;
  addressForm: any;
  handlePaymentState: any;
}> = ({
  ordersInCart,
  searchParams,
  setSearchParams,
  addressState,
  setAddressState,
  addressForm,
  handlePaymentState,
}) => {
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();
    const { fetchRest } = useFetch({});
    const [billInfo, setBillInfo] = useState({
      originalVal: 0,
      cuttOffVal: 0, 
      totalAmount: 0,
      totalDiscount: 0,
      totalItems: 0,
      checkVal: 0,
      defaultDiscount: 0
    });
    const [coupanVal, setCoupanVal] = useState<any>();
    const [coupanErr, setCoupanErr] = useState('');
    const [coupan, setCoupan] = useState<any>();
    const [openCoupanField, setOpenCoupanField] = useState<any>(false);

    useEffect(() => {
      console.log(ordersInCart);
      setOrders(ordersInCart);
    }, [ordersInCart])

    const openPayModal = () => {
      if (addressState === "valid") {
        ordersInCart.forEach((order: any) => {
          order.address = addressForm;
        });
        if (ordersInCart.length) {
          fetchRest({
            url: "create-payment-order",
            method: "POST",
            data: {
              prdIds: ordersInCart.map((res: any) => { return { prdId: res.orderDetail.prdId, count: res.orderDetail.count } }),
              coupanCode: coupan?.key
            },
            isFullPageLoader: true,
          })
            .then((res) => {
              const options = {
                key: process.env.REACT_APP_RAZOR_PAY_KEY_ID,
                amount: res?.data?.data?.amount,
                name: "WowKids",
                description: "Payment for your order",
                image: mainLogo,
                order_id: res?.data?.data?.id,
                handler: function (response: any) {
                  fetchRest({
                    url: "verify-payment-order",
                    method: "POST",
                    data: {
                      orderId: response.razorpay_order_id,
                      paymentId: response.razorpay_payment_id,
                      signature: response.razorpay_signature,
                      coupanCode: coupan?.key
                    },
                    isFullPageLoader: true,
                  })
                    .then((res) => {
                      console.log(res.data);
                      if (res.data) {
                        handlePaymentState(response);
                      } else {
                        navigate(
                          `payment/failed?payment_id=${response.razorpay_payment_id}&razorpay_order_id=${response.razorpay_order_id}`
                        );
                      }
                    })
                    .catch((err) => {
                      navigate("payment/server-error");
                    });
                },
                prefill: {
                  name: addressForm?.fullName,
                  contact: addressForm?.phoneNumber,
                  email: addressForm?.email,
                },
                notes: {
                  address: "Wowkids PVT LTD",
                },
                theme: {
                  color: "#D8F1E7",
                  hide_topbar: false,
                },
              };
              var rzp1 = new (window as any).Razorpay(options);
              rzp1.open();
            })
            .catch((err) => {
              console.error(err);
            });
        }
      } else {
        setAddressState("check");
      }
    };

    useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.async = true;
      document.body.appendChild(script);
    }, []);

    useEffect(() => {
      const amountDetails = {
        originalVal: 0,
        cuttOffVal: 0,
        totalAmount: 0,
        totalDiscount: 0,
        totalItems: 0,
        checkVal: 0,
        defaultDiscount: 0
      };
      orders?.forEach((order: any) => {
        if (order.isSelected) {
          const totalVal = (Number(order.prdInfo.offerPriceInVal) * (order?.orderDetail?.count || 1));
          amountDetails.cuttOffVal += Number(order.prdInfo.price) * (order?.orderDetail?.count || 1);
          amountDetails.checkVal += Number(order.prdInfo.price) * (order?.orderDetail?.count || 1)
          amountDetails.originalVal += totalVal;
          amountDetails.defaultDiscount += (amountDetails.checkVal -
            ((Number(order.prdInfo.offerPriceInVal) * (order?.orderDetail?.count || 1))))
          amountDetails.totalItems += 1;
        }
      });

      let totalOff = 0;
      if (
          coupan &&
          amountDetails.originalVal > coupan?.minVal && 
          amountDetails.originalVal < coupan?.maxVal
        ) {
        if (coupan?.isValueBasedCoupan) {
          totalOff = coupan?.valueOff;
        }
        if (coupan?.isPercentageBasedCoupan) {
          totalOff = ((coupan?.percentageOff / 100) * amountDetails.originalVal);
        }
        totalOff = totalOff > 0 ? totalOff : 0;
      }
      amountDetails.totalAmount = (amountDetails.originalVal - totalOff);
      amountDetails.totalDiscount = totalOff;

      setBillInfo(amountDetails);
    }, [orders]);

    const onCoupanCodeSubmit = () => {
      if (coupanVal) {
        fetchRest({
          url: "check-coupan",
          method: "POST",
          data: {
            coupanCode: coupanVal,
          },
          isFullPageLoader: true,
        }).then((res) => {
          setCoupan(res.data);
          const obj = billInfo;
          let totalOff = 0;
          if (
            billInfo.originalVal > res.data?.minVal && 
            billInfo.originalVal < res.data?.maxVal
          ) {
            if (res.data?.isValueBasedCoupan) {
              totalOff = res.data?.valueOff;
            }
            if (res.data?.isPercentageBasedCoupan) {
              totalOff = ((res.data?.percentageOff / 100) * obj.originalVal);
            }
            totalOff = totalOff > 0 ? totalOff : 0;
          }
          obj.totalAmount = obj.originalVal - totalOff;
          obj.totalDiscount = totalOff;
          setBillInfo(obj);
          setCoupanVal('');
          setCoupanErr('');
          setOpenCoupanField(false);
        }).catch((e) => {
          setCoupanErr(e?.response?.data?.error?.message || 'Invalid coupan code !')
        })
      } else {
        setCoupanErr('Coupan code is required !')
      }
    }

    const handleCoupanVal = (coupan: string) => {
      if (coupan) {
        setCoupanVal(coupan);
        setCoupanErr('');
      } else {
        setCoupanErr('Coupan code is required !')
      }
    }


    return (
      <>
        <div className="p-[24px] rounded-[12px] bg-[#F9FAFB] border-[1px] border-[solid] border-[#DFE9EF]">
          <div className="items-center flex justify-between">
            <h1 className="text-[20px] font-bold leading-[26px] text-left text-[#242631]">Apply coupons</h1>
            <button onClick={() => { setOpenCoupanField(!openCoupanField) }} className="text-[14px] font-semibold leading-[17px] tracking-[0.01em] text-right [text-decoration-line:underline] [text-decoration-style:solid] text-[#03977E]">
              {
                openCoupanField ? 'Cancel' : (coupan ? 'change' : 'Apply')
              }
            </button>
          </div>
          {
            openCoupanField ?
              <div className="flex items-center flex-col">
                <div className="coupan-form w-100">
                  <input className="w-100 mt-[20px]" onChange={(e) => handleCoupanVal(e.target.value)} type="text" />
                  {coupanErr && coupanErr !== '' ?
                    <span className="error-message">
                      {coupanErr}
                    </span> : <></>
                  }
                  <button onClick={() => onCoupanCodeSubmit()} className="mt-[20px] w-full p-[10px] rounded-[12px] bg-[#03977E] text-[18px] font-semibold leading-[22px] text-[#FFFFFF] text-center">Apply</button>
                </div>
              </div>
              : <></>
          }

          {
            coupan &&
            billInfo.originalVal > coupan?.minVal && 
            billInfo.originalVal < coupan?.maxVal 
            ? 
            <div className="mt-[20px]">
              <div className="selected-coupan-name text-[#03977E]">Applied {coupan?.isPercentageBasedCoupan ? coupan?.percentageOff + '%' : '₹' + coupan?.valueOff  } off</div>
              <div className="coupan-key  lg:tracking-[10px] tracking-[8px] lg:text-[20px] text-[16px]">{coupan?.key}</div>
            </div>
            : <>
              {
                coupan ?
                <div className="mt-4">
                  <span className="error-message">
                  Coupan is not valid for this order, minimum order should be more than {coupan?.minVal} and less than {coupan?.maxVal}
                </span>
                </div>
                 : <></>
              }
            </>
          }

        </div>
        <div className="w-full  mt-4 flex-col items-start flex justify-between p-[24px] rounded-[12px] bg-[#F9FAFB] border-[1px] border-[solid] border-[#DFE9EF] ">
          <h1 className="mb-4 text-[20px] font-bold leading-[26px] text-left text-[#242631]">
            Price details
          </h1>
          <div className="mt-2 flex flex-col items-center gap-4 w-full">
            <div className="flex-wrap flex justify-between w-full">
              <h3 className="text-[16px] font-normal leading-[20.8px] text-left text-[#242631]">
                Price (
                {billInfo.totalItems > 1
                  ? `${billInfo.totalItems} items`
                  : `1 item`}
                )
              </h3>
              <div className="flex items-center gap-2">
                {
                  billInfo.cuttOffVal > 0 ?
                    <h2 className="line-through text-[16px] font-semibold leading-[20px] text-right text-[#687688]">
                      ₹{(Math.round((billInfo.cuttOffVal) * 100) / 100).toFixed(2)}
                    </h2> : <></>
                }
                <h2 className="text-[16px] font-semibold leading-[20px] text-right text-[#242631]">
                  ₹{(Math.round((billInfo.originalVal) * 100) / 100).toFixed(2)}
                </h2>
              </div>
            </div>
            <div className="flex-wrap flex justify-between w-full">
              <h3 className="text-[16px] font-normal leading-[20.8px] text-left text-[#242631]">
                Discount
              </h3>
              <div className="flex items-center gap-2">
                {
                  billInfo.defaultDiscount > 0 ?
                  <h2 className="text-[16px] font-semibold leading-[20px] text-right text-[#687688]">
                    (₹{(Math.round((billInfo.defaultDiscount) * 100) / 100).toFixed(2)})
                  </h2> : <></>
                }
                {
                  billInfo.totalDiscount > 0 ?
                  <h2 className="text-[16px] font-semibold leading-[20px] text-right text-[#242631]">
                    + ₹{(Math.round((Math.abs(billInfo.totalDiscount)) * 100) / 100).toFixed(2)}
                  </h2> : <></>
                }
              </div>
            </div>
            <div className="flex-wrap flex justify-between w-full">
              <h3 className="text-[16px] font-normal leading-[20.8px] text-left text-[#242631]">
                Delivery
              </h3>
              <h2 className="text-[16px] font-semibold leading-[20px] text-right text-[#03977E]">
                Free
              </h2>
            </div>
          </div>
          <hr className="w-full" />
          <div className="flex-wrap flex justify-between w-full">
            <h3 className="text-[20px] font-bold leading-[26px] text-left text-[#242631]">
              Total
            </h3>
            <h2 className="text-[20px] font-bold leading-[26px] text-left text-[#242631]">
              ₹{(Math.round((billInfo.totalAmount) * 100) / 100).toFixed(2)}
            </h2>
          </div>
        </div>
        {billInfo.totalItems ? (
          <>
            {searchParams.get("state") === "info" ? (
              <button
                onClick={() =>
                  setSearchParams((params: any) => {
                    params.set("state", "address");
                    return params;
                  })
                }
                className="mt-[30px] w-full p-[20px] rounded-[58px] bg-[#03977E] text-[18px] font-semibold leading-[22px]  text-[#FFFFFF] text-center"
              >
                Add Address
              </button>
            ) : (
              <button
                onClick={() => openPayModal()}
                className="mt-[30px] w-full p-[20px] rounded-[58px] bg-[#03977E] text-[18px] font-semibold leading-[22px]  text-[#FFFFFF] text-center"
              >
                Place Order
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

const CartInfoState: React.FC<{
  selectAll: any;
  handleSelectAllChange: any;
  ordersInCart: any;
  handleCheckboxChange: any;
}> = ({
  selectAll,
  handleSelectAllChange,
  ordersInCart,
  handleCheckboxChange,
}) => {
    const { localStorageData, updateLocalStorage, deleteLocalStorage } = useLocalStorage();
    const [isModalOpen, setModalState] = useState(false);
    const [selectedPrdForEdit, setSelectedPrdForEdit] = useState<any>();
    const setTplusDays = (days: number) => {
      const result = new Date();
      result.setDate(result.getDate() + days);
      const month = result.toLocaleString("default", { month: "long" });
      return `${result.getDate()} - ${month} - ${result.getFullYear()}`;
    };

    const removeAllOrders = () => {
      const orders = localStorageData?.['wow_kids_orders'];
      const orderIds = JSON.parse(orders);
      orderIds.forEach((orderId: string) => {
        deleteLocalStorage(
          orderId
        );
      });
      updateLocalStorage('wow_kids_orders', JSON.stringify([]));
    }

    const removeOrderFromCart = (id: string) => {
      console.log('removeOrderFromCart');
      const orders = localStorageData?.['wow_kids_orders'];
      const orderIds = JSON.parse(orders);
      const index = orderIds.indexOf(id);
      if (index > -1) {
        orderIds.splice(index, 1);
      }
      updateLocalStorage('wow_kids_orders', JSON.stringify(orderIds));
      deleteLocalStorage(
        id
      );
    }

    const handleEditFromEdit = (orderInfo: any) => {
      setModalState(true);
      setSelectedPrdForEdit(orderInfo);
    }

    const increasePrdCount = (order: any) => {
      if (order?.count >= 1) {
        updateLocalStorage(
          order.orderId,
          JSON.stringify({
            ...order,
            count: order.count + 1
          }
          )
        );
      }
    }

    const decreasePrdCount = (order: any) => {
      if (order?.count > 1) {
        updateLocalStorage(
          order.orderId,
          JSON.stringify({
            ...order,
            count: order.count - 1
          }
          )
        );
      }
    }

    return (
      <>
        <Modal
          heading={"Edit Details"}
          isOpen={isModalOpen}
          onClose={() => setModalState(!isModalOpen)}
        >
          <section className="m-[12px] mt-0 p-[24px] rounded-[20px] border-[1px] border-solid border-[#EAECF0]  overflow-y-auto scrollbar-hide">
            <AddDetailsFormHandlerTemplate
              selectedPrdForEdit={selectedPrdForEdit}
              setModalState={setModalState}
            />
          </section>
        </Modal>
        <h1 className="text-[24px] font-bold leading-[27px] text-left text-[#242631]">
          My shopping cart
        </h1>
      

        <hr />

        {ordersInCart && ordersInCart?.length ? (
          <div className="flex flex-col gap-5 items mt-0">
             <div className="w-full flex justify-center -translate-y-10">
              <a
                      className="lg:px-6 lg:py-3 px-4 py-2 bg-[#039774] rounded-md text-white text-[14px] font-light text-nowrap hover:pointer-events-auto hover:bg-transparent hover:text-[#039774] hover:border-2 hover:border-[#039774]"
                      href="/"
                    >
                Continue Shopping
                    </a>
              </div>
            {ordersInCart.map((cart: any, index: number) => (
              <>
                <div
                  className="flex gap-4 justify-between items-start p-[16px] rounded-[12px] bg-[#FFFFFF] border-[1px] border-solid border-[#EAECF0]"
                  key={index}
                >
                  <div className="flex lg:flex-wrap lg:gap-4  items-start w-full  sm:w-full md:w-full lg:w-full">
                    <div className="flex flex-row gap-[20px]  w-full  sm:w-full  md:w-full lg:w-full lg:flex lg:flex-row">
                      <div className="flex justify-center items-center">
                        <img
                          className="rounded-[12px] h-auto w-[200px] lg:w-[150px] object-cover"
                          src={
                            process.env.REACT_APP_IMG_CDN_URL +
                            cart?.prdInfo?.defaultImg
                          }
                          alt=""
                        />

                      </div>
                      <div className="flex lg:justify-start items-center lg:flex lg:w-[calc(100%-170px)] ">
                        <div className="flex flex-col gap-[5px] lg:gap-[30px] lg:justify-between ">
                          <div className="flex flex-col gap-2 ">
                            <h1 className="lg:text-[20px] text-[13px] text-wrap font-bold lg:leading-[28px] text-left text-[#242631]">
                              {cart?.prdInfo?.name}
                            </h1>

                          </div>
                          <div>
                            {cart?.prdInfo?.offerPriceInVal ? (
                              <>
                                <div className="flex align-center items-baseline lg:gap-6 gap-2 lg:mt-2">
                                  <div className="lg:text-[25px] text-[18px] font-bold lg:leading-[24px] text-left">
                                    &#8377;{cart?.prdInfo?.offerPriceInVal}
                                  </div>

                                  <div className="lg:text-[16px] text-[12px] font-medium lg:leading-[20.8px] text-left text-[#687588] line-through">
                                    &#8377;{cart?.prdInfo?.price}
                                  </div>

                                  {cart?.prdInfo?.offerPriceInPercentage ? (
                                    <div className=" text-[#067647] rounded-[16px] lg:text-[16px] text-[12px] font-semibold lg:leading-[20px] text-center  text-nowrap ">
                                      {cart?.prdInfo?.offerPriceInPercentage * 100}%
                                      Off
                                    </div>
                                  ) : (
                                    <></>
                                  )}


                                </div>

                                <div className="w-full flex flex-row gap-5 items-end h-full    lg:hidden md:hidden  ">


                                  <div className="flex lg:gap-[20px] gap-[50px]  pt-3 ">
                                    {/* <button
                                      onClick={() => {
                                        handleEditFromEdit(cart);
                                      }}
                                      className=" text-[#0F1727] text-[12px] font-semibold leading-[20px] text-left "
                                    >
                                      Edit
                                    </button> */}
                                    <button
                                      onClick={() => {
                                        removeOrderFromCart(
                                          cart.orderDetail.orderId
                                        );
                                      }}
                                      className=" text-[#0F1727] text-[10px]  font-[600] leading-[20px] text-left "
                                    >
                                      Remove
                                    </button>

                                    <div className="lg:hidden md:hidden flex    gap-[9px] items-center ">
                                      <img
                                        onClick={() =>
                                          decreasePrdCount(cart.orderDetail)
                                        }
                                        className="lg:w-[25px] lg:25px]  w-[18px] h-[18px]   cursor-pointer"
                                        src={minusButton}
                                        alt="minus-button"
                                      />
                                      <span>{cart.orderDetail.count}</span>
                                      <img
                                        onClick={() =>
                                          increasePrdCount(cart.orderDetail)
                                        }
                                        className="lg:25px] lg:25px] w-[18px] h-[18px] cursor-pointer"
                                        src={plusButton}
                                        alt="plus-buttom"
                                      />
                                    <span className="text-[10px]">  Items</span>

                                    </div>
                                  </div>
                                </div>

                              </>
                            ) : (
                              <>
                                <div className="text-[18px] font-bold leading-[24px] text-left">
                                  &#8377;{cart?.prdInfo?.price}
                                </div>
                              </>
                            )}
                          </div>

                          <div className="md:flex    lg:flex hidden gap-[15px] pl-1 pr-1 py-1  ">
                            <img
                              onClick={() => decreasePrdCount(cart.orderDetail)}
                              className="w-[25px] h-[25px] cursor-pointer border-r-[1px] "
                              src={minusButton}
                              alt="minus-button"
                            />
                            <span>{cart.orderDetail.count}</span>
                            <img
                              onClick={() => increasePrdCount(cart.orderDetail)}
                              className="w-[25px] h-[25px] cursor-pointer border-l-[1px]"
                              src={plusButton}
                              alt="plus-buttom"
                            />
                            
                          </div>
                        </div>

                        <div className=" flex-col-reverse justify-between  h-full hidden lg:flex md:flex  gap-[30px] lg:items-center ">


                          <div className="flex gap-[20px]">
                            {/* <button
                              onClick={() => {
                                handleEditFromEdit(cart);
                              }}
                              className=" text-[#0F1727] text-[16px] font-semibold leading-[20px] text-left "
                            >
                              Edit
                            </button> */}
                            <button
                              onClick={() => {
                                removeOrderFromCart(cart.orderDetail.orderId);
                              }}
                              className=" text-[#0F1727] text-[16px] font-semibold leading-[20px] text-left "
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              </>
            ))}

           

       

          </div>
        ) : (
          <>
            {" "}
            <div className=" w-full h-[250px]  flex  flex-col justify-center items-center  gap-2 ">
              <img className="w-[130px]" src={cart_empty} alt="" />
              <div className=" text-[black] lg:text-[25px] md:text-[25px] text-[18px] font-bold text-center">
                Nothing here yet! Start shopping now...
              </div>
              <a
                className="px-4 py-2 bg-[#03977e] rounded-md text-white text-[14px] font-light mt-2"
                href="/"
              >
                Return to shop
              </a>
            </div>
          </>
        )}
      </>
    );
  };


const AddDetailsFormHandlerTemplate: React.FC<{
  selectedPrdForEdit: any;
  setModalState: any;
}> = ({ selectedPrdForEdit, setModalState }) => {
  const formMapper: any = {
    name_slip_with_one_image: [
      "first_name",
      "last_name",
      "school",
      "std",
      "rollNo",
      "image1",
    ],
    name_slip_with_two_image: [
      "first_name",
      "last_name",
      "school",
      "std",
      "rollNo",
      "image1",
      "image2",
    ],
  };
  const { localStorageData, updateLocalStorage } = useLocalStorage();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<any>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const location = useLocation();
  const { fetchRest } = useFetch({});
  const [imageSrcVal, setImageSrcVal] = useState<any>({});
  const [imageSrcLoader, setImageSrcLoader] = useState<any>({});
  const [imageSrcErr, setImageSrcErr] = useState<any>({});
  const [imageFileData, setImageFileData] = useState<any>({});

  useEffect(() => {
    if (selectedPrdForEdit) {
      const templateKey = selectedPrdForEdit?.prdInfo?.template?.templateName;
      const orderId = selectedPrdForEdit.orderDetail.orderId;
      const orderInfo = JSON.parse(localStorageData?.[orderId]);
      if (formMapper[templateKey]) {
        let formObj: any = {};
        const keys = formMapper[templateKey];
        let imgSrc: any = {};
        let imgLoader: any = {};
        let imgErr: any = {};
        for (let i = 0; i < keys.length; i++) {
          formObj[keys[i]] = orderInfo[keys[i]];
          if (keys[i].includes('image')) {
            imgSrc[keys[i]] = process.env.REACT_APP_IMG_CDN_URL + orderInfo[keys[i]];
            imgLoader[keys[i]] = false;
            imgErr[keys[i]] = false
          }
        }
        setImageSrcVal({ ...imgSrc });
        setImageSrcLoader({ ...imgLoader });
        setImageSrcErr({ ...imgErr });
        if (Object.keys(formObj)?.length) {
          setFormData(formObj);
        }
      }
    }
  }, [selectedPrdForEdit]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleFileChange = (e: any, imageField: any) => {
    if (e?.target?.files?.[0]) {
      const imageName = formData[imageField] || '' + new Date().getTime();
      setImageFileData({
        ...imageFileData,
        [imageField]: e?.target?.files?.[0]
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setImageSrcVal({
            ...imageSrcVal,
            [imageField]: reader.result
          });
        }
      };
      reader.readAsDataURL(e?.target?.files?.[0]);
      setFormData({ ...formData, [imageField]: imageName });
      if (errors[imageField]) {
        setErrors((prev) => ({ ...prev, [imageField]: "" }));
      }
    }
  };

  const validateField = (key: string) => {
    if (key === "first_name" && !formData[key])
      return "First name is required.";
    if (key === "last_name" && !formData[key]) return "Last name is required.";
    // if (key === "school" && !formData[key]) return "school is required.";
    // if (key === "std" && !formData[key]) return "Standard is required.";
    // if (key === "rollNo" && !formData[key]) return "Roll No is required.";
    if (key === "image1" && !formData[key]) return "Image 1 is required.";
    if (key === "image2" && !formData[key]) return "Image 2 is required.";
    return undefined;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    for (var key of Object.keys(formData)) {
      const error = validateField(key);
      if (error) newErrors[key] = error;
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {

      let imagesUploadSuccess = true;
      try {
        if (formData?.hasOwnProperty('image1') && imageFileData['image1']) {
          await uploadImagesData('image1');
        }
        if (formData?.hasOwnProperty('image2') && imageFileData['image2']) {
          await uploadImagesData('image2');
        }
      } catch (e) {
        imagesUploadSuccess = false;
      }

      if (imagesUploadSuccess) {
        const orderId = selectedPrdForEdit.orderDetail.orderId;
        updateLocalStorage(
          orderId,
          JSON.stringify({
            ...selectedPrdForEdit.orderDetail,
            ...formData
          }
          )
        );
        setModalState(false);
      }

    }
  };

  const uploadImagesData = async (
    imageField: string
  ) => {
    return new Promise((resolve, reject) => {
      setImageSrcErr({
        ...imageSrcErr,
        [imageField]: false
      });
      setImageSrcVal({
        ...imageSrcVal,
        [imageField]: null
      });
      setImageSrcLoader({
        ...imageSrcLoader,
        [imageField]: true
      });
      const imageName = formData[imageField];
      fetchRest({
        url: `get-image-upload-url`,
        method: "POST",
        data: {
          fileName: imageName
        },
        isFullPageLoader: true,
      }).then((res) => {
        const signedURL = res.data?.uploadUrl;
        if (signedURL) {
          axios.put(signedURL, imageFileData[imageField], {
            headers: { "Content-Type": "image/*" },
          }).then((res) => {
            setImageSrcErr({
              ...imageSrcErr,
              [imageField]: false
            });
            resolve('Success');
          }).catch((err) => {
            setImageSrcErr({
              ...imageSrcErr,
              [imageField]: true
            });
            setImageSrcLoader({
              ...imageSrcLoader,
              [imageField]: false
            });
            reject('Error');
          })
        }
      })
        .catch((err) => {
          setImageSrcErr({
            ...imageSrcErr,
            [imageField]: true
          });
          setImageSrcLoader({
            ...imageSrcLoader,
            [imageField]: false
          });
          reject('Error');
        });
    });
  }

  return (
    <>
      <form
        className="w-[90vw] sm:w-[80vw]   h-[500px]  lg:w-[820px] max-w-[820px] flex flex-col justify-evenly items-start  overflow-y-auto lg:flex lg:flex-row  lg:gap-5"
        onSubmit={(e) => { handleSubmit(e) }}
        style={{ display: "flex", gap: "20px" }}
      >
        <div
          className="justify-between lg:flex lg:flex-col flex flex-col gap-[20px] "
          style={{ flex: "1" }}
        >
          {formData && formData.hasOwnProperty("image1") ? (
            <label className="lg:h-[280px] lg:w-full  h-[230px] w-full ">
              {errors["image1"] && (
                <span className="error-message">{errors["image1"]}</span>
              )}
              <div className="lg:h-[100%] h-full px-[24px] py-[16px] rounded-[12px] bg-[#f9f9f9] border-[2px] border-dashed border-[#D6D8DC] cursor-pointer text-center">
                <input
                  type="file"
                  accept=".png, .jpg"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "image1")}
                />
                <div className="flex items-center justify-center flex-col lg:h-full h-1/2 ">
                  {
                    imageSrcLoader["image1"] ?
                      <div className="w-100 h-100 flex items-center"><CircularProgress color="inherit" /></div> : <></>
                  }
                  {!formData["image1"] ? (
                    <>
                      <img
                        className="w-[40px] lg:h-[40px] h-[50px] mb-[10px]"
                        src={uploadImg}
                        alt=""
                      />
                      <div className="lg:flex lg:items-center flex  flex-col items-center justify-center">
                        <p className="text-[14px] font-semibold leading-[20px] text-left [text-decoration-line:underline] [text-decoration-style:solid] text-[#242631]">
                          Click to upload
                        </p>
                        &nbsp;
                        <small className="text-[14px] font-normal leading-[20px] text-left text-[#475467]">
                          or drag and drop PNG, JPG (max. 12 MB)
                        </small>
                      </div>
                    </>
                  ) : (
                    <div className="relative w-auto h-full">
                      <img
                        className="absolute w-[40px] h-[40px] top-[20px] right-[20px]"
                        src={uploadImg}
                        alt=""
                      />
                      {
                        imageSrcErr["image1"] ? <div className="w-100 h-100 flex items-center error-message">
                          Failed to upload !, please try again after sometime
                        </div> : <></>
                      }
                      {
                        !imageSrcLoader["image1"] && imageSrcVal["image1"] ?
                          <img
                            className="hover-img-bg lg:w-[100%]  lg:h-[100%] w-[220px] h-[190px] object-cover  object-center"
                            src={imageSrcVal["image1"]}
                            alt=""
                          />
                          : <></>
                      }
                    </div>
                  )}
                </div>
              </div>
            </label>
          ) : (
            <></>
          )}
          {formData && formData.hasOwnProperty("image2") ? (
            <label className="lg:h-[280px] lg:w-full  h-[230px] w-full ">
              {errors["image2"] && (
                <span className="error-message">{errors["image2"]}</span>
              )}
              <div className="lg:h-[100%] h-full   px-[24px] py-[16px] rounded-[12px] bg-[#F9FAFB] border-[2px] border-dashed border-[#D6D8DC] cursor-pointer text-center">
                <input
                  type="file"
                  accept=".png, .jpg"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "image2")}
                />
                <div className="flex items-center justify-center flex-col lg:h-full h-1/2">
                  {
                    imageSrcLoader["image2"] ?
                      <div className="w-100 h-100 flex items-center"><CircularProgress color="inherit" /></div> : <></>
                  }
                  {!formData["image2"] ? (
                    <>
                      <img
                        className="w-[40px] lg:h-[40px] h-[50px] mb-[10px]"
                        src={uploadImg}
                        alt=""
                      />
                      <div className="lg:flex lg:items-center flex  flex-col items-center justify-center ">
                        <p className="text-[14px] font-semibold leading-[20px] text-left [text-decoration-line:underline] [text-decoration-style:solid] text-[#242631]">
                          Click to upload
                        </p>
                        &nbsp;
                        <small className="text-[14px] font-normal leading-[20px] text-left text-[#475467]">
                          or drag and drop PNG, JPG (max. 12 MB)
                        </small>
                      </div>
                    </>
                  ) : (
                    <div className="relative w-auto h-full">
                      <img
                        className="absolute w-[40px] lg:h-[40px]   lg:top-[10px]   lg:right-[18px]   -top-5 left-3  "
                        src={uploadImg}
                        alt=""
                      />
                      {
                        imageSrcErr["image2"] ? <div className="w-100 h-100 flex items-center error-message">
                          Failed to upload !, please try again after sometime
                        </div> : <></>
                      }
                      {
                        !imageSrcLoader["image2"] && imageSrcVal["image2"] ?
                          <img
                            className="hover-img-bg  w-[220px] h-[190px] object-cover"
                            src={`${imageSrcVal["image2"]}`}
                            alt=""
                          />
                          : <></>
                      }
                    </div>
                  )}
                </div>
              </div>
            </label>
          ) : (
            <></>
          )}
        </div>
        <div style={{ flex: "1" }}>
          {formData && formData.hasOwnProperty("first_name") ? (
            <div style={{ marginBottom: "15px" }}>
              <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                First name{" "}
                {errors["first_name"] && (
                  <span className="error-message">
                    ({errors["first_name"]})
                  </span>
                )}
              </label>
              <input
                className="box-border h-[50px] w-[90%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                type="text"
                name="first_name"
                placeholder="Enter first name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}
          {formData && formData.hasOwnProperty("last_name") ? (
            <div style={{ marginBottom: "15px" }}>
              <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                Last name{" "}
                {errors["last_name"] && (
                  <span className="error-message">({errors["last_name"]})</span>
                )}
              </label>
              <input
                className="box-border h-[50px] w-[90%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                type="text"
                name="last_name"
                placeholder="Enter last name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}
          {formData && formData.hasOwnProperty("school") ? (
            <div style={{ marginBottom: "15px" }}>
              <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                School{" "}
                {errors["school"] && (
                  <span className="error-message">({errors["school"]})</span>
                )}
              </label>
              <input
                className="box-border h-[50px]  w-[90%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                type="text"
                name="school"
                placeholder="Enter school name"
                value={formData.school}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}
          <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
            {formData && formData.hasOwnProperty("std") ? (
              <div style={{ flex: "1" }}>
                <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                  Std.{" "}
                  {errors["std"] && (
                    <span className="error-message">({errors["std"]})</span>
                  )}
                </label>
                <input
                  className="box-border h-[50px] w-[80%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                  type="text"
                  name="std"
                  placeholder="Enter std number"
                  value={formData.std}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )}
            {formData && formData.hasOwnProperty("rollNo") ? (
              <div style={{ flex: "1" }}>
                <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                  Roll no.{" "}
                  {errors["rollNo"] && (
                    <span className="error-message">({errors["rollNo"]})</span>
                  )}
                </label>
                <input
                  className="box-border h-[50px]  w-[80%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                  type="text"
                  name="rollNo"
                  placeholder="Enter roll number"
                  value={formData.rollNo}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="lg:block md:block hidden">
            <button
              type="submit"
              className="bg-[#03977E] px-[10px] py-[18px] rounded-[58px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center"
            >
              Edit cart
            </button>
          </div>

          <div className=" lg:hidden md:hidden relative w-full h-auto  flex justify-center z-80">
            <div className="bg-white w-full h-[90px] fixed bottom-0 left-0 z-[80]"></div>
            <button
              type="submit"
              className="bg-[#03977E] px-[10px] py-[18px] rounded-[12px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center fixed bottom-3  left-5  z-[90] "
            >
              Edit Cart
            </button>
          </div>
        </div>
      </form>
    </>
  );
};