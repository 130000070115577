import { useEffect, useState } from "react";
import { ProductWrapper } from "../../implementaion/product-wrapper/product-wrapper";
import { useParams } from 'react-router-dom';
import { useFetch } from "../../common/https";

const categoryMap: any = {
    'name-slip': {
        pageType: 'plp',
        heading: 'Name slips',
        key: 'name-slip'
    },
    'note-book': {
        pageType: 'plp',
        heading: 'Note book',
        key: 'note-book'
    },
    'bag-tags': {
        pageType: 'plp',
        heading: 'Bag tags',
        key: 'bag-tags'
    },
    'story-books': {
        pageType: 'plp',
        heading: 'Story books',
        key: 'story-books'
    }
}

export const Category: React.FC = () => {
    const { fetchRest } = useFetch({});
    const { categoryId } = useParams();
    const [wrapperSetup, setWrapperSetup] = useState<any>(undefined);

    useEffect(() => {
        if (categoryId && categoryId !== '') {
            fetchRest({
                url: `get-category-by-key/${categoryId}`,
                method: 'GET',
                isFullPageLoader: true
            }).then((res) => {
                setWrapperSetup(res?.data?.data);
            }).catch((err) => {
                setWrapperSetup(categoryMap[categoryId]);
            });
            console.log(categoryId);
        }
    }, [categoryId]);

    return (
        <>
            <div>
                {
                    wrapperSetup ? <ProductWrapper
                        productKey="plp"
                        heading= ""    // {wrapperSetup.name}
                        subHeading= ""  //{wrapperSetup.description}
                        categoryId={wrapperSetup.id}
                    /> : <></>
                }
            </div>
        </>
    )
}