import { useEffect, useState } from "react";

import { StarRating } from "../../common/star-rating/star-rating";
import { OfferEndsInTimer } from "../../implementaion/offer-ends-in-timer/offer-ends-in-timer";
import trendingImg from "../../assets/images/svg/trend.svg";
import freeDelivery from "../../assets/images/svg/free-delivery.svg";
import dispatchTime from "../../assets/images/svg/dispatch-time.svg";
import waterProof from "../../assets/images/svg/water-proof.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import { FreeMode, Pagination, Autoplay } from "swiper/modules";

import { ProductWrapper } from "../../implementaion/product-wrapper/product-wrapper";
import { Modal } from "../../common/modal-popup/modal-popup";
import Tear_Free from "../../assets/images/svg/tear_free.svg";
import previewImg from "../../assets/images/png/dev-test/preview.jpeg";
import uploadImg from "../../assets/images/svg/upload-icon.svg";
import { useFetch } from "../../common/https";
import { v4 as uuidv4 } from "uuid";
import { useLocalStorage } from "../../implementaion/localStorage/localStorageHandler";
import Popup from "reactjs-popup";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import filledStar from "../../assets/images/svg/star-svgrepo-com.svg";


const dummyTestDetails = {
  noOfOrdersInLastNthDays: "1129+",
  lastNthDays: 30,
  customerCount: "20000+",
  reviewImgs: ["Promo1.png", "Promo2.png", "Promo3.png", "Promo4.png","Promo5.jpg","Promo6.jpg","Promo7.jpg","Promo8.jpg","Promo9.jpg"],
  promotionText:
    "Turn your kids school days into something special and they will love it! See what other parents say!",
};

export const DetailsSection: React.FC = () => {
  const { fetchRest } = useFetch({});
  const [productKey, setProductKey] = useState<string>();
  const [details, setDetails] = useState<any>();
  const [isModalOpen, setModalState] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const location = useLocation();

  useEffect(() => {
    const newProductKey = location.pathname.split("/")[2];
    if (newProductKey && newProductKey !== productKey) {
      setProductKey(newProductKey);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (productKey) {
      fetchRest({
        url: `get-product-detail/${productKey}`,
        method: "GET",
        isFullPageLoader: true,
      })
        .then((res) => {
          setDetails(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [productKey]);



  return (
    <>
      {details ? (
        <section className="lg:px-[30px] md:px-[30px] py-[0] px-[15px] ">
          <div className="max-w-screen-xl mx-[auto] my-[0] ">
            <div className="md:pt-[60px] ">
              <div className="w-full lg:flex  md:w-full md:flex-col lg:justify-between lg:flex-row  md:flex  md:justify-between gap-[30px]">
                <div className="w-full md:w-1/2 ">
                  <DetailSectionImages prdImages={details.imgs} />
                </div>

                <h1 className="  lg:hidden md:hidden  inline-block  text-[24px]  leading-[1.9rem] font-[900]   text-left text-[#242631] lg:mt-4 pt-4">
                  {details.heading}
                </h1>

                <div className="  lg:hidden md:hidden flex items-center gap-1  pt-3 ">
                  <div className="rounded-full flex items-center justify-center p-4 w-[26px] h-[26px] bg-[#aed7b7d6] relative">
                    <img
                      className=" w-[22px] h-auto absolute "
                      src={trendingImg}
                      alt=""
                    />
                  </div>
                  <p className=" flex gap-2 lg:text-[20px] text-[14px]   text-gray-700  leading-[19px]  text-left  ">
                    <strong className="font-extrabold" >
                      {" "}
                      {dummyTestDetails.noOfOrdersInLastNthDays}
                    </strong>
                    People ordered item in the last{" "}
                    {dummyTestDetails.lastNthDays} days
                  </p>
                </div>

                {/* mobile pricing */}


                <div className="lg:hidden md:hidden   lg:mt-4 mt-4 pt-0">
                  {details?.offerPriceInVal ? (
                    <>
                      <div className="flex align-center items-baseline gap-3">
                        <div className="   font-bold leading-[32px] text-left">
                          <p className="lg:hidden md:hidden  text-[28px] leading-[26px]   flex align-baseline justify-center items-center self-center font-extrabold">
                            {" "}
                            <span className="flex justify-start items-start h-[20px]">
                              <MdOutlineCurrencyRupee
                                fontSize={16}
                                fontWeight={700}
                              />
                            </span>
                            {details.offerPriceInVal}
                          </p>
                        </div>

                        <div className="lg:text-[24px] text-[16px] flex items-end  font-light  text-left text-[#687588] [text-decoration-line:line-through]">
                          &#8377;{details.price}
                        </div>
                        {details?.offerPriceInPercentage ? (
                          <div className=" text-[#03977e] rounded-[16px] text-[14px] font-semibold leading-[20px] ">
                            {details?.offerPriceInPercentage * 100}% Off
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-[18px] font-bold leading-[24px] text-left">
                        &#8377;{details.price}
                      </div>
                    </>
                  )}
                </div>

                {/* mobile pricing */}

                {/*mobile product rating */}
                <div className="  lg:hidden md:hidden flex gap-2 lg:pt-3 mt-4 align-center items-center lg:flex-wrap ">
                  {/* <StarRating rating={details.rating} /> */}
                  <div className=" flex items-start font-light  justify-center gap-1 rounded-[5px] pl-1    mt-[2px] lg:text-[16px] text-[13px]  leading-[18px] text-left text-[#242631] ">
                    <img
                      className="lg:w-[20px]  w-[15px] align-middle  h-auto"
                      src={filledStar}
                      alt=""
                    />
                    {details.rating}
                  </div>
                  <div className="mt-[2px] border-l-[1px] pl-2 flex gap-3 align-center items-center text-[13px] lg:text-[16px] font-semibold leading-[18px] text-left text-[#757884] lg:text-wrap text-nowrap">
                    {/* {details.totalRatings} Ratings */}
                    {details.totalReviews}
                  </div>
                </div>
                {/* product rating */}

                <div className="w-full md:w-1/2  "  >
                  <div className="lg:flex md:flex hidden gap-1 items-center lg:items-baseline ">
                    <div className="w-[26px] h-[16px]   relative flex justify-center items-center">
                      <img
                        className="lg:w-[24px] w-[17px] h-auto absolute top-0 "
                        src={trendingImg}
                        alt=""
                      />
                    </div>
                    <p className="lg:text-[20px] text-[13px] text-nowrap font-semibold  text-center">
                      {dummyTestDetails.noOfOrdersInLastNthDays} People ordered
                      this item in the last {dummyTestDetails.lastNthDays} days
                    </p>
                  </div>

                  <div className=" block lg:hidden md:hidden  lg:mt-3 pt-5 ">
                    <OfferEndsInTimer
                      timer={new Date(details.offerValidTill).getTime()}
                    />
                  </div>

                 

                  <h1 className="lg:inline-block hidden  text-[28px]  leading-[28px] font-[700] lg:leading-[38px] text-left text-[#242631] lg:mt-4 mt-1">
                    {details.heading}
                  </h1>


                  <h3 className=" lg:block md:block hidden text-[16px] font-[400] leading-[20px] text-left text-[#595b63] mt-4">
                    {details.description}
                  </h3>
                  <div className="  lg:flex md:flex hidden gap-2 lg:pt-3 pt-7 align-center items-center lg:flex-wrap ">
                    {/* <StarRating rating={details.rating} /> */}
                    <div className="flex flex-row gap-2    mt-[2px] lg:text-[16px] text-[13px] font-bold leading-[18px] text-left text-[#242631]  pr-[8px]">
                      <img
                        className="w-[15px]  h-auto"
                        src={filledStar}
                        alt=""
                      />

                      {details.rating}
                    </div>
                    <div className="  border-l-[1px] pl-2 mt-[2px] flex gap-3 align-center items-center text-[13px] lg:text-[16px] font-semibold leading-[18px] text-left text-[#757884] lg:text-wrap text-nowrap">
                      {/* {details.totalRatings} Ratings */}
                      {details.totalReviews}
                    </div>
                  </div>
                  <div className="hidden    bg-[#f0ffff] py-[25px] rounded-[12px] w-full lg:grid lg:grid-cols-2 gap-4 lg:gap-[30px] mt-5">
                    <div className="flex gap-2 items-center">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={freeDelivery}
                        alt=""
                      />
                      <h3 className="lg:text-[16px] text-[11px] font-semibold lg:leading-[20px] leading-[13px] lg:text-nowrap text-wrap">
                        All India Free Delivery
                      </h3>
                    </div>
                    <div className="flex gap-2 items-center justify-start">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={dispatchTime}
                        alt=""
                      />
                      <h3 className="lg:text-[16px] text-[11px] font-semibold lg:leading-[20px] leading-[13px]  lg:text-nowrap text-wrap">
                        Dispatch Within 48 Hours
                      </h3>
                    </div>
                    <div className="flex gap-2  items-center">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={waterProof}
                        alt=""
                      />
                      <h3 className="lg:text-[16px] text-[11px] font-semibold  lg:leading-[20px] leading-[13px] lg:text-nowrap text-wrap">
                        Water Proof
                      </h3>
                    </div>
                    <div className="flex gap-2 items-center justify-start">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={Tear_Free}
                        alt=""
                      />
                      <h3 className="lg:text-[16px] text-[11px]  font-semibold lg:leading-[20px] leading-[13px] lg:text-nowrap text-wrap">
                        Tear Free
                      </h3>
                    </div>
                  </div>
                  <div className="lg:block md:block hidden   mt-4">
                    {details?.offerPriceInVal ? (
                      <>
                        <div className="flex align-center items-center gap-2">
                          <div className=" flex   lg:text-[32px] text-[28px]   font-bold leading-[32px] text-left">
                            <span className="flex pt-1 justify-start items-start h-[20px]">
                              <MdOutlineCurrencyRupee
                                fontSize={16}
                                fontWeight={700}
                             
                              
                              />
                            </span>
                            <strong>{details.offerPriceInVal}</strong>
                          </div>
                          <div className="lg:text-[22px] text-[18px]  font-medium leading-[32px] text-left text-[#687588] [text-decoration-line:line-through]">
                            &#8377;{details.price}
                          </div>
                          {details?.offerPriceInPercentage ? (
                            <div className="  text-[#067647] rounded-[16px] lg:text-[18px] text-[14px] font-extrabold leading-[20px] text-center ">
                              {details?.offerPriceInPercentage * 100}% Off
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-[18px] font-bold leading-[24px] text-left">
                          &#8377;{details.price}
                        </div>
                      </>
                    )}
                    <div className=" lg:block md:block hidden  lg:mt-3 pt-5 ">
                      <OfferEndsInTimer
                        timer={new Date(details.offerValidTill).getTime()}
                      />
                    </div>
                  </div>
                  <div className="pt-5  ">
                    <ImageTemplateHandler
                      templateInfo={details?.template}
                      selectedTemplate={selectedTemplate}
                      setSelectedTemplate={setSelectedTemplate}
                      totalSelectionCount={details?.template?.templateName?.includes('bag_tag') ? 2 : 1}
                    />
                  </div>
                  <div className="bg-[#f0ffff] py-[15px]  rounded-[12px] w-full border border-[#F3F0FB] p-4 lg:hidden  grid grid-cols-2 justify-start gap-3 mt-4 mb-4  t">
                    <div className="flex gap-2 items-center">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={freeDelivery}
                        alt=""
                      />
                      <h3 className="w-[100px]    lg:text-[16px] text-[13px] font-semibold lg:leading-[20px] leading-[18px] lg:text-nowrap text-wrap">
                        All India Free Delivery
                      </h3>
                    </div>
                    <div className="flex gap-2 items-center lg:justify-end">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={dispatchTime}
                        alt=""
                      />
                      <h3 className="  w-[100px]  lg:text-[16px] text-[13px] font-semibold lg:leading-[20px] leading-[18px]  lg:text-nowrap text-wrap">
                        Dispatch Within 48 Hours
                      </h3>
                    </div>
                    <div className="flex gap-2 items-center">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={waterProof}
                        alt=""
                      />
                      <h3 className="lg:text-[16px] text-[13px] font-semibold  lg:leading-[20px] leading-[13px] lg:text-nowrap text-wrap">
                        Water Proof
                      </h3>
                    </div>
                    <div className="flex gap-2 items-center lg:justify-end">
                      <img
                        className="lg:w-[48px] w-[35px] lg:h-[48px] h-[30px]"
                        src={Tear_Free}
                        alt=""
                      />
                      <h3 className="lg:text-[16px] text-[13px] font-semibold lg:leading-[20px] leading-[13px] lg:text-nowrap text-wrap">
                        Tear Free
                      </h3>
                    </div>
                  </div>
                  <div className="hidden lg:flex lg:items-center   md:block mt-0">
                    <button
                      onClick={() => setModalState(true)}
                      className="bg-[#03977E] px-[32px] py-[18px] rounded-[20px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-full text-center mt-5"
                    >
                      CREATE YOUR OWN
                    </button>
                  </div>

                  <div className="delivery-info flex flex-col gap-6 mt-5">
                    

                    <div className="flex flex-col items-start   gap-5 lg:mt-5 ">
                      <h2 className="lg:text-[20px] text-[14px]  flex gap-2   leading-[18px] text-left text-[#242631]">
                        <strong className="text-[24px]">
                          {dummyTestDetails.customerCount}
                        </strong>
                        Happy Kids
                      </h2>

                      <div
                        className={`lg:flex  md:flex lg:justify-start hidden align-center items-center md:gap-0 gap-3 flex-nowrap w-full`}
                      >
                        <Swiper
                          slidesPerView={6}
                          spaceBetween={3}
                          loop={true}
                          speed={600}
                          autoplay={{
                            delay: 1000,
                          }}
                          modules={[Autoplay]}
                        >
                          {dummyTestDetails?.reviewImgs?.map(
                            (img: any, index: any) => {
                              if (index < 10) {
                                return (
                                  <>
                                    <SwiperSlide key={index}>
                                      <img
                                        className="cursor-pointer rounded-[12px] w-[100px] h-[100px]"
                                    src={process.env.REACT_APP_IMG_CDN_URL + img}
                                        alt=""
                                      />
                                    </SwiperSlide>
                                  </>
                                );
                              }
                              return <></>;
                            }
                          )}
                        </Swiper>
                      </div>

                  

                      <div
                        className={`lg:hidden md:hidden flex align-center items-center mt-3 gap-3 flex-wrap w-full `}
                      >
                        <Swiper
                          slidesPerView={3}
                          spaceBetween={2}
                          loop={true}
                          speed={600}
                          autoplay={{
                            delay: 1000,
                          }}
                          modules={[Autoplay]}
                        >
                          {dummyTestDetails?.reviewImgs?.map(
                            (img: any, index: any) => {
                              if (index < 19) {
                                return (
                                  <>
                                    <SwiperSlide key={index}>
                                      <img
                                        className="cursor-pointer rounded-[12px] w-[100px] h-[100px]"
                                        src={process.env.REACT_APP_IMG_CDN_URL  + img}
                                        alt=""
                                      />
                                    </SwiperSlide>
                                  </>
                                );
                              }
                              return <></>;
                            }
                          )}
                        </Swiper>
                      </div>

                      <div className="lg:hidden  flex flex-col pt-3 ">
                        <p>Description</p>
                        <h3 className=" lg:hidden md:hidden block text-[16px] font-normal leading-[19.2px] text-left text-[#6b6c72] mt-4">
                          {details.description}
                        </h3>
                      </div>

                      <div className=" w-full lg:hidden md:hidden flex justify-center ">
                        <div className="w-full  bg-[#d8f1e7] fixed bottom-0 left-0 z-[80] h-[80px]"></div>
                        <button
                          onClick={() => setModalState(true)}
                          className="bg-[#03977E] px-[80px] py-[12px] rounded-[10px] text-[14px] text-nowrap font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center mt-[36px]  fixed bottom-4  z-[100]"
                        >
                          CREATE YOUR OWN
                        </button>
                      </div>
                      {/* <p className="text-[16px] font-normal leading-[20px] text-left text-[#242631]">
                        {dummyTestDetails.promotionText}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {details ? (
        <div>
          <ProductWrapper
            productKey={"recommended"}
            heading={"Recommended products"}
            categoryId={details.categoryId}
          />
        </div>
      ) : (
        <></>
      )}

      <ModalViewForDetail
        prdDetails={details}
        isModalOpen={isModalOpen}
        setModalState={setModalState}
        isPhotoUploadMode={true}
        selectedTemplateFromDetails={selectedTemplate}
      />
    </>
  );
};

const ImageTemplateHandler: React.FC<{
  templateInfo?: any;
  selectedTemplate?: any;
  setSelectedTemplate?: any;
  totalSelectionCount?: number
}> = ({ templateInfo, selectedTemplate, setSelectedTemplate, totalSelectionCount = 1 }) => {
  const [selectedKey, setSelectedKey] = useState<any>();
  const [templateTypes, setTemplateTypes] = useState<any>();
  const [selectedTemplates, setSelectedTemplates] = useState<any>([]);
  const [selectedObj, setSelectedObj] = useState<any>({});

  useEffect(()=>{
    if (selectedTemplate) {
      setSelectedTemplates(selectedTemplate || []);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    let keys: any = {};
    const types = templateInfo?.template?.template_details;
    if (types?.length) {
      for (let i = 0; i < types?.length; i++) {
        if (!keys[types[i]?.key]) {
          keys[types[i]?.key] = [types[i]];
        } else {
          keys[types[i]?.key].push(types[i]);
        }
      }
      const mainKey = "All";
      let obj: any = {};
      if (Object.keys(keys)?.length > 1) {
        keys[mainKey] = types;
        setSelectedKey(mainKey);
        if (selectedTemplate?.length) {
          setSelectedTemplate([...selectedTemplate]);
          for (let i = 0; i < selectedTemplate.length; i++) {
            obj[selectedTemplate[i]?.id] = true;
          }
        } else {
          const arr = [];
          arr.push(keys[mainKey][0]);
          setSelectedTemplate(arr);
          setSelectedTemplates(arr);
          obj[keys[mainKey][0]?.id] = true;
        }
      } else {
        setSelectedKey(types[0]?.key);
        setSelectedTemplate([types[0]]);
        setSelectedTemplates([types[0]]);
        obj[types[0]?.id] = true;
      }
      setSelectedObj(obj);
      setTemplateTypes(keys);
    }
  }, [templateInfo]);

  const onTemplateKeyChange = (key: string) => {
    const tempArr = []
    tempArr?.push(templateTypes[key][0]);
    setSelectedKey(key);
    setSelectedTemplate(tempArr);
    setSelectedTemplates(tempArr);
    setSelectedObj({[tempArr[0]?.id]: true});
  };

  const handleImageSelection = (item: any) => {
    const tempArr = selectedTemplates;
    let obj = {...selectedObj};
    if (tempArr?.length === totalSelectionCount) {
      const elemId = tempArr.shift();
      delete obj[elemId?.id];
    }
    tempArr.push(item);
    obj[item.id] = true;
    setSelectedTemplate(tempArr);
    setSelectedTemplates(tempArr);
    setSelectedObj(obj);
  }

  const cleanName = (fullPath: string): string => {
    const COMMON_WORDS = [
      "name", "slip", "theme", "template", 
      "bag", "tag", "boys", "boy", "girls", "girl", 
      "with", "without", "img", "thumbnail"
    ];
  
    const normalize = (str: string) =>
      str.replace(/^.*\//, "")        // Remove path
         .replace(/\.[^/.]+$/, "")    // Remove extension
         .replace(/[_-]/g, " ")       // Replace _ and - with space
         .toLowerCase()
         .trim();
  
    const cleaned = normalize(fullPath)
      .split(" ")
      .filter(word => !COMMON_WORDS.includes(word))
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  
    return cleaned;
  };
  
  
  

  return (
    <>
      {templateTypes && Object.keys(templateTypes)?.length ? (
        <div>
          <div className="flex lg:justify-start md:justify-start pl-4 lg:gap-5 md:gap-5 gap-11 lg:mt-[10px] md:mt-[10px]  ">
            {Object.keys(templateTypes)
              .filter((key) => key !== "Default") // Replace "keyToExclude" with the key you want to exclude
              .reverse()
              .map((key) => (
           
                <div
                  className={`cursor-pointer  lg:pl-5  lg:pr-10   pb-2 lg:text-[18px] text-[12px] font-semibold leading-[18px] text-left text-[#757884] ${
                    selectedKey === key ? "font-extrabold text-black " : ""
                  } `}
                  onClick={() => onTemplateKeyChange(key)}
                  key={key}
                >
                  {key}
                </div>
              ))}
          </div>

          <div className=" lg:max-w-[full]     max-w-full flex  overflow-x-auto ">
            {selectedKey ? (
              <div className=" flex justify-start lg:gap-4 gap-5 md:gap-3 mt-2 ">
                {templateTypes[selectedKey]?.map((item: any) => (
                  <Popup
                    trigger={(open) => (
                      <div  className="w-full flex flex-col justify-center  items-center ">

<div
                        onClick={() => handleImageSelection(item)}
                        key={item?.id}
                        className="lg:w-[100px] lg:h-[80px] w-[60px] h-[60px]  object-scale-down"
                      >
                        <img
                          className={`cursor-pointer  ${
                            selectedObj[item?.id]
                              ? " p-[4px] border-[4px] border-[solid] border-[#36956f] rounded-[10px] object-scale-down"
                              : "object-scale-down"
                          }`}
                          decoding="async"
                          fetchPriority="high"
                          loading="eager"
                          src={
                            process.env.REACT_APP_IMG_CDN_URL + item.default_img
                          }
                          alt=""
                        />
                      </div>
                      <h1 className="  font-bold text-[#17503a] text-center text-[15px] text-wrap">
                      {cleanName(item.default_img)}
    
                       </h1>
                      </div>
                    )}
                    position={["top center", "bottom center", "bottom right"]}
                    on={["hover", "focus"]}
                  >
                    <div className=" w-[170px] h-[170px] lg:w-[600px] lg:h-[300px] flex flex-col items-center justify-center ">
           <img
                        className=" object-scale-down"
                        decoding="async"
                        fetchPriority="high"
                        loading="eager"
                        src={
                          process.env.REACT_APP_IMG_CDN_URL + item.default_img
                        }
                        alt=""
                      />
                            <h1 className="  font-bold text-[#17503a] text-center text-[20px] text-wrap">
                            {cleanName(item.default_img)}
                       </h1>
                    </div>
                  </Popup>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const ModalViewForDetail: React.FC<{
  prdDetails: any;
  isModalOpen: boolean;
  setModalState: any;
  isPhotoUploadMode: boolean;
  selectedTemplateFromDetails?: any;
}> = ({ prdDetails, isModalOpen, setModalState, isPhotoUploadMode, selectedTemplateFromDetails }) => {
  const [modalHeading, setModalHeading] = useState("Photo tips");
  const [showForm, setShowForm] = useState(isPhotoUploadMode ? false : true);

  return (
    <>
      <Modal
        heading={modalHeading}
        isOpen={isModalOpen}
        onClose={() => setModalState(!isModalOpen)}
      >
        <section className="m-[12px] max-h-auto mt-0 lg:p-[24px] rounded-[20px] border-[1px] border-solid border-[#EAECF0]  ">
          {showForm ? (
            <>
              <AddDetailsFormHandlerTemplate
                prdDetails={prdDetails}
                setModalState={setModalState}
                selectedTemplateFromDetails={selectedTemplateFromDetails}
              />
            </>
          ) : (
            <>
              <div>
                <img
                  className="rounded-[10px] w-full max-w-[430px] h-auto p-10"
                  src={previewImg}
                  alt=""
                />
                <button
                  onClick={() => {
                    setShowForm(true);
                    setModalHeading("Enter Child Details");
                  }}
                  className="bg-[#03977E] px-[32px]  py-[18px] rounded-[58px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-full text-center mt-[36px]"
                >
                  Upload photo
                </button>
              </div>
            </>
          )}
        </section>
      </Modal>
    </>
  );
};

const AddDetailsFormHandlerTemplate: React.FC<{
  prdDetails: any;
  setModalState: any;
  selectedTemplateFromDetails?: any;
}> = ({ prdDetails, setModalState, selectedTemplateFromDetails }) => {
  const formMapper: any = {
    bag_tag_without_photo: ["first_name", "last_name", "address", "contact_no"],
    bag_tag_with_one_photo: [
      "first_name",
      "last_name",
      "image1",
      "address",
      "contact_no",
    ],
    name_slip_with_one_image: [
      "first_name",
      "last_name",
      "school",
      "std",
      "rollNo",
      "image1",
    ],
    name_slip_with_two_image: [
      "first_name",
      "last_name",
      "school",
      "std",
      "rollNo",
      "image1",
      "image2",
    ],
  };
  const { localStorageData, updateLocalStorage } = useLocalStorage();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<any>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>(
    selectedTemplateFromDetails
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchRest } = useFetch({});
  const [imageSrcVal, setImageSrcVal] = useState<any>({});
  const [imageFileData, setImageFileData] = useState<any>({});
  const [imageSrcLoader, setImageSrcLoader] = useState<any>({});
  const [imageSrcErr, setImageSrcErr] = useState<any>({});

  useEffect(() => {
    if (prdDetails) {
      const templateKey = prdDetails?.template?.templateName;
      if (formMapper[templateKey]) {
        let formObj: any = {};
        const keys = formMapper[templateKey];
        let imgSrc: any = {};
        let imgLoader: any = {};
        let imgErr: any = {};
        for (let i = 0; i < keys.length; i++) {
          formObj[keys[i]] = "";
          if (keys[i].includes('image')) {
            imgSrc[keys[i]] = '';
            imgLoader[keys[i]] = false;
            imgErr[keys[i]] = false
          }
        }
        setImageSrcVal({ ...imgSrc });
        // setImageSrcLoader({ ...imgLoader });
        setImageSrcErr({ ...imgErr });
        if (Object.keys(formObj)?.length) {
          setFormData(formObj);
        }
      }
    }
  }, [prdDetails]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleFileChange = (e: any, imageField: any) => {
    if (e?.target?.files?.[0]) {
      const imageName = formData[imageField] || '' + new Date().getTime();
      setImageFileData({
        ...imageFileData,
        [imageField]: e?.target?.files?.[0]
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          setImageSrcVal({
            ...imageSrcVal,
            [imageField]: reader.result
          });
        }
      };
      reader.readAsDataURL(e?.target?.files?.[0]);
      setFormData({ ...formData, [imageField]: imageName });
      if (errors[imageField]) {
        setErrors((prev) => ({ ...prev, [imageField]: "" }));
      }
    }
  };

  const validateField = (key: string) => {
    if (key === "first_name") {
      const fullname = formData?.first_name + formData.last_name;
      if (!fullname) return "First name is required.";
      if (fullname.length > 20)
        return "Name should be less than 20 characters.";
    }
    if (key === "image1" && !formData[key]) return "Image 1 is required.";
    if (key === "image2" && !formData[key]) return "Image 2 is required.";
    return undefined;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newErrors: Record<string, string> = {};
    for (var key of Object.keys(formData)) {
      const error = validateField(key);
      if (error) newErrors[key] = error;
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let imagesUploadSuccess = true;
      try {
        if (formData?.hasOwnProperty('image1') && imageFileData['image1']) {
          await uploadImagesData('image1');
        }
        if (formData?.hasOwnProperty('image2') && imageFileData['image2']) {
          await uploadImagesData('image2');
        }
      } catch (e) {
        imagesUploadSuccess = false;
      }

      if (imagesUploadSuccess) {
        const uuid = uuidv4();
        const orders = localStorage.getItem("wow_kids_orders");
        const ordersArr = JSON.parse(orders as string) || [];
        ordersArr.push(uuid);

        updateLocalStorage(
          uuid,
          JSON.stringify({
            ...formData,
            prdId: prdDetails.id,
            templateIds: selectedTemplate?.map((template: any) => {
              return template?.id
            }),
            count: 1,
          })
        );
        updateLocalStorage("wow_kids_orders", JSON.stringify(ordersArr));
        setModalState(false);
        ordersArr && navigate("/cart?state=info")
        window.scrollTo(0, 0)
      }
    }
  };

  const uploadImagesData = async (
    imageField: string
  ) => {
    return new Promise((resolve, reject) => {
      setImageSrcErr({
        ...imageSrcErr,
        [imageField]: false
      });
      setImageSrcVal({
        ...imageSrcVal,
        [imageField]: null,
      });
      setImageSrcLoader({
        ...imageSrcLoader,
        [imageField]: true,
      });
      const imageName = formData[imageField];
      fetchRest({
        url: `get-image-upload-url`,
        method: "POST",
        data: {
          fileName: imageName,
        },
        isFullPageLoader: true,
      })
        .then((res) => {
          const signedURL = res.data?.uploadUrl;
          if (signedURL) {
            axios
              .put(signedURL, imageFileData[imageField], {
                headers: { "Content-Type": "image/*" },
              })
              .then((res) => {
                setImageSrcErr({
                  ...imageSrcErr,
                  [imageField]: false,
                });
                resolve("Success");
              })
              .catch((err) => {
                setImageSrcErr({
                  ...imageSrcErr,
                  [imageField]: true,
                });
                setImageSrcLoader({
                  ...imageSrcLoader,
                  [imageField]: false,
                });
                reject("Error");
              });
          }
        })
        .catch((err) => {
          setImageSrcErr({
            ...imageSrcErr,
            [imageField]: true,
          });
          setImageSrcLoader({
            ...imageSrcLoader,
            [imageField]: false,
          });
          reject("Error");
        });
    });
  };

  return (
    <>
      <div className="pt-3">
        <ImageTemplateHandler
          templateInfo={prdDetails?.template}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
            totalSelectionCount={prdDetails?.template?.templateName.includes('bag_tag') ? 2 : 1}
        />
      </div>
      <hr />

      <form
        className="lg:w-[90vw] lg:h-[100%]  h-[500px]   max-w-[820px] md:flex md:flex-row md:gap-5 lg:flex lg:flex-row  lg:gap-5 flex flex-col items-center justify-evenly overflow-y-auto   "
        onSubmit={handleSubmit}
        style={{ display: "flex", gap: "20px" }}
      >
        <div className="justify-between  lg:flex lg:flex-col flex flex-col  gap-[20px] ">
          {formData && formData.hasOwnProperty("image1") ? (
            <label className="lg:block   flex-shrink-0 lg:h-[200px]  lg:w-[300px] self-center h-[230px]   w-[270px] ">
              {errors["image1"] && (
                <span className="error-message">{errors["image1"]}</span>
              )}
              <div className="lg:h-[100%] lg:w-full w-full h-full lg:px-0 lg:py-0 px-[24px] py-[16px] rounded-[12px] bg-[#f9f9f9] border-[2px] border-dashed border-[#D6D8DC] cursor-pointer text-center">
                <input
                  type="file"
                  accept=".png, .jpg"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileChange(e, "image1")}
                />
                <div className="flex items-center justify-center flex-col lg:h-full h-1/2 ">
                  {imageSrcLoader["image1"] ? (
                    <div className="w-100 h-100 flex justify-center items-center">
                      <CircularProgress color="inherit" />
                    </div>
                  ) : (
                    <></>
                  )}
                  {!formData["image1"] ? (
                    <>
                      <img
                        className="w-full lg:h-[40px] h-[50px] mb-[10px]"
                        src={uploadImg}
                        alt=""
                      />
                      <div className="lg:flex lg:items-center flex  flex-col items-center justify-center">
                        <p className="lg:text-[14px] md:text-[14px] text-[12px] font-semibold lg:leading-[20px] md:leading-[20px] text-left text-[#242631]">
                          Click to upload
                        </p>
                        &nbsp;
                        <small className="lg:text-[14px] text-nowrap md:text-[14px] text-[12px] font-normal md:leading-[20px] lg:leading-[20px] text-left text-[#475467]">
                          or drag and drop PNG, JPG (max. 12 MB)
                        </small>
                      </div>
                    </>
                  ) : (
                    <div className="relative w-auto h-full">
                      <img
                        className="absolute w-[40px] h-[40px] top-[20px] right-[20px]"
                        src={uploadImg}
                        alt=""
                      />
                      {imageSrcErr["image1"] ? (
                        <div className="w-100 h-100 flex items-center error-message">
                          Failed to upload !, please try again after sometime
                        </div>
                      ) : (
                        <></>
                      )}
                      {imageSrcLoader && imageSrcVal["image1"] ? (
                        <img
                          className="hover-img-bg lg:w-[100%]  lg:h-[100%] w-[220px] h-[190px] object-scale-down  "
                          src={imageSrcVal["image1"]}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </label>
          ) : (
            <></>
          )}

          {formData && formData.hasOwnProperty("image2") ? (
            location.pathname ===
              "/personalised-name-slip/name-label-with-two-Photo" && (
              <label className="lg:block   flex-shrink-0 lg:h-[200px]  lg:w-[300px] self-center h-[230px]   w-[270px] ">
                {errors["image2"] && (
                  <span className="error-message">{errors["image2"]}</span>
                )}
                <div className="lg:h-[100%] lg:w-full w-full h-full lg:px-0 lg:py-0 px-[24px] py-[16px] rounded-[12px] bg-[#f9f9f9] border-[2px] border-dashed border-[#D6D8DC] cursor-pointer text-center">
                  <input
                    type="file"
                    accept=".png, .jpg"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "image2")}
                  />
                  <div className="flex items-center justify-center flex-col lg:h-full h-1/2">
                    {imageSrcLoader["image2"] ? (
                      <div className="w-100 h-100 flex justify-center items-center">
                        <CircularProgress color="inherit" />
                      </div>
                    ) : (
                      <></>
                    )}
                    {!formData["image2"] ? (
                      <>
                        <img
                          className="w-[40px] lg:h-[40px] h-[50px] mb-[10px]"
                          src={uploadImg}
                          alt=""
                        />
                        <div className=" w-full lg:flex lg:items-center flex  flex-col items-center justify-center ">
                          <p className="lg:text-[14px] md:text-[14px] text-[12px] font-semibold leading-[20px] text-left [text-decoration-line:underline] [text-decoration-style:solid] text-[#242631]">
                            Click to upload
                          </p>
                          &nbsp;
                          <small className="lg:text-[14px] lg:text-nowrap text-nowrap md:text-[14px] text-[12px] font-normal leading-[20px] text-left text-[#475467]">
                            or drag and drop PNG, JPG (max. 12 MB)
                          </small>
                        </div>
                      </>
                    ) : (
                      <div className="relative w-auto h-full">
                        <img
                          className="absolute w-[40px] lg:h-[40px]   lg:top-[10px]   lg:right-[18px]   -top-5 left-3  "
                          src={uploadImg}
                          alt=""
                        />
                        {imageSrcErr["image2"] ? (
                          <div className="w-100 h-100 flex items-center error-message">
                            Failed to upload !, please try again after sometime
                          </div>
                        ) : (
                          <></>
                        )}
                        {imageSrcLoader && imageSrcVal["image2"] ? (
                          <img
                            className="hover-img-bg lg:w-[100%]  lg:h-[100%] w-[220px] h-[190px] object-scale-down  object-center"
                            src={imageSrcVal["image2"]}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </label>
            )
          ) : (
            <></>
          )}
        </div>

        <div className="w-full   flex-1 lg:ml-0 ml-5 items-start">
          {formData && formData.hasOwnProperty("first_name") ? (
            <div className="lg:mb-[15px] md:mb-[15px] mb-3 ">
              <label className="lg:block md:block hidden mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                First name{" "}
                {errors["first_name"] && (
                  <span className="error-message">
                    ({errors["first_name"]})
                  </span>
                )}
              </label>
              <label className="lg:hidden md:hidden block mb-[8px] mt-4 text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                {errors["first_name"] && (
                  <span className="error-message">
                    ({errors["first_name"]})
                  </span>
                )}
              </label>
              <input
                className="box-border self-start h-[50px] w-[90%] p-[10px] lg:mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                type="text"
                name="first_name"
                placeholder="Enter first name"
                value={formData.first_name}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}
          {formData && formData.hasOwnProperty("last_name") ? (
            <div className="lg:mb-[15px] md:mb-[15px] mb-3 ">
              <label className="lg:block md:block hidden mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                Last name{" "}
                {/* {errors["last_name"] && (
                  <span className="error-message">({errors["last_name"]})</span>
                )} */}
              </label>
              <label className="lg:hidden md:hidden block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                {errors["last_name"] && (
                  <span className="error-message">({errors["last_name"]})</span>
                )}
              </label>
              <input
                className="box-border h-[50px] w-[90%] p-[10px] lg:mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                type="text"
                name="last_name"
                placeholder="Enter last name"
                value={formData.last_name}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}
          {formData && formData.hasOwnProperty("school") ? (
            <div className="lg:mb-[15px] md:mb-[15px] mb-3 ">
              <label className="lg:block md:block hidden mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                School{" "}
                {errors["school"] && (
                  <span className="error-message">({errors["school"]})</span>
                )}
              </label>
              <label className="lg:hidden md:hidden block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                {errors["school"] && (
                  <span className="error-message">({errors["school"]})</span>
                )}
              </label>
              <input
                className="box-border h-[50px]  w-[90%] p-[10px]  lg:mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                type="text"
                name="school"
                placeholder="Enter school name"
                value={formData.school}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}

          {/* contact number number field */}
          {formData && formData.hasOwnProperty("contact_no") ? (
            <div className="lg:mb-[15px] md:mb-[15px] mb-3 ">
              <label className="lg:block md:block hidden mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                Contact Number&nbsp;
                {errors["contact_no"] && (
                  <span className="error-message">
                    ({errors["contact_no"]})
                  </span>
                )}
              </label>
              <label className="lg:hidden md:hidden block mb-[8px] mt-4 text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                {errors["contact_no"] && (
                  <span className="error-message">
                    ({errors["contact_no"]})
                  </span>
                )}
              </label>
              <input
                className="box-border h-[50px] w-[90%] p-[10px] lg:mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                type="tel"
                name="contact_no"
                placeholder="Enter contact number"
                value={formData.contact_no}
                maxLength={10}
                onChange={handleChange}
              />
            </div>
          ) : (
            <></>
          )}

          {/* Address info text area */}
          {formData && formData.hasOwnProperty("address") ? (
            <div className="lg:mb-[15px] md:mb-[15px] mb-3 ">
              <label className="lg:block md:block hidden mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                Address Info&nbsp;
                {errors["address"] && (
                  <span className="error-message">({errors["address"]})</span>
                )}
              </label>
              <label className="lg:hidden md:hidden block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                {errors["address"] && (
                  <span className="error-message">({errors["address"]})</span>
                )}
              </label>
              <textarea
                rows={10}
                className="lg:h-[120px] resize-none m-0 box-border h-[50px]  w-[90%] p-[10px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                name="address"
                id="address-text-area"
                placeholder="Add address info"
                value={formData.address}
                onChange={handleChange}
              ></textarea>
            </div>
          ) : (
            <></>
          )}

          <div className="flex lg:flex-row  lg:gap-[10px] gap-3 mb-[15px] lg:flex-nowrap flex-wrap w-full ">
            {formData && formData.hasOwnProperty("std") ? (
              <div className="w-full ">
                <label className="lg:block md:block hidden mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                  Std.{" "}
                  {errors["std"] && (
                    <span className="error-message">({errors["std"]})</span>
                  )}
                </label>
                <input
                  className="box-border h-[50px] w-[90%] p-[10px] lg:mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                  type="text"
                  name="std"
                  placeholder="Enter std number"
                  value={formData.std}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )}
            {formData && formData.hasOwnProperty("rollNo") ? (
              <div className="w-full">
                <label className="lg:block md:block hidden mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                  Roll no.{" "}
                  {errors["rollNo"] && (
                    <span className="error-message">({errors["rollNo"]})</span>
                  )}
                </label>
                <input
                  className="box-border h-[50px]  w-[90%] p-[10px] lg:mb-[20px] mb-[50px]  border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                  type="text"
                  name="rollNo"
                  placeholder="Enter roll number"
                  value={formData.rollNo}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="lg:block md:block hidden">
            <button
              type="submit"
              className="bg-[#03977E] px-[10px] py-[18px] rounded-[58px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center"
            >
              Add to cart
            </button>
          </div>
          <div className=" lg:hidden md:hidden relative w-full h-auto  flex justify-center z-80">
            <div className="bg-white w-full h-[90px] fixed bottom-0 left-0 z-[80]"></div>
            <button
              type="submit"
              className="bg-[#03977E] px-[10px] py-[18px] rounded-[12px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center fixed bottom-3  left-5  z-[90] "
            >
              Add to cart
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

const DetailSectionImages: React.FC<{
  prdImages: Array<string>;
}> = ({ prdImages }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(
    prdImages?.length ? 0 : -1
  );

  function selectImage(index: number) {
    setSelectedImageIndex(index);
  }

  useEffect(() => {}, []);

  return (
    <>
      {prdImages?.length ? (
        <section className="md:block lg:block hidden ">
          <div className="selectedImages min-w-[600px] h-[750px]   overflow-hidden rounded-[28px]">
            <img
              className="object-cover"
              width="720"
              height="720"
              decoding="async"
              fetchPriority="high"
              loading="eager"
              src={
                process.env.REACT_APP_IMG_CDN_URL +
                prdImages[selectedImageIndex]
              }
              alt=""
            />
          </div>

          <div className="max-w-[700px] overflow-x-auto flex align-center items-end gap-2 sm:gap-3 mt-6 mb-6 ">
            {prdImages?.map((img, currIndex) => {
              return (
                <>
                  <div
                    onClick={() => setSelectedImageIndex(currIndex)}
                    className={`cursor-pointer ${
                      selectedImageIndex === currIndex
                        ? "p-[4px] border-[4px] border-[solid] border-[#D8F1E7] rounded-[18px] opacity-100"
                        : "opacity-50"
                    }`}
                  >
                    <img
                      className="object-scale-down  border-[2px] border-solid border-[#E8DB70] rounded-[12px] lg:w-[155px] lg:h-[120px] w-[250px] h-[90px]"
                      src={process.env.REACT_APP_IMG_CDN_URL + img}
                      alt=""
                    />
                  </div>
                </>
              );
            })}
          </div>
        </section>
      ) : (
        <></>
      )}
      {prdImages?.length ? (
        <section className="lg:hidden md:hidden h-full flex justify-center -translate-y-5 ">
          {/* Main Image Carousel with Looping */}
          <div className="selectedImages w-[100%] h-auto max-w-[85%] overflow-hidden rounded-[28px] md:hidden lg:hidden ">
            <Swiper
              loop={true} // Enables looping through images
              spaceBetween={10}
              slidesPerView={1}
              pagination={{ clickable: true, type: "bullets" }}
              modules={[Pagination]}
            >
              {prdImages.map((img, index) => (
                <SwiperSlide key={index}>
                  <img
                    width="700"
                    height="700"
                    decoding="async"
                    fetchPriority="high"
                    loading="eager"
                    src={process.env.REACT_APP_IMG_CDN_URL + img}
                    alt={`Products ${index + 1}`}
                    className="w-full h-auto"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      ) : null}
    </>
  );
};
