
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export const TermsandConditions: React.FC = () => {


    const location =useLocation()

    useEffect(()=>{
    window.scrollTo(0,0)
    },[location.pathname === "/terms-and-conditions"])
    return (
        <>
            <section className="lg:block lg:py-10 lg:px-52    lg:relative lg:top-[60%] lg:left-[50%] lg:-translate-x-[50%]    md:block md:py-10 md:px-[50px]   md:relative md:top-[50%] md:left-[50%] md:-translate-x-[50%]   block py-10 px-[15px]   relative top-[50%] left-[50%] -translate-x-[50%]  ">
                <div className="lg:w-fit lg:h-fit lg:py-10 lg:px-10 md:w-fit md:h-fit md:py-10 w-fit h-fit py-10 ">
                    <h1 className=" font-bold text-2xl">Terms & Conditions</h1>
                    <br />
                    <p className=" text-left">
                        Welcome to the wow kid, operated by the wow kid Kids Private Limited.
                        Please carefully review these Terms and Conditions before utilising
                        our website and services. By accessing or using our website and
                        placing an order, you signify your acceptance and agreement to abide
                        by these Terms and Conditions.
                    </p>
                    <br />
                    <h2 className=" text-base font-semibold">
                        Acceptance of Terms
                    </h2>
                    <p className=" text-left">
                        By accessing our website or placing an order with the wow kid, you
                        acknowledge and agree to comply with these Terms and Conditions. If
                        you do not agree with these terms, please refrain from using our
                        website or services.
                    </p>
                    <br />
                    <h2 className="text-base font-semibold">
                        Personalised Products
                    </h2>
                    <p className="text-left ">
                        The wow kid specialises in offering personalised products for
                        children, including but not limited to personalised storybooks, name
                        labels, and luggage tags. We endeavour to craft high-quality,
                        customised items based on the information provided by the customer.
                    </p>
                    <br />
                    <h2 className=" text-base font-semibold">
                        Ordering and Payment
                    </h2>
                    <p className=" text-left">
                        3.1. Orders: When placing an order with the wow kid, you undertake to
                        provide accurate and complete information. We reserve the right to
                        decline or cancel orders at our discretion. 3.2. Payment: Orders can
                        be paid for using the payment methods provided on our website. All
                        payments are securely processed. By placing an order, you agree to pay
                        all applicable fees, taxes, and shipping costs associated with your
                        order.
                    </p>
                    <br />
                    <h2 className=" text-base font-semibold">
                        Intellectual Property
                    </h2>
                    <p className=" text-left">
                        All content on the the wow kid website, including logos, designs, and
                        product images, is the exclusive property of the wow kid Kids Private
                        Limited and is safeguarded by intellectual property laws. You may not
                        utilise or reproduce our content without explicit permission.
                    </p>
                    <br />
                    <h2 className=" text-base font-semibold">Privacy Policy</h2>
                    <p className=" text-left">
                        Please review our Privacy Policy to comprehend how we collect,
                        utilise, and safeguard your personal information.
                    </p>
                    <br />
                    <h2 className=" text-base font-semibold">
                        Changes to Terms and Conditions
                    </h2>
                    <p className=" text-left">
                        The wowkid reserves the right to amend, modify, or alter these Terms
                        and Conditions at any time. Such changes will become effective upon
                        being posted on our website.
                    </p>
                    <br />
                    <h2 className=" text-base font-semibold">
                        Contact Information
                    </h2>
                    <p className=" text-left">
                        If you have any queries or concerns regarding these Terms and
                        Conditions, please reach out to us at{" "}
                        <strong >hi@thewowkid.kids</strong>.
                    </p>
                    <br />

                </div>
            </section>
        </>
    );
};