import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, FreeMode } from "swiper/modules";
import Aos from "aos";
import "aos/dist/aos.css";

import fragmentreeLogo from "../../assets/images/svg/Ftree_Logo-Pink-01.svg";
import IndiaPostlogo from "../../assets/images/svg/India Post.svg";
import Razorpaylogo from "../../assets/images/svg/razorpay.svg";
import { useEffect } from "react";

export const VerifiedPatners: React.FC = () => {
  useEffect(() => {
    Aos.init();
    // Aos.refresh();
  }, []);
  return (
    <>
      <div className="  lg:flex md:flex justify-evenly gap-7  items-center  py-5 px-4  hidden  ">
        <div
          className="text-center flex flex-col items-center gap-4   text-[16px]  "
          data-aos="zoom-in"
        >
          <span className="text-[11px]   text-center">DELIVERY  PATNER</span>

          <img
            className="w-80    h-[100px] "
            src={IndiaPostlogo}
            alt="indianpost"
          />
        </div>

        <div
          className="text-center flex flex-col   gap-1   text-[16px]   "
          data-aos="zoom-in"
        >
          <span className="text-[11px]    text-center">ASSOCIATED WITH</span>

          <img
            className="w-80  h-[100px]  "
            src={fragmentreeLogo}
            alt="Awslogo"
          />
        </div>

        <div
          className="text-center flex flex-col gap-2   text-[16px]  "
          data-aos="zoom-in"
        >
          <span className=" text-[11px]    text-center">
            PAYMENT SECURED BY{" "}
          </span>

          <img
            className="w-80  h-[100px] "
            src={Razorpaylogo}
            alt="Razorpay"
          />
        </div>
      </div>

      <div className="lg:hidden md:hidden  ">
  <Swiper
    slidesPerView={1} // Dynamically adjust slides per view
    spaceBetween={50} // Adjust spacing between slides
    loop={true}
    speed={1000}
    autoplay={{
      delay:0.9,
      disableOnInteraction: false,
    }}
    modules={[Autoplay]}
    className="w-full h-full"
  >
    <SwiperSlide className="flex justify-center">
      <div className="text-center flex flex-col items-center gap-7">
        <span className="text-[15px]">Delivery Partner</span>
        <img className="w-56" src={IndiaPostlogo} alt="India Post" />
      </div>
    </SwiperSlide>

    <SwiperSlide className="flex justify-center">
      <div className="text-center flex flex-col items-center gap-4">
        <span className="text-[15px]">Associated with</span>
        <img className="w-80 h-auto" src={fragmentreeLogo} alt="AWS" />
      </div>
    </SwiperSlide>

    <SwiperSlide className="flex justify-center">
      <div className="text-center flex flex-col items-center gap-4">
        <span className="text-[15px]">Payment Secured By</span>
        <img className="w-80" src={Razorpaylogo} alt="Razorpay" />
      </div>
    </SwiperSlide>
  </Swiper>
</div>;
    </>
  );
};