import React, { createContext, useState, useEffect, useContext } from 'react';

interface LocalStorageContextType {
    localStorageData: { [key: string]: string };
    updateLocalStorage: (key: string, value: string) => void;
    deleteLocalStorage : (key: string) => void;
}

const LocalStorageContext = createContext<LocalStorageContextType | undefined>(undefined);

export const LocalStorageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [localStorageData, setLocalStorageData] = useState<{ [key: string]: string }>(() => {
        const data: { [key: string]: string } = {};
        Object.keys(localStorage).forEach((key) => {
            data[key] = localStorage.getItem(key) || '';
        });
        return data;
    });

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key) {
                setLocalStorageData((prev) => ({
                    ...prev,
                    [event.key!]: event.newValue || '',
                }));
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const updateLocalStorage = (key: string, value: string) => {
        localStorage.setItem(key, value);
        setLocalStorageData((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const deleteLocalStorage = (key: string) => {
        localStorage.removeItem(key);
        setLocalStorageData((prev) => {
            delete prev[key]
            return ({
            ...prev
        })
    });
    };

    return (
        <LocalStorageContext.Provider value={{ localStorageData, updateLocalStorage, deleteLocalStorage }}>
            {children}
        </LocalStorageContext.Provider>
    );
};

export const useLocalStorage = (): LocalStorageContextType => {
    const context = useContext(LocalStorageContext);
    if (!context) {
        throw new Error('useLocalStorage must be used within a LocalStorageProvider');
    }
    return context;
};
