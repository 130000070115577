import React, { useState, useContext, useEffect } from "react";
import "./header.scss";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import mainLogo from "../../assets/images/svg/wow_kid_logo.svg";
import hamburgerIcon from "../../assets/images/svg/hamburger.svg";
import trackIcon from "../../assets/images/svg/track.svg";
import cartIcon from "../../assets/images/svg/cart.svg";
import PageContext from "../page-context/page-context";
import securedImg from "../../assets/images/svg/secured.svg";
import cartSectionImg from "../../assets/images/svg/cart-section.svg";
import addressSectionImg from "../../assets/images/svg/address-section.svg";
import addressSectionGreenImg from "../../assets/images/svg/address-section-green.svg";
import paymentSectionImg from "../../assets/images/svg/payment-section.svg";
import paymentSectionGreenImg from "../../assets/images/svg/payment-section-green.svg";
import { useLocalStorage } from "../localStorage/localStorageHandler";

export const Header: React.FC = () => {
  const { currentPage, updateCurrentPage } = useContext(PageContext);
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <>
      <header className="   main-header  sm:px-[40px]  lg:pb-6 pb-4 px-4 pt-3">
        <div className="flex aligns-end  items-end gap-2">
          <div className="logo w-[52px] h-[32px] lg:w-[96px] md:h-[60px]">
            <Link to="/" onClick={() => updateCurrentPage("/")}>
              <img
                className="lg:h-[80px] w-[70px] h-[50px] "
                src={mainLogo}
                alt=""
              />
            </Link>
          </div>

          {!currentPage?.includes("/cart") ? (
            <>
              <nav className="      desktop-nav sm:ml-[60px]">
                <ul className="header-links    navbar flex flex-row items-center sm:gap-[30px] ">
                  <li className={currentPage === "/name-slip" ? "  " : ""}>
                    <Link
                      to="/name-slip"
                      onClick={() => updateCurrentPage("/name-slip")}
                    >
                      Name slip
                    </Link>
                  </li>
                  {/* <li className={currentPage === '/note-book' ? 'active' : ''}>
                                            <Link to="/note-book" onClick={() => updateCurrentPage('/note-book')}>
                                                Note book
                                            </Link>
                                        </li> */}
                  <li className={currentPage === "/bag-tags" ? "" : ""}>
                    <Link
                      to="/bag-tags"
                      onClick={() => updateCurrentPage("/bag-tags")}
                    >
                      Bag tags
                    </Link>
                  </li>
                  <li className={currentPage === "/story-books" ? "" : ""}>
                    <Link
                      to="/story-books"
                      onClick={() => updateCurrentPage("/story-books")}
                    >
                      Story books
                    </Link>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="header-actions flex gap-4 ">
          {!currentPage?.includes("/cart") ? (
            <>
              <div className="menu-icon flex items-center">
                <MobileMenuAction
                  setShowNavbar={setShowNavbar}
                  showNavbar={showNavbar}
                  handleShowNavbar={handleShowNavbar}
                  updateCurrentPage={updateCurrentPage}
                  currentPage={currentPage}
                />
              </div>

              <div className="desktop-main-icon">
                <TrackOrder updateCurrentPage={updateCurrentPage} />
              </div>
              <div className="desktop-main-icon">
                <CartInfo updateCurrentPage={updateCurrentPage} />
              </div>
            </>
          ) : (
            <>
              <img
                className="lg:w-[124px] lg:h-[20px] w-[100px] h-[15px] pl-10 "
                src={securedImg}
                alt=""
              />
            </>
          )}
        </div>
      </header>
      {currentPage?.includes("/cart") ? (
        <div className="fixed left-2/4 top-[35px] -translate-x-1/2 translate-y-[0] ">
          <CartSteps
            currentPage={currentPage}
            updateCurrentPage={updateCurrentPage}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const CartSteps: React.FC<{ currentPage: any; updateCurrentPage: any }> = ({
  currentPage,
  updateCurrentPage,
}) => {
  const [shownavbar, setshownavbar] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <section className="flex items-center     w-full lg:translate-y-2 -translate-y-3 pr-5 pb-2">
        <div className="flex items-center gap-2 ">
          <img
            className="lg:w-[32px] lg:h-[32px] w-[30px] h-[30px] "
            src={cartSectionImg}
            alt=""
          />
          <h2
            className={` hidden  lg:block    text-[16px] font-semibold leading-[20px] text-left ${
              searchParams.get("state") === "info" ||
              searchParams.get("state") === "address" ||
              searchParams.get("state") === "payment"
                ? "text-[#03977E]"
                : "text-[#242631]"
            }`}
          >
            My cart
          </h2>
        </div>
        <div className="flex justify-center items-center ml-1 mr-2 lg:ml-4 lg:mr-4 lg:items-center lg:justify-center   md:flex md:justify-center md:items-center md:ml-4  md:mr-4">
          <span
            className={`w-[20px] h-[2px] lg:w-[32px] lg:h-[2px]  ${
              searchParams.get("state") === "address" ||
              searchParams.get("state") === "payment"
                ? "bg-[#03977E]"
                : " bg-[#DFDFDF]"
            }`}
          ></span>
        </div>
        <div className="flex items-center gap-2">
          <img
            className="lg:w-[32px] lg:h-[32px] w-[30px] h-[30px]"
            src={
              searchParams.get("state") === "address" ||
              searchParams.get("state") === "payment"
                ? addressSectionGreenImg
                : addressSectionImg
            }
            alt=""
          />
          <h2
            className={` hidden  lg:block       text-[16px] font-semibold leading-[20px] text-left ${
              searchParams.get("state") === "address" ||
              searchParams.get("state") === "payment"
                ? "text-[#03977E]"
                : "text-[#242631]"
            }`}
          >
            Address
          </h2>
        </div>
        <div className="flex justify-center items-center  ml-1 mr-2  lg:flex  lg:ml-4 lg:mr-4 lg:items-center lg:justify-center">
          <span
            className={`lg:w-[32px] lg:h-[2px] w-[20px] h-[2px] ${
              searchParams.get("state") === "payment"
                ? "bg-[#03977E]"
                : " bg-[#DFDFDF]"
            }`}
          ></span>
        </div>
        <div className="flex items-center gap-2">
          <img
            className="lg:w-[32px] lg:h-[32px] w-[30px] h-[30px]"
            src={
              searchParams.get("state") === "payment"
                ? paymentSectionGreenImg
                : paymentSectionImg
            }
            alt=""
          />
          <h2
            className={` hidden  lg:block       text-[16px] font-semibold leading-[20px] text-left ${
              searchParams.get("state") === "payment"
                ? "text-[#03977E]"
                : "text-[#242631]"
            }`}
          >
            Payment
          </h2>
        </div>
      </section>
    </>
  );
};

const MobileMenuAction: React.FC<{
  showNavbar: boolean;
  setShowNavbar: any;
  handleShowNavbar: () => void;
  updateCurrentPage: (path: string) => void;
  currentPage: string;
}> = ({
  showNavbar,
  handleShowNavbar,
  updateCurrentPage,
  currentPage,
  setShowNavbar,
}) => {
  // Function to close the menu when a link is clicked
  const handleLinkClick = (path: string) => {
    setShowNavbar(false);
    updateCurrentPage(path);
    // Close the menu
  };

  return (
    <div className="mobile-menu flex justify-between items-center">
      <div className="header-actions     pr-4 flex gap-4 justify-end ">
        <TrackOrder updateCurrentPage={handleLinkClick} />
        <CartInfo updateCurrentPage={handleLinkClick} />
      </div>
      {/* Hamburger Icon */}
      <div className="hamburger-icon" onClick={handleShowNavbar}>
        <img
          className="w-[20px] h-[20px] mt-1"
          src={hamburgerIcon}
          alt="Menu"
        />
      </div>

      {/* Dropdown Navigation */}
      <div
        className={` nav-elements block mt-3 drop-shadow-xl bg-[#FAFAFA] ${
          showNavbar ? "active" : ""
        }`}
      >
        <ul>
          <li className={currentPage === "/name-slip" ? "" : ""}>
            <Link
              className="text-[19px] "
              to="/name-slip"
              onClick={() => handleLinkClick("/name-slip")}
            >
              Name slip
            </Link>
          </li>
          {/* <li className={currentPage === "/note-book" ? "active" : ""}>
                        <Link className="text-[32px]" to="/note-book" onClick={() => handleLinkClick("/note-book")}>
                            Note book
                        </Link>
                    </li> */}
          <li className={currentPage === "/bag-tags" ? "" : ""}>
            <Link
              className="text-[32px]"
              to="/bag-tags"
              onClick={() => handleLinkClick("/bag-tags")}
            >
              Bag tags
            </Link>
          </li>
          <li className={currentPage === "/story-books" ? "" : ""}>
            <Link
              className="text-[32px]"
              to="/story-books"
              onClick={() => handleLinkClick("/story-books")}
            >
              Story books
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const TrackOrder: React.FC<{
  updateCurrentPage: any;
}> = ({ updateCurrentPage }) => {
  return (
    <div className="track-order">
      <Link to="/track-order" onClick={() => updateCurrentPage("/track-order")}>
        <img className="w-[19px] h-[19px]" src={trackIcon} alt="" />
      </Link>
    </div>
  );
};

const CartInfo: React.FC<{
  updateCurrentPage: any;
}> = ({ updateCurrentPage }) => {
  const { localStorageData, updateLocalStorage } = useLocalStorage();
  const [totalOrder, setTotalOrders] = useState<number>(0);

  useEffect(() => {
    const orders = localStorageData["wow_kids_orders"];
    if (orders) {
      const len = JSON.parse(orders)?.length;
      setTotalOrders(len);
    }
  }, [localStorageData]);

  return (
    <div className="cart-info relative">
      <div className="absolute top-[0] right-[0] bg-[#EF3B42] rounded-full w-[18px] h-[18px] flex justify-center items-center text-[12px] font-semibold text-[white]">
        {totalOrder}
      </div>
      <Link
        to="/cart?state=info"
        onClick={() => updateCurrentPage("/cart?state=info")}
      >
        <img
          className="w-[16px] h-[16px] lg:w-[20px] lg:h-[20px]"
          src={cartIcon}
          alt=""
        />
      </Link>
    </div>
  );
};
