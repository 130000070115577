import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../header/header";
import { Footer } from "../footer/footer";
import './layout.scss';

export const Layout: React.FC = () => {
    return (
        <section className="layout-section">
            <div className="upper-layout-section ">
                <div className="header-wrapper">
                    <Header/>
                </div>
                <div className="main-content mx-[auto] my-[100px]">
                    <Outlet />
                </div>
            </div>
            <div className="lower-layout-section">
                <Footer />
            </div>
        </section>
    )
}
