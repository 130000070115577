import { useEffect, useRef, useState } from "react";
import "./order-details.scss";

import trackerSuccess from "../../assets/images/svg/track-order-postive.svg";
import trackerPending from "../../assets/images/svg/track-order-pending.svg";
import trackerTopSuccess from "../../assets/images/svg/tracket-top-success.svg";
import trackerTopPending from "../../assets/images/svg/tracker-top-pending.svg";
import starRating from "../../assets/images/svg/star.svg";
import { useFetch } from "../../common/https";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "../../common/modal-popup/modal-popup";
import { CircularProgress, Rating } from "@mui/material";
import uploadImg from "../../assets/images/svg/upload-icon.svg";
import axios from "axios";
import { MdOutlineCurrencyRupee } from "react-icons/md";

const dummyOrderDetailsInfo = {
  orderId: "154977779050230784",
  prdId: "",
  prdName: "Face sticker",
  orderStatus: "in_progress",
  courier: "Delivery",
  trackingId: "3088026689816",
  expectedDeliveryDate: new Date(),
  rating: 4.5,
  totalRating: 60,
  price: 599,
  offerPrice: 299,
  discountRate: 0.1,
};

export const OrderDetails: React.FC = () => {
  const { fetchRest } = useFetch({});
  const [details, setDetails] = useState(dummyOrderDetailsInfo);
  const initialized = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState();

  const [inProgressOrder, setInProgressOrder] = useState([]);
  const [inReworkOrder, setInReworkOrder] = useState([]);
  const [inApprovalOrder, setInApprovalOrder] = useState([]);
  const [inPrintOrder, setInPrintOrder] = useState([]);
  const [inTransitOrder, setInTransitOrder] = useState([]);
  const [completedOrder, setCompletedOrder] = useState([]);

  const [productInOrder, setProductInOrder] = useState();
  const [addressInOrder, setAddressInOrder] = useState();
  const [refreshOrderList, setRefreshOrderList] = useState(false);

  const handleOrderClick = (order: any) => {
    const order_detail = document.getElementById("order-card");


    setSelectedOrder(order);
    setAddressInOrder(order.address);
    const productId = order.productId;

    fetchRest({
      url: `get-product-detail-by-id/${productId}`,
      method: "GET",
      isFullPageLoader: true,
    })
      .then((res) => {
        order_detail?.scrollIntoView({ behavior: "smooth", block: "end" });
        setProductInOrder(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
    // navigate("/about")
  };

  useEffect(() => {
    if (refreshOrderList) {
      fetchOrder();
      setRefreshOrderList(false);
    }
  }, [refreshOrderList]);

  const fetchOrder = () => {
    fetchRest({
      url: `get-order`,
      method: "POST",
      data: {
        accessToken: localStorage.getItem("accessToken"),
      },
      isFullPageLoader: true,
    })
      .then((res) => {
        console.log(res.data);
        const orders: any = [];
        res.data?.data?.forEach((order: any) => {
          order?.subOrders?.forEach((suborder: any) => {
            orders.push({
              ...suborder,
              orderDate: order?.orderDate,
              address: order?.addressInfo,
            });
          });
        });
        setInProgressOrder(
          orders?.filter((order: any) => order.orderStatus === "IN_ORDER")
        );
        setInReworkOrder(
          orders?.filter((order: any) => order.orderStatus === "IN_REWORK")
        );
        setInApprovalOrder(
          orders?.filter((order: any) => order.orderStatus === "IN_APPROVAL")
        );
        setInPrintOrder(
          orders?.filter((order: any) => order.orderStatus === "IN_PRINT")
        );
        setInTransitOrder(
          orders?.filter((order: any) => order.orderStatus === "IN_TRANSIT")
        );
        setCompletedOrder(
          orders?.filter((order: any) => order.orderStatus === "DELIVERED")
        );
      })
      .catch((err) => {
        console.error(err);
        localStorage.removeItem("accessToken");
        navigate("/track-order");
      });
  };

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      if (!localStorage.getItem("accessToken")) {
        navigate("/track-order");
      }
      fetchOrder();
    }
  }, []);

  return (
    <>
      {details ? (
        <section id="order-card" className="px-[30px] py-[0]">
          <div className="max-w-screen-xl mx-[auto] my-[0]">
            <div className="md:pt-[60px]">
              {inProgressOrder?.length ||
                completedOrder?.length ||
                inReworkOrder?.length ||
                inApprovalOrder?.length ||
                inTransitOrder?.length ||
                inPrintOrder?.length
                ? (
                  <>

                    {inTransitOrder?.length ? (
                      <>
                        <div className="flex items-start justify-start gap-4 flex-col">
                          <div className="flex gap-3 items-center">
                            <div
                              className={`flex items-center order-status text-[16px] font-medium leading-[20px] text-left text-[#6ECF9C]`}
                            >
                              <span
                                className={`block w-[10px] h-[10px] rounded-full bg-[#6ECF9C]`}
                              ></span>
                              &nbsp;Order in transit
                            </div>
                          </div>
                          {inTransitOrder.map((order) => {
                            return <OrderBar order={order} />;
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {inTransitOrder?.length ? (<hr className="text-[#EAEAEC] my-[40px]" />) : <></>}

                    {inPrintOrder?.length ? (
                      <>
                        <div className="flex items-start justify-start gap-4 flex-col">
                          <div className="flex gap-3 items-center">
                            <div
                              className={`flex items-center order-status text-[16px] font-medium leading-[20px] text-left text-[#6ECF9C]`}
                            >
                              <span
                                className={`block w-[10px] h-[10px] rounded-full bg-[#6ECF9C]`}
                              ></span>
                              &nbsp;Printing in progress
                            </div>
                          </div>
                          {inPrintOrder.map((order) => {
                            return <OrderBar order={order} />;
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {inPrintOrder?.length ? (<hr className="text-[#EAEAEC] my-[40px]" />) : <></>}

                    {inApprovalOrder?.length ? (
                      <>
                        <div className="flex items-start justify-start gap-4 flex-col">
                          <div className="flex gap-3 items-center">
                            <div
                              className={`flex items-center order-status text-[16px] font-medium leading-[20px] text-left text-[#6ECF9C]`}
                            >
                              <span
                                className={`block w-[10px] h-[10px] rounded-full bg-[#6ECF9C]`}
                              ></span>
                              &nbsp;In approval mode
                            </div>
                          </div>
                          {inApprovalOrder.map((order) => {
                            return (
                              <div
                                className="cursor-pointer w-full"
                                onClick={() => handleOrderClick(order)}
                              >
                                <OrderBar order={order} />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {inApprovalOrder?.length ? (<hr className="text-[#EAEAEC] my-[40px]" />) : <></>}

                    {inReworkOrder?.length ? (
                      <>
                        <div className="flex items-start justify-start gap-4 flex-col">
                          <div className="flex gap-3 items-center">
                            <div
                              className={`flex items-center order-status text-[16px] font-medium leading-[20px] text-left text-[#6ECF9C]`}
                            >
                              <span
                                className={`block w-[10px] h-[10px] rounded-full bg-[#6ECF9C]`}
                              ></span>
                              &nbsp;In Rework
                            </div>
                          </div>
                          {inReworkOrder.map((order) => {
                            return <OrderBar order={order} />;
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {inReworkOrder?.length ? (<hr className="text-[#EAEAEC] my-[40px]" />) : <></>}

                    {inProgressOrder?.length ? (
                      <>
                        <div className="flex items-start justify-start gap-4 flex-col">
                          <div className="flex gap-3 items-center">
                            <h1 className="text-[22px] font-semibold leading-[26.4px] text-left text-[#242631]">
                              Total Orders
                            </h1>
                            {/* <div
                            className={`flex items-center order-status text-[16px] font-medium leading-[20px] text-left text-[#ED731B] text-[ED731B]`}
                          >
                            <span
                              className={`block w-[10px] h-[10px] rounded-full bg-[#ED731B]`}
                            ></span>
                            &nbsp;In Progress
                          </div> */}
                          </div>
                          {inProgressOrder.map((order) => {
                            return (
                              <div
                                className="cursor-pointer w-full"
                                onClick={() => handleOrderClick(order)}
                              >
                                <OrderBar order={order} />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {inProgressOrder?.length ? (<hr className="text-[#EAEAEC] my-[40px]" />) : <></>}

                    <OrderProductInfo details={productInOrder} />
                    <div className="mt-[30px]">
                      <OrderMetaInfoDetails
                        selectedOrder={selectedOrder}
                        setSelectedOrder={setSelectedOrder}
                        setRefreshOrderList={setRefreshOrderList}
                      />
                    </div>
                    <div className="mt-[30px]">
                      <PlaceOrderAddressDetails
                        adressInfo={addressInOrder}
                        selectedOrder={selectedOrder}
                      />
                    </div>

                    {completedOrder?.length ? (<hr className="text-[#EAEAEC] my-[40px]" />) : <></>}
                    {completedOrder?.length ? (
                      <>
                        <div className="flex items-start justify-start gap-4 flex-col">
                          <div className="flex gap-3 items-center">
                            <div
                              className={`flex items-center order-status text-[16px] font-medium leading-[20px] text-left text-[#6ECF9C]`}
                            >
                              <span
                                className={`block w-[10px] h-[10px] rounded-full bg-[#6ECF9C]`}
                              ></span>
                              &nbsp;Completed
                            </div>
                          </div>
                          {completedOrder.map((order) => {
                            return <OrderBar order={order} />;
                          })}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

const OrderMetaInfoDetails: React.FC<{
  selectedOrder: any;
  setSelectedOrder: any;
  setRefreshOrderList: any;
}> = ({ selectedOrder, setSelectedOrder, setRefreshOrderList }) => {
  const { fetchRest } = useFetch({});
  const [isModalOpen, setModalState] = useState(false);
  const [orderMetaDetails, setOrderMetaDetails] = useState<any>();

  useEffect(() => {
    if (selectedOrder) {
      const orderMetaDetailsInfo = JSON.parse(
        JSON.stringify({ ...selectedOrder?.orderMetaDetails })
      );
      setOrderMetaDetails(orderMetaDetailsInfo);
      if (orderMetaDetailsInfo?.imgs?.length) {
        for (let i = 0; i < orderMetaDetailsInfo.imgs.length; i++) {
          fetchRest({
            url: `img-signed-url`,
            method: "POST",
            data: {
              imageId: orderMetaDetailsInfo.imgs[i],
            },
            isFullPageLoader: true,
          })
            .then((res) => {
              orderMetaDetailsInfo.imgs[i] = res.data?.data?.url;
              setOrderMetaDetails(orderMetaDetailsInfo);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    }
  }, [selectedOrder]);

  return (
    <>
      {selectedOrder && orderMetaDetails ? (
        <>
          <Modal
            heading={"Edit Order Details"}
            isOpen={isModalOpen}
            onClose={() => setModalState(!isModalOpen)}
          >
            <section className="m-[12px] mt-0 p-[24px] rounded-[20px] border-[1px] border-solid border-[#EAECF0]  overflow-y-auto scrollbar-hide">
              <AddDetailsFormHandlerTemplate
                selectedOrder={selectedOrder}
                orderMetaDetails={orderMetaDetails}
                setModalState={setModalState}
                setSelectedOrder={setSelectedOrder}
                setRefreshOrderList={setRefreshOrderList}
              />
            </section>
          </Modal>
          <h1 className="text-[18px] font-semibold leading-[20px] text-left text-[#242631] my-4">
            Order Details
          </h1>
          <div className="flex-col items-start justify-between gap-6 w-full flex lg:p-[30px] p-[20px] rounded-[12px] bg-[#F6F6F6] border-[1px] border-solid border-[#DFE9EF]">
            <div className="items-start justify-between gap-6 w-full flex lg:flex-row flex-col ">
              <div className="flex lg:w-[70%] w-full gap-[20px] flex-wrap">
                <div className="w-full flex items-start gap-[20px]">
                  <div className="flex items-center gap-4 flex-row flex-wrap">
                    {orderMetaDetails?.imgs?.length ? (
                      <>
                        {orderMetaDetails?.imgs.map((url: string) => {
                          return (
                            <>
                              <img
                                className="lg:w-[220px] lg:h-auto w-[400px] h-auto object-scaledown    object-center rounded-[12px]"
                                src={url}
                                alt=""
                              />
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <div className="w-[2px] h-[100%] bg-[#80808038]"></div> */}
                  <div className="lg:flex lg:flex-col lg:gap-5  grid-cols-1 grid-rows-2 justify-center gap-y- ">
                    {orderMetaDetails?.first_name && orderMetaDetails?.last_name ? (
                      <>
                        <div className="lg:flex lg:gap-1 ">


                          <div className=" hidden lg:inline-block  lg:text-[20px] text-nowrap text-[9px] font-semibold lg:leading-[18px] text-left text-[#72747C] ">
                            {orderMetaDetails?.first_name}
                          </div>
                          <div className="hidden lg:inline-block   lg:text-[20px] text-[9px] w-[50px] font-semibold lg:leading-[18px] text-left text-[#72747C] text-nowrap">
                            {orderMetaDetails?.last_name}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {orderMetaDetails?.school ? (
                      <>
                        <div className="lg:flex lg:gap-3 lg:w-full   w-[135px]">
                          <div className=" lg:inline-block hidden  text-[20px] font-semibold lg:leading-[18px] text-left text-[#72747C] overflow-hidden text-ellipsis text-wrap  lg:pr-0 pr-[10px] ">
                            {orderMetaDetails?.school}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* mobile view */}

                    {orderMetaDetails?.first_name &&
                      orderMetaDetails?.last_name &&
                      orderMetaDetails?.school ? (
                      <>
                        <div className="flex flex-col gap-2 lg:hidden md:hidden">
                          <div className="flex ">
                            <div className="  lg:text-[14px] text-nowrap text-[13px] font-semibold lg:leading-[18px] text-left text-[#000000] ">
                              {orderMetaDetails?.first_name}
                            </div>

                            <div className="   lg:text-[14px] text-[13px] w-[50px] font-semibold lg:leading-[18px] text-left text-[#000000] text-nowrap">
                              {orderMetaDetails?.last_name}
                            </div>
                          </div>

                          <div className=" text-[12px] font-semibold  text-left text-[#000000] overflow-hidden text-ellipsis text-wrap lg:text-nowrap lg:pr-0 pr-[10px] ">
                            {orderMetaDetails?.school}
                          </div>
                          <div className=" lg:hidden md:hidden w-full  flex justify-end">
                            <button
                              onClick={() => {
                                setModalState(true);
                              }}
                              className="cursor-pointer bg-gradient-to-b from-indigo-500 to-indigo-600 shadow-[0px_4px_32px_0_rgba(99,102,241,.70)] px-4 py-1 rounded-lg text-[12px] border-[1px] border-slate-500 text-white font-medium group"
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* {orderMetaDetails?.rollNo ? (
                      <>
                        <div className="   hidden lg:flex lg:gap-3  ">
                          <div className=" lg:inline-block hidden          lg:text-[16px] text-[10px] font-bold lg:leading-[20px] text-left text-[#242631] text-nowrap">
                            Roll No:
                          </div>

                          <div className=" text-left  lg:text-[14px] text-[9px] font-semibold lg:leading-[18px]  text-[#72747C] text-nowrap">
                            {orderMetaDetails?.rollNo}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )} */}
                    {/* {orderMetaDetails?.std ? (
                      <>
                        <div className=" hidden lg:flex lg:gap-3 ">
                          <div className=" lg:inline-block hidden            lg:text-[16px] text-[10px] font-bold lg:leading-[20px] text-left  text-[#242631] text-nowrap">
                            Std :
                          </div>
                         
                          <div className="text-left    lg:text-[14px] text-[9px] font-semibold lg:leading-[18px]  text-[#72747C] text-nowrap">
                            {orderMetaDetails?.std}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )} */}

                  </div>
                </div>
              </div>
              {
                selectedOrder?.orderStatus === 'IN_APPROVAL' || selectedOrder?.orderStatus === 'IN_REWORK' ?
                <div className="lg:flex md:flex hidden">
                  <button
                    onClick={() => {
                      setModalState(true);
                    }}
                    className=" cursor-pointer bg-gradient-to-b from-indigo-500 to-indigo-600 shadow-[0px_4px_32px_0_rgba(99,102,241,.70)] px-5 py-2 rounded-lg text-[16px] border-[1px] border-slate-500 text-white font-medium group "
                  >
                    Edit
                  </button>
                </div>
                : <></>
              }
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const PlaceOrderAddressDetails: React.FC<{
  adressInfo: any;
  selectedOrder: any;
}> = ({ adressInfo, selectedOrder = null }) => {
  const { fetchRest } = useFetch({});
  const [isModalOpen, setModalState] = useState(false);
  const [formData, setFormData] = useState<any>({
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    country: "",
    state: "",
    pinCode: "",
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (selectedOrder) {
      const obj = formData;
      const address = selectedOrder?.address;
      for (let key in obj) {
        if (address.hasOwnProperty(key) && obj.hasOwnProperty(key)) {
          obj[key] = address[key];
        }
      }
      setFormData(obj);
    }
  }, [selectedOrder]);

  const validate = () => {
    const newErrors: any = {};
    if (!formData.addressLine1)
      newErrors.addressLine1 = "Address Line 1 is required.";
    if (!formData.addressLine2)
      newErrors.addressLine2 = "Address Line 2 is required.";
    if (!formData.country) newErrors.country = "Country is required.";
    if (!formData.state) newErrors.state = "State/Province is required.";
    if (!formData.pinCode) newErrors.pinCode = "Pin code is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors((prev: any) => ({ ...prev, [name]: null }));
    }
    let isValid = true;
    for (var key of Object.keys(errors)) {
      if (errors[key]) {
        isValid = false;
        break;
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (validate()) {
      fetchRest({
        url: `update-order-address`,
        method: "POST",
        data: {
          orderId: selectedOrder.orderId,
          changedAddressInfo: { ...formData },
        },
        isFullPageLoader: true,
      })
        .then(() => {
          setModalState(false);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      return;
    }
  };

  return (
    <>
      {adressInfo ? (
        <div className="lg:mt-2  overflow-y-auto">
          <Modal
            heading={"Edit Shipping Address"}
            isOpen={isModalOpen}
            onClose={() => setModalState(!isModalOpen)}
          >
            <section className="lg:m-[12px] mx-[20px] lg:mt-0 mt-[50px] p-[24px] rounded-[20px] border-[1px] border-solid border-[#EAECF0]  overflow-y-auto h-full  lg:max-h-auto max-h-[400px] ">
              <form
                className="address-form w-full h-full"
                onSubmit={handleSubmit}
              >
                <div className="flex items-center justify-between lg:gap-[10px] gap-[5px] md:gap-[20px] lg:flex-nowrap ">
                  <div className="w-full sm:w-1/2">
                    <label>Address line 1*</label>
                    <input
                      type="text"
                      name="addressLine1"
                      value={formData.addressLine1}
                      onChange={handleChange}
                    />
                    {errors.addressLine1 && (
                      <p style={{ color: "red" }}>{errors.addressLine1}</p>
                    )}
                  </div>

                  <div className="w-full sm:w-1/2">
                    <label>Address line 2*</label>
                    <input
                      type="text"
                      name="addressLine2"
                      value={formData.addressLine2}
                      onChange={handleChange}
                    />
                    {errors.addressLine2 && (
                      <p style={{ color: "red" }}>{errors.addressLine2}</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between gap-[10px] sm:gap-[20px] md:gap-[20px] flex-wrap sm:flex-nowrap">
                  <div className="w-full sm:w-1/2">
                    <label>Landmark</label>
                    <input
                      type="text"
                      name="landmark"
                      value={formData.landmark}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="w-full sm:w-1/2">
                    <label>Country*</label>
                    <select
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    >
                      <option value="">Select country</option>
                      <option value="India">India</option>
                    </select>
                    {errors.country && (
                      <p style={{ color: "red" }}>{errors.country}</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between gap-[10px] sm:gap-[20px] md:gap-[20px] flex-wrap sm:flex-nowrap">
                  <div className="w-full sm:w-1/2">
                    <label>State / Province*</label>
                    <select
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                    >
                      <option value="">Select state</option>
                      <option value="State1">Kerala</option>
                      <option value="State2">Tamil Nadu</option>
                      <option value="State2">Karnataka</option>
                    </select>
                    {errors.state && (
                      <p style={{ color: "red" }}>{errors.state}</p>
                    )}
                  </div>

                  <div className="w-full sm:w-1/2">
                    <label>Pin code*</label>
                    <input
                      type="text"
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleChange}
                    />
                    {errors.pinCode && (
                      <p style={{ color: "red" }}>{errors.pinCode}</p>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className=" lg:block md:block hidden  bg-[#03977E] px-[10px] py-[18px] rounded-[58px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center "
                >
                  Edit
                </button>

                <button
                  type="submit"
                  className=" lg:hidden md:hidden block  bg-[#03977E] px-[10px] py-[18px]  rounded-[58px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center fixed bottom-0 left-0"
                >
                  Edit
                </button>
              </form>
            </section>
          </Modal>

          <h1 className="text-[18px] font-semibold leading-[20px] text-left text-[#242631] my-4">
            Address Details
          </h1>
          <div className="flex-col items-start justify-between gap-6 w-full flex p-[30px] rounded-[12px] bg-[#F6F6F6] border-[1px] border-solid border-[#DFE9EF]">
            <div className="lg:items-start justify-between gap-6 w-full flex items-end">
              <div className="flex w-[70%] gap-[20px] lg:flex-wrap">
                <div className="flex items-start gap-3 flex-col">
                  <h1 className="lg:text-[16px] text-[14px] font-semibold leading-[20px] text-left text-[#242631]">
                    {adressInfo.fullName}
                  </h1>
                  <div className="lg:text-[16px] text-[14px] font-semibold leading-5 lg:leading-[18px] text-left text-[#72747C] text-wrap">
                    {adressInfo.addressLine1}, {adressInfo.addressLine2}
                    <br />
                    {adressInfo.landmark}, {adressInfo.state},{" "}
                    {adressInfo.country}, {adressInfo.pinCode}
                  </div>
                </div>
              </div>
              <div className="lg:block md:block hidden">
                <button
                  onClick={() => {
                    setModalState(true);
                  }}
                  className="cursor-pointer bg-gradient-to-b from-indigo-500 to-indigo-600 shadow-[0px_4px_32px_0_rgba(99,102,241,.70)] px-5 py-2 rounded-lg text-[16px] border-[1px] border-slate-500 text-white font-medium group"
                >
                  Change
                </button>
              </div>
              <div className="lg:hidden md:hidden flex  justify-end h-full">
                <button
                  onClick={() => {
                    setModalState(true);
                  }}
                  className="cursor-pointer bg-gradient-to-b from-indigo-500 to-indigo-600 shadow-[0px_4px_32px_0_rgba(99,102,241,.70)] px-4 py-1 rounded-lg text-[12px] border-[1px] border-slate-500 text-white font-medium group"
                >
                  Change
                </button>
              </div>
            </div>
            <hr className="w-full my-0" />
            <div className="flex flex-row  items-start lg:gap-3 gap-[5rem] lg:flex-col">
              <div className="w-[60%]">
                <h1 className="lg:text-[16px] text-[14px] font-semibold leading-[20px] text-left text-[#242631] text-nowrap">
                  Phone number
                </h1>
                <div className="text-[14px] font-semibold leading-[18px] text-left text-[#72747C]">
                  {adressInfo.phoneNumber}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const OrderProductInfo: React.FC<{ details: any }> = ({ details }) => {
  const [isModalOpen, setModalState] = useState(false);

  return (
    <>
      {details ? (
        <div className="w-full lg:w-full md:w-full ">
          <Modal
            heading="Rate Product"
            isOpen={isModalOpen}
            onClose={() => setModalState(!isModalOpen)}
          >
            <div className="lg:m-[12px] w-full flex flex-col items-start gap-5 mt-0 p-[24px] min-h-[250px] rounded-[20px] border border-solid border-[#EAECF0]">
              {/* Star Rating */}
              <Rating name="half-rating" defaultValue={0} precision={0.5} />

              {/* Review Form */}
              <form className="lg:max-w-[500px] w-[350px]" action="">
                {/* File Input */}
                <label className="relative flex flex-col items-center justify-center w-full p-4 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-[#03977E] transition">
                  <input
                    type="file"
                    name="media"
                    accept="image/*,video/*"
                    multiple
                    className="hidden"
                  />
                  <div className="flex flex-col items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-8 h-8 text-gray-500"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3a1 1 0 011 1v8.586l2.293-2.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V4a1 1 0 011-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-gray-700 font-medium">
                      Click to Upload
                    </span>
                    <span className="text-sm text-gray-500">
                      or drag and drop files here
                    </span>
                  </div>
                </label>

                {/* Media Preview Placeholder */}
                {/* <div className="flex flex-wrap mt-2 gap-2">
                  <div className="w-20 h-20 bg-gray-200 rounded flex items-center justify-center">
                    <span className="text-gray-500">Image</span>
                  </div>
                  <div className="w-20 h-20 bg-gray-200 rounded flex items-center justify-center">
                    <span className="text-gray-500">Video</span>
                  </div>
                </div> */}

                {/* Review Textarea */}
                <textarea
                  className="lg:min-w-[500px] w-[350px] mt-3 p-2 border rounded"
                  name="review"
                  placeholder="Review the Product"
                ></textarea>

                {/* Submit Button */}
                <div className="w-full flex justify-center mt-3">
                  <button className="bg-[#03977E] px-[150px] lg:px-[220px] py-2 rounded-full text-white text-lg ">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Modal>

          <h1 className="text-[18px] font-semibold leading-[20px] text-left text-[#242631] my-4">
            Item In This Order
          </h1>
          <div className="w-full lg:w-full md:w-full   flex items-start  gap-10  justify-between  lg:p-[30px] md:gap-[30px] p-[10px] rounded-[12px] bg-[#F6F6F6] border-[1px] border-solid border-[#DFE9EF]">
            <div className="flex w-[70%] lg:gap-[20px] md:gap-[20px] gap-[30px] lg:flex-wrap md:flex-wrap">
              <img
                className="rounded-[12px] md:w-[150px] md:h-[150px] lg:w-[150px] lg:h-[150px] w-[75px] h-auto"
                src={process.env.REACT_APP_IMG_CDN_URL + details.defaultImg}
                alt=""
              />
              <div className="flex flex-col lg:justify-start md:justify-start justify-between  lg:gap-6 gap-3">
                <div className="flex flex-col gap-4">
                  <h1 className=" lg:w-full w-[200px]  lg:text-[22px] md:text-[22px] text-left text-[12px] lg:text-nowrap  font-bold md:leading-[28px] lg:leading-[28px]  text-[#242631]">
                    {details.name}
                  </h1>
                </div>
                <div>
                  {details?.offerPriceInVal ? (
                    <>
                      <div className="flex  items-baseline lg:gap-4 md:gap-4 gap-2">
                        <div className="lg:text-[24px] text-[20px] font-extrabold leading-[24px] text-left ">
                          <p className="lg:hidden md:hidden text-[20px] text-gray-800  flex align-baseline justify-center items-center self-center font-[800] ">
                            <span className="flex  justify-start items-start h-[16px]">
                              <MdOutlineCurrencyRupee
                                fontSize={14}
                                fontWeight={700}
                              />
                            </span>
                            {details.offerPriceInVal}
                          </p>
                          <p className="lg:flex  md:flex lg:text-[25px] text-gray-800  hidden align-baseline justify-center items-center self-center font-[600] ">
                            <span className="flex  justify-start items-start h-[20px]">
                              <MdOutlineCurrencyRupee
                                fontSize={16}
                                fontWeight={700}
                              />
                            </span>
                            {details.offerPriceInVal}
                          </p>
                        </div>
                        <div className="lg:text-[16px] text-[12px] align-bottom font-semibold leading-[20.8px] text-left text-[#687588] line-through">
                          &#8377;{details.price}
                        </div>
                        {details?.offerPriceInPercentage ? (
                          <div className="  text-[#067647]  lg:text-[16px] md:text-[12px] text-[10px] font-semibold leading-[20px] text-nowrap text-center  ">
                            {details?.offerPriceInPercentage * 100}% Off
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="lg:hidden md:hidden  flex w-full items-center justify-start pt-3 ">
                        <button
                          onClick={() => {
                            setModalState(true);
                          }}
                          className="lg:hidden md:hidden flex gap-1 bg-gradient-to-r from-indigo-500 to-indigo-600 bg-clip-text text-transparent text-[12px] font-semibold leading-[20px] "
                        >
                          <img
                            className="lg:w-[20px]  w-[10px]  h-auto"
                            src={starRating}
                            alt=""
                          />
                          Rate Your Order
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-[18px] font-bold leading-[24px] text-left">
                        &#8377;{details.price}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:flex md:flex lg:flex-row hidden lg:gap-2">
              <img
                className="lg:w-[20px]  w-[10px]  h-auto"
                src={starRating}
                alt=""
              />
              <button
                onClick={() => {
                  setModalState(true);
                }}
                className="bg-gradient-to-r from-indigo-500 to-indigo-600 bg-clip-text text-transparent text-[16px] font-semibold leading-[20px] text-left "
              >
                Rate Your Order
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const OrderBar: React.FC<{ order: any }> = ({ order }) => {
  return (
    <>
      <div className="flex w-[100%]    justify-between gap-6 lg:w-full items-center p-[15px] lg:p-[30px] rounded-[12px] bg-[#F6F6F6] border-[1px] border-solid border-[#DFE9EF]">
        <div className="lg:flex lg:justify-between flex-nowrap lg:w-[70%] lg:gap-[20px] lg:flex-wrap  grid grid-cols-2 w-full gap-3">
          <div className="flex items-start gap-3 flex-col">
            {/* <div className="lg:text-[16px] text-[14px] font-medium leading-[18px] text-left text-[#242631]">
              Courier
            </div> */}

            {/* here to see the status of order */}

            <button className="flex  items-center gap-3  lg:px-12 lg:py-5 font-bold lg:text-[16px] leading-5  rounded-md text-center text-[#039746] ">
              <span
                className={`block w-[10px] h-[10px] rounded-full bg-[#039746]`}
              ></span>
              Status
            </button>

            <div className="lg:text-[16px] text-[14px] font-bold leading-[18px] text-left text-[#242631] lg:text-wrap text-nowrap">
              {order.courier}
            </div>
          </div>

          <div className="flex items-start lg:gap-3 flex-col">
            <div className="lg:text-[16px] text-[14px]  font-medium leading-[18px] text-left text-[#242631] lg:text-wrap text-nowrap">
              Order ID
            </div>
            <div className="lg:text-[16px] text-[12px] font-bold leading-[18px] text-left text-[#242631]">
              {order.id}
            </div>
          </div>

          <div className="flex items-start gap-3 flex-col">
            <div className="lg:text-[16px] text-[12px]  font-medium leading-[18px] text-left text-[#242631] lg:text-wrap text-nowrap">
              Tracking ID
            </div>
            <div className="text-center w-full lg:text-[16px] text-[12px] font-bold leading-[18px] text-left text-[#242631]">
              {order.trackingId || 'NA'}
            </div>
          </div>

          <div className="flex items-start gap-3 flex-col">
            <div className="lg:text-[16px] text-[12px]  font-medium leading-[18px] text-left text-[#242631] lg:text-wrap text-nowrap">
              Expected Delivery Date
            </div>
            <div className="text-center w-full">--</div>
            {/* <div className="text-[16px] font-bold leading-[18px] text-left text-[#242631]">{new Date(order.expectedDeliveryDate).toISOString()}</div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const TrackOrderView: React.FC<{
  details: any;
}> = ({ details }) => {
  return (
    <>
      <div className="flex w-full gap-6 md:gap-0 flex-wrap justify-between overflow-hidden">
        <div className={`w-1/4 min-w-[120px] success-right relative`}>
          <OrderStage
            status={true}
            label={"Ordered"}
            labelInPending={"Order in progress"}
            labelInConfirmed={"Order confirmed"}
          />
        </div>
        <div className="w-1/4 min-w-[120px] success-left pending-right relative">
          <OrderStage
            label={"Shipped"}
            labelInPending={"Shipping in progress"}
            labelInConfirmed={"Shipping confirmed"}
          />
        </div>
        <div className="w-1/4 min-w-[120px] pending-left pending-right relative">
          <OrderStage
            label={"Out for delivery"}
            labelInPending={"Waiting to be picked by partner"}
            labelInConfirmed={"Picked up by partner"}
          />
        </div>
        <div className="w-1/4 min-w-[120px] pending-left relative">
          <OrderStage
            label={"Delivered"}
            labelInPending={"Delivery expected"}
            labelInConfirmed={"Delivered"}
          />
        </div>
      </div>
    </>
  );
};

const OrderStage: React.FC<{
  label: string;
  labelInPending: string;
  labelInConfirmed: string;
  status?: boolean;
  eta?: Date;
}> = ({
  label,
  labelInPending,
  labelInConfirmed,
  status = false,
  eta = new Date(),
}) => {
    return (
      <>
        <div className="flex gap-3 justify-center flex-col align-center items-center">
          <img
            className="w-[24px] h-[24px]"
            width={24}
            height={24}
            src={status ? trackerSuccess : trackerPending}
            alt=""
          />
          <h1
            className={`text-center text-[14px] font-semibold leading-[20px] text-center ${status ? "text-[#03977E]" : "text-[#0F1727]"
              }`}
          >
            {label}
          </h1>
          <img
            className="w-[14px] h-[12px]"
            width={14}
            height={12}
            src={status ? trackerTopSuccess : trackerTopPending}
            alt=""
          />
          <h2 className="text-center text-[14px] font-semibold leading-[16px] text-left text-[#242631]">
            {status ? labelInConfirmed : labelInPending}
          </h2>
          <div className="text-center text-[14px] font-medium leading-[16.8px] text-left text-[#72747C]">
            {eta.toISOString()}
          </div>
        </div>
      </>
    );
  };

const AddDetailsFormHandlerTemplate: React.FC<{
  selectedOrder: any;
  orderMetaDetails: any;
  setModalState: any;
  setSelectedOrder: any;
  setRefreshOrderList: any;
}> = ({
  selectedOrder,
  orderMetaDetails,
  setModalState,
  setSelectedOrder,
  setRefreshOrderList,
}) => {
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [formData, setFormData] = useState<any>();
    const { fetchRest } = useFetch({});
    const [imageSrcVal, setImageSrcVal] = useState<any>({});
    // const [imageSrcLoader, setImageSrcLoader] = useState<any>({});
    const [imageSrcErr, setImageSrcErr] = useState<any>({});
    const [oldVal, setOldVal] = useState<any>({});
    const [imageFileData, setImageFileData] = useState<any>({});

    useEffect(() => {
      if (selectedOrder && selectedOrder?.orderMetaDetails) {
        let formObj: any = {};
        for (let key in selectedOrder.orderMetaDetails) {
          if (key === "imgs") {
            let imgSrc: any = {};
            let imgLoader: any = {};
            let imgErr: any = {};
            for (let i = 0; i < selectedOrder.orderMetaDetails[key].length; i++) {
              const keyname = "image" + (i + 1);
              formObj[keyname] = "temp#" + selectedOrder.orderMetaDetails[key][i];
              imgSrc[keyname] = orderMetaDetails[key][i];
              imgLoader[keyname] = false;
              imgErr[keyname] = false;
            }
            setImageSrcVal({ ...imgSrc });
            // setImageSrcLoader({ ...imgLoader });
            setImageSrcErr({ ...imgErr });
          } else {
            formObj[key] = selectedOrder.orderMetaDetails[key];
          }
        }
        if (Object.keys(formObj)?.length) {
          setFormData(formObj);
          setOldVal(formObj);
        }
      }
    }, [selectedOrder]);

    const handleChange = (e: any) => {
      const { name, value } = e.target;
      setFormData((prev: any) => ({ ...prev, [name]: value }));
      if (errors[name]) {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    };

    const handleFileChange = (e: any, imageField: any) => {
      if (e?.target?.files?.[0]) {
        const imageName = formData[imageField].includes("temp#")
          ? "" + new Date().getTime()
          : formData[imageField];
        setImageFileData({
          ...imageFileData,
          [imageField]: e?.target?.files?.[0],
        });
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            setImageSrcVal({
              ...imageSrcVal,
              [imageField]: reader.result,
            });
          }
        };
        reader.readAsDataURL(e?.target?.files?.[0]);
        setFormData({ ...formData, [imageField]: imageName });
        if (errors[imageField]) {
          setErrors((prev) => ({ ...prev, [imageField]: "" }));
        }
      }
    };

    const validateField = (key: string) => {
      if (key === "first_name" && !formData[key])
        return "First name is required.";
      if (key === "last_name" && !formData[key]) return "Last name is required.";
      if (key === "image1" && !formData[key]) return "Image 1 is required.";
      if (key === "image2" && !formData[key]) return "Image 2 is required.";
      return undefined;
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      const newErrors: Record<string, string> = {};
      for (var key of Object.keys(formData)) {
        const error = validateField(key);
        if (error) newErrors[key] = error;
      }
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        if (JSON.stringify(oldVal) !== JSON.stringify(formData)) {
          let imagesUploadSuccess = true;
          try {
            if (formData?.hasOwnProperty("image1") && imageFileData["image1"]) {
              await uploadImagesData("image1");
            }
            if (formData?.hasOwnProperty("image2") && imageFileData["image2"]) {
              await uploadImagesData("image2");
            }
          } catch (e) {
            imagesUploadSuccess = false;
          }

          if (imagesUploadSuccess) {
            let editedDetails = formData;
            const imgs = [];
            for (let key in editedDetails) {
              if (key.includes("image")) {
                const val = editedDetails[key].includes("temp#")
                  ? editedDetails[key].split("temp#")[1]
                  : editedDetails[key];
                imgs.push(val);
                delete editedDetails[key];
              }
            }
            editedDetails["imgs"] = imgs;
            // -- make api call to edit the details
            fetchRest({
              url: `edit-suborder`,
              method: "POST",
              data: {
                subOrderId: selectedOrder.id,
                orderDetails: { ...editedDetails },
              },
              isFullPageLoader: true,
            })
              .then(() => {
                setSelectedOrder({
                  ...selectedOrder,
                  orderMetaDetails: { ...editedDetails },
                });
                setRefreshOrderList(true);
              })
              .catch((e) => {
                console.error(e);
              })
              .finally(() => {
                setModalState(false);
              });
          }
        } else {
          setModalState(false);
        }
      }
    };

    const uploadImagesData = async (imageField: string) => {
      return new Promise((resolve, reject) => {
        setImageSrcErr({
          ...imageSrcErr,
          [imageField]: false,
        });
        setImageSrcVal({
          ...imageSrcVal,
          [imageField]: null,
        });
        // setImageSrcLoader({
        //   ...imageSrcLoader,
        //   [imageField]: true,
        // });
        const imageName = formData[imageField];
        fetchRest({
          url: `get-image-upload-url`,
          method: "POST",
          data: {
            fileName: imageName,
          },
          isFullPageLoader: true,
        })
          .then((res) => {
            const signedURL = res.data?.uploadUrl;
            if (signedURL) {
              axios
                .put(signedURL, imageFileData[imageField], {
                  headers: { "Content-Type": "image/*" },
                })
                .then((res) => {
                  // setImageSrcLoader({
                  //   ...imageSrcLoader,
                  //   [imageField]: false,
                  // });
                  setImageSrcErr({
                    ...imageSrcErr,
                    [imageField]: false,
                  });

                  resolve("Success");
                })
                .catch((err) => {
                  setImageSrcErr({
                    ...imageSrcErr,
                    [imageField]: true,
                  });
                  // setImageSrcLoader({
                  //   ...imageSrcLoader,
                  //   [imageField]: false,
                  // });
                  reject("Error");
                });
            }
          })
          .catch((err) => {
            setImageSrcErr({
              ...imageSrcErr,
              [imageField]: true,
            });
            // setImageSrcLoader({
            //   ...imageSrcLoader,
            //   [imageField]: false,
            // });
            reject("Error");
          });
      });
    };

    return (
      <>
        <form
          className="w-[90vw] sm:w-[80vw]   h-[500px]  lg:w-[820px] max-w-[820px] flex flex-col justify-evenly items-start  overflow-y-auto lg:flex lg:flex-row  lg:gap-5"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          style={{ display: "flex", gap: "20px" }}
        >
          <div
            className="justify-between lg:flex lg:flex-col flex flex-col gap-[20px] "
            style={{ flex: "1" }}
          >
            {formData && formData.hasOwnProperty("image1") ? (
              <label className="lg:h-[280px] lg:w-full  h-[230px] w-[300px]">
                {errors["image1"] && (
                  <span className="error-message">{errors["image1"]}</span>
                )}
                <div className="lg:h-[100%] h-full px-[24px] py-[16px] rounded-[12px] bg-[#f9f9f9] border-[2px] border-dashed border-[#D6D8DC] cursor-pointer text-center">
                  <input
                    type="file"
                    accept=".png, .jpg"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "image1")}
                  />
                  <div className="flex items-center justify-center flex-col lg:h-full h-1/2 ">
                    {/* {imageSrcLoader["image1"] ? (
                    <div className="w-100 h-100 flex items-center">
                      <CircularProgress color="inherit" />
                    </div>
                  ) : (
                    <></>
                  )} */}
                    {!formData["image1"] ? (
                      <>
                        <img
                          className="w-[40px] lg:h-[40px] h-[50px] mb-[10px]"
                          src={uploadImg}
                          alt=""
                        />
                        <div className="lg:flex lg:items-center flex  flex-col items-center justify-center">
                          <p className="text-[14px] font-semibold leading-[20px] text-left [text-decoration-line:underline] [text-decoration-style:solid] text-[#242631]">
                            Click to upload
                          </p>
                          &nbsp;
                          <small className="text-[14px] font-normal leading-[20px] text-left text-[#475467]">
                            or drag and drop PNG, JPG (max. 12 MB)
                          </small>
                        </div>
                      </>
                    ) : (
                      <div className="relative w-auto h-full">
                        <img
                          className="absolute w-[40px] h-[40px] top-[20px] right-[20px]"
                          src={uploadImg}
                          alt=""
                        />
                        {imageSrcErr["image1"] ? (
                          <div className="w-100 h-100 flex items-center error-message">
                            Failed to upload !, please try again after sometime
                          </div>
                        ) : (
                          <></>
                        )}
                        {imageSrcVal["image1"] ? (
                          <img
                            className="hover-img-bg lg:w-[100%]  lg:h-[100%] w-[220px] h-[190px] object-scale-down  object-center"
                            src={imageSrcVal["image1"]}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}
            {formData && formData.hasOwnProperty("image2") ? (
              <label className="lg:h-[280px] lg:w-full  h-[230px] w-full flex  ">
                {errors["image2"] && (
                  <span className="error-message">{errors["image2"]}</span>
                )}
                <div className="lg:h-[100%] h-full   px-[24px] py-[16px] rounded-[12px] bg-[#F9FAFB] border-[2px] border-dashed border-[#D6D8DC] cursor-pointer text-center">
                  <input
                    type="file"
                    accept=".png, .jpg"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e, "image2")}
                  />
                  <div className="flex items-center justify-center flex-col lg:h-full h-1/2">
                    {/* {imageSrcLoader["image2"] ? (
                    <div className="w-100 h-100 flex items-center">
                      <CircularProgress color="inherit" />
                    </div>
                  ) : (
                    <></>
                  )} */}
                    {!formData["image2"] ? (
                      <>
                        <img
                          className="w-[40px] lg:h-[40px] h-[50px] mb-[10px]"
                          src={uploadImg}
                          alt=""
                        />
                        <div className="lg:flex lg:items-center flex  flex-col items-center justify-center ">
                          <p className="text-[14px] font-semibold leading-[20px] text-left [text-decoration-line:underline] [text-decoration-style:solid] text-[#242631]">
                            Click to upload
                          </p>
                          &nbsp;
                          <small className="text-[14px] font-normal leading-[20px] text-left text-[#475467]">
                            or drag and drop PNG, JPG (max. 12 MB)
                          </small>
                        </div>
                      </>
                    ) : (
                      <div className="relative w-auto h-full flex">
                        <img
                          className="absolute w-[40px] lg:h-[40px]   lg:top-[10px]   lg:right-[18px]   -top-5 left-3  "
                          src={uploadImg}
                          alt=""
                        />
                        {imageSrcErr["image2"] ? (
                          <div className="w-100 h-100 flex items-center error-message">
                            Failed to upload !, please try again after sometime
                          </div>
                        ) : (
                          <></>
                        )}
                        {imageSrcVal["image2"] ? (
                          <img
                            className="hover-img-bg  w-[220px] h-[190px] object-scale-down"
                            src={`${imageSrcVal["image2"]}`}
                            alt=""
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </label>
            ) : (
              <></>
            )}
          </div>
          <div style={{ flex: "1" }}>
            {formData && formData.hasOwnProperty("first_name") ? (
              <div style={{ marginBottom: "15px" }}>
                <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                  First name{" "}
                  {errors["first_name"] && (
                    <span className="error-message">
                      ({errors["first_name"]})
                    </span>
                  )}
                </label>
                <input
                  className="box-border h-[50px] w-[90%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                  type="text"
                  name="first_name"
                  placeholder="Enter first name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )}
            {formData && formData.hasOwnProperty("last_name") ? (
              <div style={{ marginBottom: "15px" }}>
                <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                  Last name{" "}
                  {errors["last_name"] && (
                    <span className="error-message">({errors["last_name"]})</span>
                  )}
                </label>
                <input
                  className="box-border h-[50px] w-[90%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                  type="text"
                  name="last_name"
                  placeholder="Enter last name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )}
            {formData && formData.hasOwnProperty("school") ? (
              <div style={{ marginBottom: "15px" }}>
                <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                  School{" "}
                  {errors["school"] && (
                    <span className="error-message">({errors["school"]})</span>
                  )}
                </label>
                <input
                  className="box-border h-[50px]  w-[90%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                  type="text"
                  name="school"
                  placeholder="Enter school name"
                  value={formData.school}
                  onChange={handleChange}
                />
              </div>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
              {formData && formData.hasOwnProperty("std") ? (
                <div style={{ flex: "1" }}>
                  <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                    Std.{" "}
                    {errors["std"] && (
                      <span className="error-message">({errors["std"]})</span>
                    )}
                  </label>
                  <input
                    className="box-border h-[50px] w-[80%] p-[10px] mb-[20px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                    type="text"
                    name="std"
                    placeholder="Enter std number"
                    value={formData.std}
                    onChange={handleChange}
                  />
                </div>
              ) : (
                <></>
              )}
              {formData && formData.hasOwnProperty("rollNo") ? (
                <div style={{ flex: "1" }}>
                  <label className="block mb-[8px] text-[14px] font-semibold leading-[14px] text-left text-[#344054]">
                    Roll no.{" "}
                    {errors["rollNo"] && (
                      <span className="error-message">({errors["rollNo"]})</span>
                    )}
                  </label>
                  <input
                    className="box-border h-[50px]  w-[80%] p-[10px] mb-[50px] border-[1px] border-[solid] border-[#ccc] rounded-[8px] text-[14px] text-[#7E818C] focus:outline-[none] focus:border-[1px] focus:border-[solid] focus:border-[#9FA4AC] focus:[box-shadow:0px_0px_0px_3px_rgba(159,_164,_172,_0.1607843137)]"
                    type="text"
                    name="rollNo"
                    placeholder="Enter roll number"
                    value={formData.rollNo}
                    onChange={handleChange}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="lg:block md:block hidden">
              <button
                type="submit"
                className="bg-[#03977E] px-[10px] py-[18px] rounded-[58px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center"
              >
                Edit Order
              </button>
            </div>
            <div className=" lg:hidden md:hidden relative w-full h-auto  flex justify-center z-80">
              <div className="bg-white w-full h-[90px] fixed bottom-0 left-0 z-[80]"></div>
              <button
                type="submit"
                className="bg-[#03977E] px-[10px] py-[18px] rounded-[12px] text-[20px] font-semibold leading-[28px]  text-[#FFFFFF] w-[90%] text-center fixed bottom-3  left-5  z-[90] "
              >
                Edit Order
              </button>
            </div>
          </div>
        </form>
      </>
    );
  };
