import whatAppKidImg from "../../assets/images/png/kid-whataap-banner.png";
import circle2 from "../../assets/images/svg/whatsapp/circle-2.svg";
import circle3 from "../../assets/images/svg/whatsapp/circle-3.svg";
import whatsapplogo from "../../assets/images/svg/whatsapp/whatsapp-logo.svg";
import circle1 from "../../assets/images/svg/whatsapp/half-circle.svg";
import snowflake1 from "../../assets/images/svg/whatsapp/snow-flake-1.svg";
import snowflake2 from "../../assets/images/svg/whatsapp/snow-flake-2.svg";
import popper1 from "../../assets/images/svg/whatsapp/sprinkle-1.svg";
import popper2 from "../../assets/images/svg/whatsapp/sprinkle-2.svg";
import popper3 from "../../assets/images/svg/whatsapp/sprinkle-3.svg";
import popper4 from "../../assets/images/svg/whatsapp/sprinkle.svg";





export const WhatappBanner: React.FC = () => {
  function handleClick() {
    const userAgent = navigator.userAgent;
      
     console.log(userAgent)

    const phoneNumber = "918590322571";
    const message = "Hi, I am interested in your product.";
    const encodedMessage = encodeURIComponent(message);
    let regexp = /android|iphone|kindle|ipad/i; 
    let isMobileDevice = regexp.test(userAgent);
    console.log(isMobileDevice)
    if (isMobileDevice) {
      // For Mobile Devices
      window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, "_blank");
    } else {
      // For Desktop (WhatsApp Web)
      window.open(`https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`, "_blank");
    }

    
  }



  return (
    <>
      <div className="block max-w-screen-xl sm:w-screen mx-[auto] my-[0] lg:overflow-hidden overflow-hidden md:overflow-hidden px-6 lg:px-0 md:px-6   z-[90]"    >
        <div className="w-full h-auto bg-[linear-gradient(89.75deg,_#0ABCAC_0.19%,_#009A8C_99.81%)]  rounded-[30px] overflow-hidden lg:overflow-hidden md:overflow-hidden">
          <div className="lg:flex  w-full    lg:justify-between lg:items-center  flex flex-row justify-evenly items-center md:flex md:flex-row md:justify-between md:items-center ">
            <div className="flex  relative w-1/2 sm:w-1/2  md:w-1/2 ">
              <div className="lg:relative lg:w-full  w-full relative  md:w-full md:relative pt-7 md:pt-10 ">
                <img
                  className="lg:w-[2%]  lg:absolute lg:-top-[20%] lg:left-[35%]  w-[2%] absolute bottom-[15%] left-[28%]       md:w-[10px] md:absolute   md:bottom-[120px] md:left-[70px]  " 
               
                  src={popper1}
                  alt=""
                />

                <img
                  className="lg:w-[3%]  lg:absolute lg:-top-[30%]    lg:left-[85%]  w-[3%] absolute bottom-[23%] left-[83%]    md:w-[20px] md:absolute   md:bottom-[130px] md:left-[330px]"
                 
                  src={popper2}
                  alt=""
                />

                <img
                  className="lg:w-[4%]  lg:absolute lg:top-[30px]    lg:left-[93%]  w-[6%] absolute top-[2%] left-[90%]    md:w-[25px] md:absolute   md:bottom-[10px] md:left-[350px]"
                
                  src={popper3}
                  alt=""
                />
                <img
                  className="lg:w-[3%]  lg:absolute lg:top-[30px]    lg:left-[20%]  w-[3%] absolute top-0 left-[18%] z-30    md:w-[15px] md:absolute     md:left-[5%]"
                   
                  src={popper4}
                  alt=""
                />
                <img
                  className="md:w-[83%] w-[83%] lg:w-[70%] lg:absolute lg:left-[100px] lg:-bottom-[120px] lg:z-10  absolute left-[6%] -bottom-[12%] z-10   md:absolute md:right-[40px] md:-bottom-[39px] md:z-10 "
                  src={circle2}
                  alt=""
                />
                <img
                  className="w-[100%]  lg:w-[85%]  lg:absolute lg:left-[50px] lg:top-[100px]  lg:z-20 absolute -left-[1%] top-[6%] z-20 md:absolute md:right-[5px] md:-bottom-[20px] md:z-10"
                  src={circle3}
                  alt=""
                />
                <img
                  className="lg:w-[60%] lg:relative lg:z-30 lg:left-[157px] lg:-bottom-[80px] w-[70%] relative z-30 left-[7%] -bottom-[5%]  md:w-[70%] md:relative md:z-30 md:left-[30px] md:-bottom-[10px]"
                 
                  src={whatAppKidImg}
                  alt="whatapp qr code"
                />
              </div>
            </div>

            <div className="lg:w-[30%]  h-auto  lg:flex lg:flex-col    lg:px-5 lg:translate-y-[15%]  w-[30%]  flex flex-col  -translate-y-[3%]    md:w-[30%] md:flex md:flex-col  ">
              <div className="lg:w-full w-full  ">
                <img
                  className="lg:w-[100px] w-[50px] md:w-[100px] "
                  src={whatsapplogo}
                  alt=""
                />
                <p className=" lg:font-[childhood] lg:text-[49px]    leading-6  lg:font-bold lg:text-white lg:leading-[50px] lg:py-5 font-[childhood] text-[23px] text-white   md:font-[childhood] md:text-[39px] md:font-bold md:text-white md:leading-[50px] md:py-3 ">
                  Order through
                  <br />
                  WhatsApp
                </p>

                <div className="w-fit h-fit">
                  <button
                    onClick={handleClick}
                    className="lg:py-[15px]  lg:px-[23px] lg:bg-white lg:rounded-full lg:text-base  lg:text-[#019d8f] lg:font-bold   py-[10px] px-[10px] translate-y-2 bg-white rounded-full text-[#019d8f] font-bold text-[10px]  md:text-base  md:py-[5px] md:px-[6px] md:bg-white md:rounded-full md:text-[#019d8f] md:font-bold "
                  >
                    
                    Click to Order
                  </button>
                </div>
              </div>

              <div className="lg:w-full w-full md:w-full">
                <img
                  className="lg:w-[40px]   lg:translate-y-[2rem] lg:-translate-x-10    w-[12px]  -translate-x-4 -translate-y-5  md:w-[30px]  md:-translate-x-9 md:-translate-y-9"
                  src={snowflake1}
                  alt=""
                />
              </div>
            </div>

            <div className=" lg:w-1/5 w-1/6 md:w-1/5 ">
              <img
                className="lg:w-[40px] lg:translate-x-32 lg:translate-y-14  w-[12px] translate-x-3 translate-y-1   md:w-[30px] md:translate-x-20 md:translate-y-12   "
                src={snowflake2}
                alt=""
              />

              <img
                className="lg:translate-y-30 lg:rotate-45 lg:-translate-x-9  w-full translate-y-9 translate-x-3  rotate-[10deg]  md:translate-y-32 md:rotate-45 md:-translate-x-6  "
                src={circle1}
                alt=""
              />
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
