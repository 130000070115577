import React from 'react';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './implementaion/layout/layout';
import { NotFound } from './pages/not-found/not-found';
import { LoaderProvider } from './common/loader-provider/loader-provider';
import { Landing } from './pages/landing/landing';
import { Category } from './pages/category/category';
import { AboutSection } from './pages/about/about';
import { PrivacyPolicySection } from './pages/privacy-policy/privacy-policy';
import { DetailsSection } from './pages/details/details';
import { TrackOrderLogin } from './pages/track-order-login/track-order-login';
import { OrderDetails } from './pages/order-details/order-details';
import { Cart } from './pages/cart/cart';
import { PageProvider } from './implementaion/page-context/page-context';
import { LocalStorageProvider } from './implementaion/localStorage/localStorageHandler';
import {Contact} from './pages/contact/contact';
import { TermsandConditions } from './pages/terms_and_conditions/terms_and_conditions';
import { PaymentFailuremsg } from './pages/payment-failure/payment-failure';
import { Paymentsuccessmsg } from './pages/payment-success/payment-success';
import { PaymentServerErrormsg } from './pages/payment-server-error/payment-server-error';


export const App: React.FC = () => {


  return (
    <LocalStorageProvider>
      <LoaderProvider>
        <BrowserRouter>
          <PageProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Landing />} />
                <Route path="/about" element={<AboutSection />} />
                <Route path="/privacy-policy" element={<PrivacyPolicySection />} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/terms-and-conditions" element={<TermsandConditions />} />
                <Route path="/:categoryId" element={<Category />} />
                <Route path="/:categoryId/:productId" element={<DetailsSection />} />
                <Route path="/order-details" element={<OrderDetails />} />
                <Route path="track-order" element={<TrackOrderLogin />} />
                <Route path="/cart" element={<Cart />} />
                <Route path='/cart/payment/failed' element={<PaymentFailuremsg/>}/>
                <Route path='/cart/payment/success' element={<Paymentsuccessmsg/>}/>
                <Route path='/cart/payment/server-error' element={<PaymentServerErrormsg/>}/>
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </PageProvider>
        </BrowserRouter>
      </LoaderProvider>
    </LocalStorageProvider>
  );
}
