import React, { useEffect, useState } from "react";
import "./product-wrapper.scss";
import { useLocation, useNavigate } from "react-router-dom";
import starRating from "../../assets/images/svg/star.svg";
import elipsesBig from "../../assets/images/svg/plp-big-elipses.svg";
import elipsesSmall from "../../assets/images/svg/plp-small-elipses.svg";
import elipsesXs from "../../assets/images/svg/plp-xs-elipses.svg";
import { useFetch } from "../../common/https";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, FreeMode } from "swiper/modules";
import { LazyLoadImage } from "react-lazy-load-image-component";
import bluredPrdImg from "../../assets/images/png/blured-product.png";
import { MdOutlineCurrencyRupee } from "react-icons/md";

const productApiMap: any = {
  featured: () => "featured-products",
  "top-selling": () => "top-selling-products",
  recommended: (categoryId: string) => `recommended-products/${categoryId}`,
  plp: (categoryId: string) => `${categoryId}/products`,
};

export const ProductWrapper: React.FC<{
  pageType?: string;
  heading?: string;
  subHeading?: string;
  productKey?: string;
  categoryId?: string;
}> = ({
  pageType = "landing",
  heading = "Featured products",
  subHeading = " ",
  // Discover our meticulously crafted products,designed to blend individuality with function. Each of our featured pieces reflect utmost sophistication and quality..
  productKey = "featured",
  categoryId = "",
}) => {
  const [productRes, setProductRes] = useState([]);
  const { fetchRest } = useFetch({});
  const location = useLocation();

  useEffect(() => {
    if (productApiMap[productKey]) {
      fetchRest({
        url: productApiMap[productKey](categoryId),
        method: "GET",
        isFullPageLoader: true,
      })
        .then((res) => {
          setProductRes(res?.data?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [categoryId, productKey, location.pathname]);

  return (
    <>
      <section className="w-full relative">
        <img
          className="absolute z-[10] w-[210px] h-[210px] sm:w-[420px] sm:h-[420px] right-[0] top-[30px]"
          src={elipsesBig}
          alt=""
        />
        <div className="px-[15px] md:px-[30px] pt-[30px] md:pt-[60px]">
          <div className="relative max-w-screen-xl mx-[auto] my-[0]">
            <img
              className="absolute z-1 w-[70px] h-[70px] -top-[16px] left-[30px]"
              src={elipsesSmall}
              alt=""
            />
            <img
              className="absolute z-1 w-[32px] h-[32px] top-[50px] left-[100px]"
              src={elipsesXs}
              alt=""
            />
            <div className="relative z-10">
              <h1 className="font-[childhood] text-[32px] md:text-[60px] font-normal leading-[60px] tracking-[0.02em] text-center">
                {heading}
              </h1>
              <h2 className="text-[16px] md:text-[20px] font-normal leading-[26px] text-center mt-0 sm:mt-4 md:mt-6">
                {subHeading}
              </h2>

              <div className="hidden md:flex md:flex-wrap lg:flex-nowrap md:gap-11 lg:flex lg:flex-row prd-list  gap-[20px] items-center justify-center mt-6 sm:mt-[50px]">
                {productRes?.length ? (
                  productRes.map((prd, index) => (
                    <ProductCard key={index} prdInfo={prd} />
                  ))
                ) : (
                  <>
                  <div className="font-[childhood] lg:text-[52px] text-[36px] text-nowrap md:text-[52px] font-normal leading-[62px] md:leading-[110px] tracking-[0.02em] text-center bg-[linear-gradient(90.38deg,_#EF3B42_2.76%,_#FAC12C_100%)] text-transparent bg-clip-text">
                    Products will be added soon ...
                  </div>
                  </>
                  
                )}
              </div>

              {location.pathname === "/name-slip" ? (
                <div className=" md:hidden mt-6 lg:hidden  flex flex-col gap-11 justify-center items-center   ">
                  {productRes?.length ? (
                    productRes.map((prd, index) => (
                      <ProductCard key={index} prdInfo={prd} />
                    ))
                  ) : (
                    <>
                    <div className="font-[childhood] lg:text-[52px] text-[36px] text-nowrap md:text-[52px] font-normal leading-[62px] md:leading-[110px] tracking-[0.02em] text-center bg-[linear-gradient(90.38deg,_#EF3B42_2.76%,_#FAC12C_100%)] text-transparent bg-clip-text">
                     Products will be added soon ...
                   </div>
                    </>
                  
                  )}
                </div>
              ) : (
                <>
                  <div className=" md:hidden mt-6 lg:hidden  flex flex-col justify-center items-center gap-11 ">
                    {productRes?.length ? (
                      productRes.map((prd, index) => (
                        <ProductCard key={index} prdInfo={prd} />
                      ))
                    ) : (
                      <>

<div className="font-[childhood] lg:text-[52px] text-[36px] text-nowrap md:text-[52px]  font-normal lg:leading-[62px] md:leading-[110px] tracking-[0.02em] text-center bg-[linear-gradient(90.38deg,_#EF3B42_2.76%,_#FAC12C_100%)] text-transparent bg-clip-text">
                      Products will be added soon ...
                      </div>
                      
                      </>
                   
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ProductCard: React.FC<{
  prdInfo: any;
}> = ({ prdInfo }) => {
  const navigate = useNavigate();

  function handleClick() {
    navigate(`/${prdInfo?.tags[0]}/${prdInfo.key}`);
    window.scrollTo(0, 0);
  }

  return (
    <div className=" w-[290px]  flex flex-col items-center justify-center lg:gap-2  ">
      <div className="flex justify-center">
        <LazyLoadImage
          onClick={handleClick}
          className="cursor-pointer rounded-[20px] lg:w-[290px] md:w-[290px] lg:h-[350px] md:h-[350px] w-[240px] h-auto object-cover"
          src={process.env.REACT_APP_IMG_CDN_URL + prdInfo.defaultImg}
          // placeholderSrc={bluredPrdImg}
          alt="Image Alt"
        />
      </div>
      {/* <img onClick={handleClick} className="cursor-pointer rounded-[20px] w-[306px] h-[356px]" src={process.env.REACT_APP_IMG_CDN_URL + prdInfo.defaultImg} alt="" /> */}

      <div className=" w-[290px] self-start lg:ml-0 ml-6  flex flex-col items-center justify-center pt-2">
        <h2 className="lg:w-[300px] md:w-[300px] text-gray-800  w-[290px] lg:text-[18px] md:text-[18px] text-[18px] leading-[25px] text-balance  lg:tracking-normal lg:font-[550]  font-[600] lg:pt-2  lg:leading-[24px] md:leading-[28px] lg:text-left  lg:pl-3 ">
          {prdInfo.name}
        </h2>

        {prdInfo?.offerPriceInVal ? (
          <>
            <div className="flex  lg:align-middle  md:align-middle align-baseline items-baseline lg:items-baseline lg:justify-start justify-start lg:gap-2 md:gap-2 gap-1  lg:py-1 self-start  mt-2 ">
              <div className=" lg:text-[23px] md:text-[23px]  font-semibold lg:leading-[24px] md:leading-[24px] text-left">
                {/* &#8377; */}
                <p className="lg:hidden md:hidden text-[20px] text-gray-800  flex align-baseline justify-center items-center self-center font-[800] ">
                  <span className="flex  justify-start items-start h-[16px]">
                    <MdOutlineCurrencyRupee fontSize={14} fontWeight={700} />
                  </span>
                  {prdInfo.offerPriceInVal}
                </p>

                <p className="lg:flex  md:flex lg:text-[25px] text-gray-800  hidden align-baseline justify-center items-center self-center font-[600] ">
                  <span className="flex  justify-start items-start h-[20px]">
                    <MdOutlineCurrencyRupee fontSize={16} fontWeight={700} />
                  </span>
                  {prdInfo.offerPriceInVal}
                </p>
              </div>

              <div className="lg:text-[20px] md:text-[20px] text-[16px]  font-light leading-[24.8px] text-left text-[#687588] line-through">
                &#8377;{prdInfo.price}
              </div>
              {prdInfo?.offerPriceInPercentage ? (
                <div className="  text-[#03977e]  lg:text-[18px] md:text-[15px]  text-[16px] font-extrabold leading-[24px] text-left   ">
                  {prdInfo?.offerPriceInPercentage * 100}% Off
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="text-[18px] font-bold leading-[24px] text-left">
              &#8377;{prdInfo.price}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
