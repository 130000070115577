
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

export const AboutSection: React.FC = () => {


  const location =useLocation()

  useEffect(()=>{
  window.scrollTo(0,0)
  },[location.pathname === "/about"])


  return (
    <>
      <section className="lg:block lg:py-10 lg:px-52    lg:relative lg:top-[60%] lg:left-[50%] lg:-translate-x-[50%]    md:block md:py-10 md:px-[50px]   md:relative md:top-[50%] md:left-[50%] md:-translate-x-[50%]   block py-10 px-[15px]   relative top-[50%] left-[50%] -translate-x-[50%]  ">
        <div className="lg:w-fit lg:h-fit lg:py-10 lg:px-10 md:w-fit md:h-fit md:py-10 w-fit  py-10 ">
          <h1 className=" font-bold text-2xl">About</h1>
          <br />
          <p className=" text-left">
            Welcome to TheWowKids, a visionary initiative dedicated to
            transforming the way students learn and engage with education.
            Founded by me,Aysha Minha, an ambitious 18-year-old entrepreneur
            with a passion for innovation, TheWowKids is on a mission to
            revolutionize the student experience with creative and personalized
            learning solutions.
          </p>
          <br />

          <p className=" text-left">
            Driven by a deep enthusiasm for enhancing education, I recognized
            the need for more interactive and enjoyable study methods. This led
            to the creation of TheWowKids—a platform that goes beyond
            conventional learning tools to offer customized name slips and an
            array of thoughtfully designed products that make studying more
            engaging, efficient, and fun.
          </p>
          <br />

          <p className="text-left ">
            At TheWowKids, we believe that every student has untapped potential
            waiting to be unlocked. Our goal is to empower young minds, inspire
            curiosity, and redefine education through creativity and innovation.
            Join us on this transformative journey and experience the future of
            learning—where education is not just a task but an exciting
            adventure!
          </p>
          <br />
          
        </div>
      </section>
    </>
  );
};
