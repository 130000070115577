import React from "react";
import './footer.scss';
import footerCloud from '../../assets/images/svg/footer-cloud.svg';
import mainLogo from '../../assets/images/svg/wow_kid_logo.svg';
import { Link } from "react-router-dom";
import instagramFooter from '../../assets/images/svg/icons8-instagram.svg';
import youtubeFooter from '../../assets/images/svg/icons8-youtube.svg';
import twitterFooter from '../../assets/images/svg/icons8-twitter.svg';
import facebookFooter from '../../assets/images/svg/icons8-facebook.svg';

export const Footer: React.FC = () => {

    return (
        <footer className="footer-container w-full ">
            <img className="w-full" src={footerCloud} alt="footer-cloud" />
            <div className="bg-[#D8F1E7] px-5 sm:px-[40px] ">
                <div className="flex sm:flex-col items-center justify-between sm:justify-center align-center w-full">
                    <div className="flex items-center lg:justify-center  flex-col lg:mb-[70px] mb-[30pxnpm ]">
                        <img className="footer-app-logo lg:w-[168px] lg:h-[120px] w-[142px] h-[80px]" src={mainLogo} alt="main-logo" />
                        <div className="sm:hidden mt-6  ">
                            <SocialLinks />
                        </div>
                    </div>
                    <div className="hidden sm:flex footer-info w-full justify-between items-center mb-[20px]">
                        <CopyRightInfo />
                        <div className="footer-important-links">
                            <FooterLinks />
                        </div>
                        <div className="footer-social-media-links">
                            <SocialLinks />
                        </div>
                    </div>
                    <div className="block sm:hidden">
                        <FooterLinks />
                    </div>
                </div>
                <div className="flex items-center justify-center sm:hidden mt-[12px] lg:pb-0 pb-[50px]">
                    <CopyRightInfo />
                </div>
            </div>
        </footer>
    );
}

const CopyRightInfo: React.FC = () => {
    return (
        <div className="footer-copyright-info text-[#45425E] text-sm font-medium leading-[18px] text-center pt-4">&copy; 2024 wowkids | All Rights Reserved</div>
    );
}

const SocialLinks: React.FC = () => {

     function handleclick(){
        window.open("https://www.instagram.com/thewowkid_/?hl=en")
    }

    function handlefbclick(){
        window.open("https://www.facebook.com/thewowkid/")
    }
    return (
        <div className="flex flex-row items-center lg:gap-4 gap-2">
            <div className="bg-[#FFFFFF] p-[10px] flex items-center align-center rounded-full cursor-pointer"  onClick={handleclick}>
                <img className="w-[18px] h-[18px] " src={instagramFooter} alt="footer-social" />
            </div>

            <div className="bg-[#FFFFFF] p-[10px] flex items-center align-center rounded-full cursor-pointer">
                <img className="w-[18px] h-[18px]" src={facebookFooter} alt="footer-social"   onClick={handlefbclick}/>
            </div>

            <div className="bg-[#FFFFFF] p-[10px] flex items-center align-center rounded-full cursor-pointer">
                <img className="w-[18px] h-[18px]" src={twitterFooter} alt="footer-social" />
            </div>

            <div className="bg-[#FFFFFF] p-[10px] flex items-center align-center rounded-full cursor-pointer">
                <img className="w-[18px] h-[18px]" src={youtubeFooter} alt="footer-social" />
            </div>
        </div>
    )
}

const FooterLinks: React.FC = () => {
    return (
        <ul className="footer-links   flex gap-4 lg:gap-[30px] flex-col sm:flex-row  sm:items-end items-end ">
            <li>
                <Link to="/about">
                    About
                </Link>
            </li>
            <li>
                <Link to="/privacy-policy">
                    Privacy Policy
                </Link>
            </li>
            <li>
                <Link to="/contact">
                    Contact
                </Link>
            </li>
            <li className=" text-end text-nowrap">
                <Link to="/terms-and-conditions">
                    Terms & Condition
                </Link>
            </li>
        </ul>
    )
}
